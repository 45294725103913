import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FinancialImplication, FinancialImplicationComment } from '../../submission.model';
import { FormGroup } from '@angular/forms';
import { SubmissionService } from '../../submission.service';
import { AppConfigService } from '../../../services/app-config.service';

@Component({
  selector: 'oir-financial-implications-type-panel',
  templateUrl: './type-panel.component.html',
  styleUrls: ['./type-panel.component.less']
})
export class FinancialImplicationTypePanelComponent implements OnInit, OnDestroy {
  @Input() parentFormGroup: FormGroup;
  @Input() index: number;

  public model: FinancialImplication;
  public commentToEdit = new FinancialImplicationComment()
  public commentsToView: number;
  public readOnly: Boolean;
  public isDraft: Boolean;
  public showFirst: Boolean;
  public collapsed = false;

  private subscription;

  constructor(
    private submissionService: SubmissionService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.subscription = this.submissionService.submission$.subscribe(model => {

      this.model = model.submission.financialImplications[this.index];
      this.commentsToView = this.model.comments.length;
      const completeContributionsCount = model.submission.contributions.filter(x => x.contributionStatusId === 2).length;

      if (model.submission.assignedToUser == null ||
        model.submission.assignedToUser.adRoleMemberId === null ||
        model.submission.assignedToUser.adRoleMemberId === 0) {
        this.readOnly = true;
      } else {
        this.readOnly = model.submission.assignedToUser.adRoleMemberId !== +this.config.getValue('UserRoleMemberId');
      }
      const draftContribution = model.submission.contributions.filter(x => x.contributionStatusId === 1);
      if (draftContribution.length > 0) {
        this.isDraft = true;
      }

      if (this.isDraft && !this.readOnly && this.commentsToView > completeContributionsCount) {
        this.commentsToView = this.commentsToView - 1;
        this.commentToEdit = this.model.comments[this.commentsToView];
      }

      this.showFirst = this.commentsToView > 1 ||
        (this.commentsToView === 1 &&
          (!this.readOnly || !this.isDraft || completeContributionsCount >= 1 || (this.readOnly && this.isDraft))
        );

    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
  }
}
