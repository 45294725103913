import { FileItem } from 'ng2-file-upload';

export class SelectItem {
  value: any;
  title: any;
  heading: any;
}
export enum RelatedDocumentEnum {
  Other = '5'
}
export enum SupportingInformationOptionsEnum {
  EstablishPrinciple = 1,
  ExistingDocument = 2,
  NewDocument = 3,
  LinkToASubmission = 4,
  ExternalLink = 5,
  StandingOrder = 6,
  SalientRuling = 7,
  ArticlesOfTheConstitution = 8
}

export class Principle {
  public principleId: number;
  public descr: string;
}

export class RefDataModel {
  public SupportInfoTypes: any;
  public RelatedDocuments: any;
  public OtherRelatedDocTypes: any;
  public Languages: any;
  public PrinciplesList: any;
  public SaliantRulingList: any;
  public StandingOrdersList: any;
  public ArticlesOfTheConstList: any;
}

export class SupportingInformation {
  supportInfoId: number;
  supportInfoTypeId: number;
  contributionId: number;
  commentFinancialId: number;
  commentAmendmentId: number;
  submissionCategory: string;
  supportinfoTypeDescr: string;
  standingOrderId: number;
  standingOrderReference: string;
  standingOrderDescr: string;
  salientRulingId: number;
  salientRulingReference: string;
  salientRulingDescr: string;
  constitutionArticleId: number;
  constitutionArticleReference: string;
  constitutionArticleDescr: string;
  principleId: number;
  principleDesc: string;
  documentId: number;
  documentTypeId: number;
  documentDescr: string;
  documentUserDescription: string;
  documentDate: string;
  documentTypeDescr: string;
  documentLanguageId: number;
  documentLanguageDescr: string;
  documentUniqueId: number;
  url: string;
  urlDesc: string;
  submissionIdLink: string;
  submissionDesc: string;
  establishedUnderSectionId: number;
  establishedUnderSectionName: string;
  financialImplicationTypeIdLink: number;
  financialImplicationTypeDescr: string;
  amendmentGroupIdLink: number;
  amendmentGroupTitle: string;
  shortTitleEngLink: string;
  billId: number;
  isActive: number;
  newFileContent: FileItem[];
  attachedDocuments: FileAttachment[];
  billIdLink: number;
}

export class FileAttachment {
  documentUniqueId: string;
  name: string;
  content: File;
}


export enum AddSupportingInformation {
  SelectFromOptions = 1,
  AddingInfo = 2
}
export class AddSupportInfoModel {
  public supportInfoTypes: Array<any> = [];
  public relatedDocuments:  Array<any> = [];
  public otherRelatedDocTypes:  Array<any> = [];
  public languages: Array<any> = [];
  public principles: Array<any> = [];
  public salientRulings: Array<any> = [];
  public standingOrders: Array<any> = [];
  public articlesofTheConstitution: Array<any> = [];
  public allowedMimeType: String[];
}

export class RelatedDocument {
  documentTypeId: number;
  submissionId: number;
  otherDescription: string;
  documentLanguageId: number;
  documentUserDescription: string;
  documentDate: string;
  fileContent: string;
}
