import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  NavigationService,
  ToastNotificationsService,
  OirAuthService
} from 'proceduralsystem-clientcomponents';
import {
  PrincipleModel,
  LoadEditPrincipleModel,
  LinkTypeEnum
} from './principle.model';
import { SupportInformation } from './cited-submissions/cited-submissions.model';
import { CitedSubmissionsModel } from './cited-submissions/cited-submissions.model';

import { throwError as observableThrowError, Observable, Subject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../services/app-config.service';

@Injectable()
export class PrincipleService {
  public loadEditmodel: LoadEditPrincipleModel;
  public principle = new Subject();
  public key = 'MostNotable';
  public reverse = false;

  private baseUrl: string;
  private citedHash = {};
  private model: PrincipleModel;

  constructor(
    private http: HttpClient,
    private readonly config: AppConfigService,
    private router: Router,
    private translateService: TranslateService,
    private toastService: ToastNotificationsService,
    private navigationService: NavigationService,
    private authService: OirAuthService
  ) {
    this.baseUrl = `${this.config.getValue('ApiEndpoint').url}/api`;
  }

  public getUpdatedBy() {
    return this.authService.getUsername();
  }

  public establishEditPrinciple(principle: PrincipleModel) {
    principle.updatedBy = this.getUpdatedBy();
    principle.delegateRoleMemberId = this.config.getValue('DelegateRoleMemberId');
    const data = {
      principle: principle,
      citedSubmissions: {
        establishedForSubmission: this.model.citedSubmissions.establishedForSubmission,
        items: this.citedHash
      }
    };

    const url = this.baseUrl + '/Principle';
    this.http.post<PrincipleModel>(url, data).subscribe((model) => {
      if (principle.principleId === 0) {
        this.toastService.addNotification({
          title: 'TOAST.PRINCIPLE.ESTABLISHED.TITLE',
          description: this.translateService.instant(
              'TOAST.PRINCIPLE.ESTABLISHED.SUBTITLE',
              { principleId: model.principleId }
            ),
        })
      } else {
        this.toastService.addNotification({
          title: 'TOAST.PRINCIPLE.SAVED.TITLE',
          description: this.translateService.instant(
              'TOAST.PRINCIPLE.SAVED.SUBTITLE',
              { principleId: model.principleId }
            ),
        })
      }
      if (model.principleId !== 0) {
        this.router.navigate(['/principle/view/' + model.principleId], { queryParams: { keepTab: true } });
      }

      return model;
    });
  }

  public getPrinciple(id: number) {
    const url = this.baseUrl + `/Principle/id?Id=${id}&UserName=${this.authService.getUsername()}`;
    this.http.get<LoadEditPrincipleModel>(url).subscribe((model: any) => {
      if (model) {
        this.model = model;

        this.principle.next(model);
      }
    });
  }

  public getPrinciples(): Observable<any> {
    const url = this.baseUrl + '/PrincipleList';
    return this.http.get<any>(url);
  }

  public expirePrinciple(principleToExpire): any {
    const url =  this.baseUrl + '/Principle';
    return this.http.put(url, principleToExpire)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  deleteRelatedPrincipleLink(principleLinkId: number) {
    const data = {
      updatedBy: this.getUpdatedBy(),
      principleLinkId: principleLinkId
    };
    const url =  this.baseUrl + `/LinkPrinciple?principleLinkId=${data.principleLinkId}&updatedBy=${data.updatedBy}`;
    return this.http.delete(url);
  }

  public linkPrinciples(linkingData: any) {
    let url;
    const data = {
      updatedBy: this.getUpdatedBy(),
      PrincipleLinks: linkingData
    };

    if (linkingData[0].principleLinkTypeId === LinkTypeEnum.Relate) {
      url = this.baseUrl + '/LinkPrinciple';
    } else {
      url = this.baseUrl + '/api/SupercedePrinciple';
    }

    return this.http.post<any>(url, data).subscribe();
  }

  public isAllowedExpire(id): Observable<any> {
    if (!id) { return of(false) };
    const userName = this.authService.getUsername();
    const url = this.baseUrl + `/Principle/?UserName=${userName}`;
    return this.http.get(url);
  }

  public initNavigationViewChild(principleId) {
    const searchNode = this.navigationService.model.tree.find(x => x.title === 'Search principles');
    searchNode.children = [];
    searchNode.children.push({ title: `Principle #${principleId}`, path: `principle/view/${principleId}` });
    searchNode.expanded = true;
    this.navigationService.select(searchNode.children[0]);
  }

  public initNavigationEditChild(principleId) {
    if (principleId > 0 ) {
      const searchNode = this.navigationService.model.tree.find(x => x.title === 'Search principles');
      searchNode.children = [];
      searchNode.children.push({ title: `Edit principle #${principleId}`, path: `principle/establish-edit/${principleId}` });
      searchNode.expanded = true;
      this.navigationService.select(searchNode.children[0]);
    } else {
      this.navigationService.select(this.navigationService.model.tree.find(x => x.title === 'Establish principle'), true);
    }

  }

  public searchCitedSubmission(id: number, skip: number, take: number, sortBy: number): void {
    const url = this.baseUrl + `/SupportInformation?id=${id}&skip=${skip}&take=${take}&sortBy=${sortBy}`;

    this.http.get<CitedSubmissionsModel>(url).subscribe((response: any) => {
      this.model.citedSubmissions.items = response;

      this.updateSupportInformations(this.model.citedSubmissions.items);

      this.principle.next(this.model);
    });
  }

  public markEstablishedSubmissionAsNotable(): void {
    this.model.citedSubmissions.establishedForSubmission.isNotable = !this.model.citedSubmissions.establishedForSubmission.isNotable;
  }

  public markSubmissionAsNotable(supportInfo: SupportInformation) {
    if (this.citedHash.hasOwnProperty(supportInfo.supportInfoId)) {
      this.citedHash[supportInfo.supportInfoId] = !this.citedHash[supportInfo.supportInfoId];
    } else {
      this.citedHash[supportInfo.supportInfoId] = !supportInfo.isNotable;
    }
  }

  private updateSupportInformations(groups: Array<SupportInformation[]>) {
    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < groups[i].length; j++) {
        const supportInfoId = groups[i][j].supportInfoId;

        if (this.citedHash.hasOwnProperty(supportInfoId)) {
          groups[i][j].isNotable = this.citedHash[supportInfoId];
        }
      }
    }
  }
}
