export class AdvancedFiltersOptions {
  public categories?: Array<any> = [];
  public subCategories?: Array<any> = [];
  public statuses?: Array<any> = [];
  public forums?: Array<any> = [];
  public movements?: Array<any> = [];
  public usersAndRoles?: Array<any> = [];
  public outcomes?: Array<any> = [];
}

export class FilterModel {
  public freeText: string;
  public categoryId: number;
  public subCategoryIds: SubCategoryDto[];
  public statusIds: number[];
  public forumIds: ForumDto[];
  public movementIds: number[];
  public userOrRoleId: UserOrRoleDto;
  public outcomeIds: OutcomeDto[];
  public sortBy: number = SortByEnum.BillNumberDescending;
  public skip;
  public take;
  public currentPage: number;
}

export interface ForumDto {
  catgoryId: number;
  houseId: number;
}

export interface OutcomeDto {
  categoryId: number;
  outcomeId: number;
  descr: string;
}

export interface SubCategoryDto {
  categoryId: number;
  subCategoryId: number;
}

export interface UserOrRoleDto {
  headerSelected: boolean;
  roleId: number;
  userId: number;
}

export class SearchResultsModel {
  count: number;
  items: ItemResultModel[];
  skip;
  take;
  currentPage;
}

export class ItemResultModel {
  bill: Bill;
  submissions: Submission[];
}

export class Bill {
  id?: number;
  title?: string;
  number?: string;
  year?: number;
  url?: string;
}

export class Submission {
  submissionNumber?: number;
  submissionCategory?: string;
  catId?: SubmissionCategory;
  subCatId?: SubmissionSubCategory;
  subCategoryType?: string;
  subCategory?: string;
  status?: string;
  statusId?: SubmissionStatus;
  forum?: string;
  assignedTo?: string;
  dateAssignedAt?: string;
  dateAssignedAtIso?: string;
  createdBy?: string;
  dateCreatedAt?: string;
  dateCreatedAtIso?: string;
  completedBy?: string;
  dateReceivedAt?: string;
  mostRecentCompletedDate: string;
  lastUpdatedDate: string;
  pmbDecisionOutcome: string;
  jsonDataFinancialDecision?: string;
  jsonDataFinancialDecisionList?: FinancialDecision[];
  jsonDataCountAmndOrders?: string;
  jsonDataCountAmndOrdersList?: AmendmentOrder[];
  url?: string;
}

export class FinancialDecision {
  decision?: string;
  outcome?: number;
}

export class AmendmentOrder {
  inOrder?: string;
  outOfOrder?: number;
}

export enum SortByEnum {
  LastUpdatedDate = 1,
  BillNumberDescending = 2
}

export enum SubmissionMovement {
  CreatedBy = 1,
  AssignedTo = 2,
  CompletedBy = 3
}

export enum SubmissionStatus {
  InDraft = 1,
  InProgress = 2,
  FinalDecisionMade = 3
}

export enum SubmissionCategory {
  PMB = 1,
  PrivateBill = 2,
  FinancialImplications = 3,
  Amendments = 4,
  Other = 5
}

export enum SubmissionSubCategory {
  LeaveToIntroduce = 1,
  Presentation = 2,
  SecondStage = 3,
  CommitteeStage = 4,
  ReportStage = 5,
  Acts = 6,
  Bills = 7,
  Elections = 8,
  RequestForInstructions = 9,
  Other = 10,
  CreamList = 11
}

export const CategoryAliases = new Map([
  [SubmissionCategory.Amendments, 'Amnd'],
  [SubmissionCategory.FinancialImplications, 'FI'],
  [SubmissionCategory.Other, 'Other'],
  [SubmissionCategory.PMB, 'PMB'],
  [SubmissionCategory.PrivateBill, 'Private Bill']
]);

export const SubCategoryAliases = new Map([
  [SubmissionSubCategory.LeaveToIntroduce, 'LTI'],
  [SubmissionSubCategory.Presentation, 'Pres'],
  [SubmissionSubCategory.SecondStage, '2nd'],
  [SubmissionSubCategory.CommitteeStage, 'C'],
  [SubmissionSubCategory.ReportStage, 'R'],
  [SubmissionSubCategory.CreamList, 'CL'],
  [SubmissionSubCategory.Acts, 'Acts'],
  [SubmissionSubCategory.Bills, 'Bills'],
  [SubmissionSubCategory.Elections, 'Elections'],
  [SubmissionSubCategory.RequestForInstructions, 'Instruct'],
  [SubmissionSubCategory.Other, 'Other']
]);
