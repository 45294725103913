import { Component, OnInit, Input } from '@angular/core';
import { SupportingInformation, SupportingInformationOptionsEnum } from '../support-info/support-info.model';
import { SupportInfoService } from '../support-info/support-info.service';
import { SharedService } from '../../shared/shared.service';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'oir-support-info-view',
  templateUrl: './support-info-view.component.html',
  styleUrls: ['./support-info-view.component.less']
})
export class SupportInfoViewComponent implements OnInit {
  @Input() supportingInformation: SupportingInformation[];
  public textValue = '';
  public supportingInformationOptions = SupportingInformationOptionsEnum;

  constructor(
    private config: AppConfigService,
    private supportInfoService: SupportInfoService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.supportingInformation.forEach((supportInfo) => {
      if (supportInfo.establishedUnderSectionId) {
        this.getSection(supportInfo, supportInfo.submissionIdLink, supportInfo.establishedUnderSectionId);
      }
    });
  }

  getSection(obj, submissionId, sectionId) {
    if (submissionId != null) {
      this.sharedService.getSubmissionSections(submissionId).subscribe(x => {
        const t = x.filter(y => y.value === sectionId);
        if (t.length === 1) {
          obj.establishedUnderSectionName = t[0].title;
        }
      }
      )
    }
    return '';
  }

  public getBillsBaseUrl(): string {
    return `${this.config.getValue('BillsAdminWebUrl')}/edit/`;
  }

  public onDownloadDocument(info: any) {
    const regexp = new RegExp('\.(PDF|doc|odt) &', 'i');
    const match = info.documentDescr.match(regexp);
    if (match) {
      info.documentDescr = 'documents.zip';
    }
    this.supportInfoService.downloadDocument(null, info.documentId, info.documentDescr);
  }

  public getFileExtension(fileName: any): string | undefined {
    if (!fileName) {
      return;
    }

    let extension;

    if (fileName.indexOf('.odt') !== -1) {
      extension = 'odt';
    } else if (fileName.indexOf('.pdf') !== -1) {
      extension = 'pdf';
    } else {
      extension = 'doc';
    }
    return extension;
  }
}
