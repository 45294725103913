import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { SubmissionSearchService } from './submission-search.service';
import { SelectValue, PaginationOptions } from 'proceduralsystem-clientcomponents';
import {
  AdvancedFiltersOptions, SortByEnum, SearchResultsModel,
  SubmissionCategory, SubmissionStatus, SubCategoryAliases
} from './submission-search.model';
import { CreateSubmissionContainer } from '../create/create-submission-container-guard';
import { CreateSubmissionComponent } from '../create/create-submission.component';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oir-submission-search',
  templateUrl: './submission-search.component.html',
  styleUrls: ['./submission-search.component.less']
})
export class SubmissionSearchComponent implements OnInit, OnDestroy, CreateSubmissionContainer {
  @ViewChild(CreateSubmissionComponent) createComponent: CreateSubmissionComponent;

  public form: FormGroup;
  public model: AdvancedFiltersOptions = new AdvancedFiltersOptions();
  public sortOptionsList: Array<SelectValue<any>> = [];
  public submissionStatus = SubmissionStatus;
  public submissionCategory = SubmissionCategory;
  public subCategoryAliases = SubCategoryAliases;
  public showOutcomes: boolean = true;
  public showSubCategory: boolean = true;
  public userOrRoleRequired: boolean = false;
  public results$: Observable<SearchResultsModel>;

  private dataModel: AdvancedFiltersOptions = new AdvancedFiltersOptions();

  constructor(
    private formBuilder: FormBuilder,
    private searchService: SubmissionSearchService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      freeText: '',
      categoryId: [{ value: null, disabled: true }],
      subCategoryIds: [{value: [], disabled: true}],
      statusIds: [{ value: [], disabled: true }],
      forumIds: [{ value: [], disabled: true }],
      movementIds: [{ value: [], disabled: true }],
      userOrRoleId: [{ value: null, disabled: true }],
      outcomeIds: [{ value: [], disabled: true }],
      sortBy: SortByEnum.LastUpdatedDate
    });

    this.setupDropdowns();

    this.setupSubscriptions();
  }

  ngOnDestroy() {}

  clearFilter(): void {
    this.form.get('freeText').reset('');
    this.form.get('categoryId').reset(null);
    this.form.get('subCategoryIds').reset([]);
    this.form.get('statusIds').reset([]);
    this.form.get('forumIds').reset([]);
    this.form.get('movementIds').reset([]);
    this.form.get('userOrRoleId').reset(null);
    this.form.get('outcomeIds').reset([]);

    this.form.markAsPristine();
  }

  search(): void {
    this.results$ = this.searchService.search(this.form.value);
  }

  changePage(pageNumber) {
    this.results$ = this.searchService.changePage(pageNumber);
  }

  sort(sortOption) {
    this.results$ = this.searchService.sort(sortOption);
  }

  toggle(): void {
    this.createComponent.toggle();
  }

  getPaginationOptions(results: SearchResultsModel): PaginationOptions {
    return {
      id: 'pagination',
      itemsPerPage: results.take,
      currentPage: results.currentPage,
      totalItems: results.count
    }
  }

  getDisplayFrom(results: SearchResultsModel): number {
    return results.skip + 1;
  }

  getDisplayTo(results: SearchResultsModel): number {
    return Math.min(results.skip + results.take, results.count);
  }

  trackBySubmisisonId(index, item) {
    return item ? item.submissionNumber : index;
  }

  trackByBillId(index, item) {
    return item ? item.bill.id : index;
  }

  private setupSubscriptions(): void {
    this.form.get('categoryId').valueChanges.subscribe((categoryId) => {
      this.onCategoryChange(categoryId);
    });

    this.form.get('movementIds').valueChanges.subscribe(() => {
      this.onMovementChange();
    });

    this.form.get('userOrRoleId').valueChanges.subscribe(() => {
      this.onUserOrRoleChange();
    });

    this.form.get('sortBy').valueChanges.subscribe((sortOption) => {
      this.sort(sortOption);
    });
  }

  private setupDropdowns(): void {
    this.getDropdownData();

    this.sortOptionsList = this.loadSortingOptions();
  }

  private getDropdownData(): void {
    this.searchService.getDropdownData().subscribe(
      (options: AdvancedFiltersOptions) => {
        this.dataModel = options;
        this.populateDropdowns(this.dataModel);
        this.initiateDropdowns();
    });
  }

  private initiateDropdowns(): void {
    this.userOrRoleRequired = false;
    this.model.forums = this.dataModel.forums.filter(x => x.value.categoryId === SubmissionCategory.PMB);
    this.enableControls(this.form.get('categoryId'),
                        this.form.get('statusIds'),
                        this.form.get('forumIds'),
                        this.form.get('movementIds'),
                        this.form.get('userOrRoleId'));
  }

  private populateDropdowns(options: AdvancedFiltersOptions): void {
    this.model.categories = options.categories;
    this.model.subCategories = options.subCategories;
    this.model.statuses = options.statuses;
    this.model.forums = options.forums;
    this.model.movements = options.movements;
    this.model.usersAndRoles = options.usersAndRoles;
    this.model.outcomes = options.outcomes;
  }

  private loadSortingOptions(): Array<SelectValue<any>> {
    return [
      { value: SortByEnum.LastUpdatedDate, title: this.translateService.instant('SEARCHSUBMISSIONS.SORTBY.LASTUPDATEDATE') },
      { value: SortByEnum.BillNumberDescending, title: this.translateService.instant('Bill number descending') }
    ];
  }

  private onCategoryChange(categoryId?: number): void {
    this.showSubCategory = this.showOutcomes = false;

    if (categoryId) {
      const subCategory = this.form.get('subCategoryIds');
      const forum = this.form.get('forumIds');
      const outcome = this.form.get('outcomeIds');

      this.resetControls(subCategory, forum, outcome);
      this.enableControls(subCategory, forum, outcome);

      this.model.forums = this.dataModel.forums.filter(x => x.value.categoryId === categoryId);
      this.model.subCategories = this.dataModel.subCategories.filter(x => x.value.categoryId === categoryId);

      if (categoryId === SubmissionCategory.PMB || categoryId === SubmissionCategory.FinancialImplications) {
        this.showOutcomes = true;
        this.model.outcomes = this.dataModel.outcomes.filter(x => x.value.categoryId === categoryId);
      }

      this.showSubCategory = categoryId === SubmissionCategory.PMB ||
                             categoryId === SubmissionCategory.Amendments ||
                             categoryId === SubmissionCategory.Other;

    }
  }

  private onMovementChange(): void {
    this.setUserOrRoleValidation();
  }

  private onUserOrRoleChange(): void {
    this.setUserOrRoleValidation();
  }

  private setUserOrRoleValidation(): void {
    this.userOrRoleRequired = false;
    const userOrRoleId = this.form.get('userOrRoleId');
    const movementIds = this.form.get('movementIds');
    if (movementIds && movementIds.value && movementIds.value.length > 0) {
      if (!userOrRoleId.value) {
        this.userOrRoleRequired = true;
      }
    }
  }

  private resetControls(...controls: Array<any>): void {
    controls.forEach(x => x.reset());
  }

  private enableControls(...controls: Array<any>): void {
    controls.forEach(x => x.enable());
  }

}


