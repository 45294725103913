import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import {
  ConfigurationService,
  NavigationService,
  OirAuthService
} from 'proceduralsystem-clientcomponents';;
import { UserRoleRank } from './models/roles.enum.model';
import { AppConfig, AppConfigService } from './services/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.less']
})
export class PageComponent implements OnInit {

  router: any;
  activatedRoute: any;
  titleService: any;

  constructor(
    private navigationService: NavigationService,
    private config: AppConfigService,
    private configurationService: ConfigurationService<AppConfig>,
    protected authService: OirAuthService
  ) {}

  ngOnInit() {
    this.router?.events
      .filter((event) => event instanceof NavigationEnd)
      ?.map(() => this.activatedRoute)
      ?.map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter((route) => route.outlet === "primary")
      .mergeMap((route) => route.data)
      .subscribe((event) => this.titleService.setTitle(event["title"]));

    if (+this.config.getValue('RoleRank') >= UserRoleRank.BillsOffice) {
      this.initNavigation(this.configurationService.getValue('ModuleName'));
    }
  }

  private initNavigation(moduleName: string): void {
    const tree = [];
    tree.push({ title: "TASKMANAGER.TITLE", path: "/task-manager" });
    tree.push({
      title: "SEARCHSUBMISSIONS.SEARCHSUBMISSIONS",
      path: "/submission-search",
    });
    tree.push({ title: "Create a submission", path: "/create-submission" });
    tree.push({ title: "Search principles", path: "/principle-search" });
    if (+this.config.getValue('RoleRank') > UserRoleRank.BillReviewers) {
      tree.push({
        title: "Establish principle",
        path: "/principle/establish-edit",
      });
    }
    tree.push({ title: "Edit principle topics", path: "/principle-topics" });
    this.configurationService.setCookie("lang", "en", 30);

    this.navigationService.init({
      module: { title: moduleName ? moduleName : 'Submissions', path: "" },
      tree,
    });
  }
}
