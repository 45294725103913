import { Component, OnInit, OnDestroy } from "@angular/core";
import { SubmissionService } from "../submission.service";
import { Submission, SubmissionCategory } from "../submission.model";
import { AppConfigService } from "../../services/app-config.service";

@Component({
  selector: "oir-submission-header",
  templateUrl: "header.component.html",
  styleUrls: ["./header.component.less"],
})

export class SubmissionHeaderComponent implements OnInit, OnDestroy {
  public submission: Submission;
  private subscription;
  public category = SubmissionCategory;

  constructor(
    private submissionService: SubmissionService,
    private config: AppConfigService
  ) {}

  ngOnInit() {
    this.subscription = this.submissionService.submission$.subscribe(
      (model) => {
        this.submission = model.submission;
        if (this.submission.bill.billInfo) {
          this.submission.bill.billUrl = `${this.config.getValue("BillsAdminWebUrl")}/edit/${this.submission.bill.billInfo.billId}`;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
