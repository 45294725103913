import { Injectable } from "@angular/core";
import { AppConfigService } from "../../../app/services/app-config.service";
import { OirAuthService } from "proceduralsystem-clientcomponents";

@Injectable()
export class SupportInfoService {

  constructor(
    private config: AppConfigService,
    private authService: OirAuthService,
  ) {}

  public downloadDocument(
    fileId: number,
    documentId: number,
    fileName: string
  ) {
    const params =
      documentId !== null ? `documentId=` + documentId : `fileId=` + fileId;
    const file =
      `${this.config.getValue("ApiEndpoint").url}/api/Document?` + params;

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!(document as any).documentMode;
    if (isIE) {
      this.downloadFileIE(file, function (blob) {
        const url = URL.createObjectURL(blob);
        (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
      });
      return;
    }
    this.downloadFile(file, fileName);
  }

  private async downloadFile(url, fileName) {
    const anchor = document.createElement("a");
    document.body.appendChild(anchor);
    const accessToken = await this.authService.getAccessToken().toPromise();
    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${accessToken}`
    );
    fetch(url, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        const objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
      });
  }

  private async downloadFileIE(url, success) {
    const accessToken = await this.authService.getAccessToken().toPromise();
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${accessToken}`
    );
    xhr.responseType = "blob";
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (success) {
          success(xhr.response);
        }
      }
    };
    xhr.send(null);
  }
}
