import { SupportingInformation } from '../support-info/support-info.model';

export class AmendmentContributionSave {
  contributionId: number;
  amendmentGroups: Array<AmendmentGroup>;
}
export class Contribution {
  contributionId: number;
  amendmentGroups: Array<AmendmentGroup>;
  comment: string;
}

export class AmendmentGroup {
  amendmentGroupId: number;
  commentAmendments: Array<CommentAmendment>;
  amendments: Array<BillAmendment>;
}

export class CommentAmendment {
  amendment?: BillAmendment;
  comment: string;
  supportingInformation: SupportingInformation[];
  amendmentDecisionId: number;
}

export class BillAmendment {
  amendmentReferenceId = 0;
  listType = 1;
  numberListReference: number = null;
  whiteListNumber: number = null;
  whiteListReference: number = null;
  chamberMembers: Member[];
}

export enum ListType {
  Numbered = 1,
  White = 2
}

export enum AmendmentView {
  Create,
  FirstReview,
  SecondReview,
  AdditionalReview,
  FinalDecision,
  Readonly,
  Migrated
}

export enum AmendmentDecision {
  Conflict = 0,
  InOrder = 1,
  OutOfOrder = 2,
  Split = 3
}

export enum ReferenceNumberType {
  NumberedList = 1,
  WhiteList = 2
}

export const AmendmentSectionTitles = [
  { 'title': 'Conflicts', 'value': 0 },
  { 'title': 'In Order', 'value': 1 },
  { 'title': 'Out of Order', 'value': 2 }
]

export class Member {
  public typeId: number;
  public title: string;
  public value: number;
  public amendmentSubmitterId: number;
}

export class AmendmentGroupWithIndex {
  public amendmentGroup: any;
  public groupIndex: number;
}
