/// <reference path="./../../../../assets/Plugins/ckeditor.d.ts" />

import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl,
  ControlValueAccessor, NG_VALUE_ACCESSOR, NG_ASYNC_VALIDATORS, AsyncValidator } from '@angular/forms';

import { FinancialImplicationType, FinancialImplicationComment, Submission } from '../../submission.model';
import { Observable, of } from 'rxjs';
import { EditorConfig } from '../../../models/editor-config.model';
import { SelectValue } from 'proceduralsystem-clientcomponents';
import OirCkEditor, { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { AppConfigService } from 'src/app/services/app-config.service';


@Component({
  selector: 'oir-financial-implications-type-create',
  templateUrl: './type-create.component.html',
  styleUrls: ['./type-create.component.less'],
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => FinancialImplicationTypeCreateComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinancialImplicationTypeCreateComponent),
      multi: true
    }
  ]
})
export class FinancialImplicationTypeCreateComponent implements OnInit, ControlValueAccessor, AsyncValidator {
  @Input() parentFormGroup: FormGroup;
  @Input() decisions: Array<SelectValue<any>>;
  @Input() type: FinancialImplicationType;
  @Input() comment: FinancialImplicationComment;
  @Input() isCollapsed: boolean;

  public form: FormGroup;
  public parentForm: FormGroup;
  public submission: Submission;
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;
  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  constructor(private formBuilder: FormBuilder,private configurationService: AppConfigService) { 
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    };
  }

  ngOnInit() {
    this.parentForm = this.parentFormGroup;
    this.form = this.formBuilder.group({
      comment: '',
      decision: '',
      decisionId: [null, [Validators.required], [this.validate.bind(this)]],
      id: null,
      sendtoDate: '',
      typeId: this.type.id,
      user: '',
      supportingInformation: []
    });

    if (this.comment) {
      this.form.patchValue({
        decisionId: this.comment.decisionId,
        comment: this.comment.comment,
        id: this.comment.id,
        supportingInformation: this.comment.supportingInformation
      });
    }

    const thisFormArray = this.parentFormGroup.get('financialImplications') as FormArray;
    thisFormArray.removeAt(this.type.id - 1);
    thisFormArray.insert(this.type.id - 1, this.form);
  }

  writeValue(contribution: any): void {
    if (contribution) {
      this.form.patchValue(contribution);
    }
  }

  validate(c: AbstractControl): Promise<{ [key: string]: any; }> | Observable<{ [key: string]: any; }> {
    return of(c.errors === null ? null : {invalid: true});
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
}
