import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SupportingInformationOptionsEnum } from '../support-info.model';
import { SupportInfoService } from '../support-info.service';
import { SharedService } from '../../../shared/shared.service';
import { AppConfigService } from '../../../services/app-config.service';

@Component({
  selector: 'oir-existing-support-info',
  templateUrl: './existing-support-info.component.html',
  styleUrls: ['./existing-support-info.component.less']
})
export class ExistingSupportInfoComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() index: number;
  @Output() delete = new EventEmitter();

  public sectionName = '';
  public supportingInformationOptions = SupportingInformationOptionsEnum;

  constructor(
    private supportInfoService: SupportInfoService,
    private sharedService: SharedService,
    private config: AppConfigService
  ) { }

    ngOnInit() {
      if (this.form.get('submissionIdLink').value) {
        if (this.form.get('establishedUnderSectionId').value) {
          this.getSection(this.form.get('submissionIdLink').value, this.form.get('establishedUnderSectionId').value)
                    // IF NewItem, => unsaved, not in DB; => no AmendmentGroupID/ F_I_ID => no accessible description in model!
                        //    => getSectionNames for submissionID, and filter by generic "establishedUnderSectionId".
        }
      }
    }

  getBillsBaseUrl(): string {
    return `${this.config.getValue('BillsAdminWebUrl')}/edit/`;
  }

  getSection(submissionId, sectionId) {
    if (submissionId != null) {
      this.sharedService.getSubmissionSections(submissionId).subscribe(x => {
        const t = x.filter(y => y.value === sectionId);
        if (t.length === 1) {
          this.sectionName = t[0].title;
          }
        }
      )
    }
  }

  public onDelete() {
    this.delete.emit(this.index);
  }

  public onDownloadDocument(fileName: any) {
    const regexp = new RegExp('\.(PDF|doc|odt) &', 'i');
    const match = fileName.match(regexp);

    if (match) {
      fileName = 'documents.zip';
    }

    this.supportInfoService.downloadDocument(null, this.form.get('documentId').value, fileName);
  }

  public getFileExtension(fileName: any): string {
    let extension;
    const regexp = new RegExp('\.(pdf|doc|odt) &', 'i');
    const match = fileName.match(regexp);

    if (match) {
      extension = 'multi'
    } else if (fileName.indexOf('.odt') !== -1) {
      extension = 'odt';
    } else if (fileName.indexOf('.pdf') !== -1) {
      extension = 'pdf';
    } else {
      extension = 'doc';
    }
    return extension;
  }

  get billIdLink(): number {
    return this.form.get('billIdLink').value;
  }
}
