
export class ListItem {
  id: number;
  name: string;
}

export class ErrorModel {
  error: string;
  method: string;
}

export class ValidModel {
  public id: number;
  public clean: boolean;
}

