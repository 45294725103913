import { Component, OnInit, forwardRef, Input, OnDestroy } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  AbstractControl,
  Validators,
  NG_ASYNC_VALIDATORS
} from '@angular/forms';
import { ListType } from '../amendment.model';
import { Subscription, BehaviorSubject, Observable, of } from 'rxjs';

@Component({
  selector: 'oir-amendment-final-decision',
  templateUrl: './amendment-final-decision.component.html',
  styleUrls: ['./amendment-final-decision.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmendmentFinalDecisionComponent),
      multi: true
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => AmendmentFinalDecisionComponent),
      multi: true
    }
  ]
})
export class AmendmentFinalDecisionComponent implements OnInit, OnDestroy {

  @Input() group: any;
  @Input() showValidations$ = new BehaviorSubject(false);

  public amendments: any;
  public form: FormGroup;
  public readonly = true;

  private formSubscription: Subscription;
  private validationSubscription: Subscription;

  get formData() { return <FormArray>this.form.get('billAmendments'); }

  constructor(private fb: FormBuilder) { }

  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  ngOnInit() {
    this.form = this.fb.group({
      amendmentGroupId: 0,
      billAmendments: this.fb.array([]),
      supportingInformation: []
    });

    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.propagateChange(this.form.value)
    });

    this.validationSubscription = this.form.statusChanges.subscribe((value) => {
      this.validate(this.form);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();
    }
  }

  writeValue(obj: any): void {
    this.amendments = obj.billAmendments;

    this.form.setValue({
      amendmentGroupId: obj.amendmentGroupId,
      billAmendments: [],
      supportingInformation: obj.supportingInformation ? obj.supportingInformation : []
    });

    this.setBillAmendments(obj.billAmendments)
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  validate(c: AbstractControl): Promise<{ [key: string]: any; }> | Observable<{ [key: string]: any; }> {
    return of(this.form.valid ? null : { invalid: true });
  }

  private setBillAmendments(billAmendments) {
    const arr = <FormArray>this.form.controls['billAmendments'];

    for (let i = 0; i < billAmendments.length; i++) {
      const amendment = billAmendments[i];

      arr.push(this.fb.group({
        amendmentId: amendment.amendmentId,
        listType: new FormControl(amendment.listType),
        numberListReference: [
          { value: amendment.numberListReference, disabled: amendment.listType === 2 },
        ],
        whiteListNumber: [
          { value: `${amendment.whiteListNumber}`, disabled: amendment.listType === 1 }
        ],
        whiteListReference: [
          { value: amendment.whiteListReference, disabled: amendment.listType === 1 }
        ]
      }));

      this.updateListType(i);
    }
  }

  public updateListType(index) {
    const arr = <FormArray>this.form.controls['billAmendments'];
    const control = arr.controls[index];

    const numberListReference = control.get('numberListReference');
    const whiteListNumber = control.get('whiteListNumber');
    const whiteListReference = control.get('whiteListReference');

    if (control.value.listType === ListType.Numbered) {
      numberListReference.enable();
      numberListReference.setValidators(Validators.required);
      numberListReference.setValidators([Validators.required, Validators.pattern('([A-Za-z]?)+[0-9]+([A-Za-z]?)+')]);

      whiteListNumber.disable();
      whiteListNumber.clearValidators();

      whiteListReference.disable();
      whiteListReference.clearValidators();
    } else {
      numberListReference.disable();
      numberListReference.clearValidators();

      whiteListNumber.enable();
      whiteListNumber.setValidators(Validators.required);

      whiteListReference.enable();
      whiteListNumber.setValidators([Validators.required, Validators.pattern('[0-9]+')]);
      whiteListReference.setValidators([Validators.required, Validators.pattern('[0-9]+')]);
    }

    numberListReference.updateValueAndValidity();
    whiteListNumber.updateValueAndValidity();
    whiteListReference.updateValueAndValidity();
  }
}
