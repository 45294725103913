import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface CreateSubmissionContainer {
  toggle: () => void;
}

@Injectable()
export class CreateSubmissionContainerGuard implements CanDeactivate<CreateSubmissionContainer> {
  canDeactivate(component: CreateSubmissionContainer, _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const result = nextState.url === '/create-submission';
    if (result) { component.toggle(); }

    return !result;
  }
}
