import { Component, OnInit, forwardRef, Input, EventEmitter, Output, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import {
  NG_VALUE_ACCESSOR, FormBuilder, FormGroup, FormArray, Validators, NG_ASYNC_VALIDATORS, AbstractControl
  , FormControl
} from '@angular/forms';
import { AmendmentView, AmendmentDecision } from '../amendment.model';
import { Observable, of, Subscription } from 'rxjs';
import { ValidModel } from '../../../models/app.models';
import { ValidationService } from '../../../shared/validation.service';
import { SupportInfoComponent } from '../../../submission/support-info/support-info.component';
import { SubmissionComponent } from '../../submission.component';
import {  EditorConfig } from '../../../models/editor-config.model';
import { AppConfigService } from '../../../services/app-config.service';
import OirCkEditor, { OirCkEditorConfig } from 'proceduralsystem-ckeditor';


@Component({
  selector: 'oir-amendment-edit',
  templateUrl: './amendment-edit.component.html',
  styleUrls: ['./amendment-edit.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmendmentEditComponent),
      multi: true
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => AmendmentEditComponent),
      multi: true
    }
  ]
})
export class AmendmentEditComponent implements OnInit, OnDestroy {
  @Input() decisions;
  @Input() group;
  @Input() amendmentView;

  @Output() onUpdate = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  @ViewChildren('supportinfo') supportInfoComponents: QueryList<SupportInfoComponent>;

  public form: FormGroup;
  public readonly = true;
  public newDecision = true;
  public isSplitDecision = false;
  public canSplitDecision = false;
  public noSplitDecisions = [];
  public mustSplitDecision = false;
  public amendmentDecision = AmendmentDecision;
  public isConflict = false;
  formID: number;

  private decisionSubscription: Subscription;
  private contribution: any;

  private subscription: Subscription;

  get formData() { return <FormArray>this.form.get('commentAmendments'); }
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;
  constructor(private fb: FormBuilder,
    private config: AppConfigService,
    readonly validationService: ValidationService,
    readonly submissionComponent: SubmissionComponent) {
    this.formID = Math.floor(Math.random() * 90000) + 10000;
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.config.getValue('CKEditor5LicenseKey')
    };
  }

  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  ngOnInit() {
    this.form = this.fb.group({
      amendmentGroupId: 0,
      commentAmendments: this.fb.array([]),
    });

    this.canSplitDecision = this.group.canSplitDecision;
    this.noSplitDecisions = this.decisions.slice(0);
    this.noSplitDecisions.splice(this.noSplitDecisions.length - 1, 1);
    this.subscription = this.form.valueChanges.subscribe((data) => {
      if (this.form.touched) {
        this.makeValidationChange(!this.form.touched);
        this.propagateChange(this.form.value);
      }
    });
    this.validationService.addAmendment();
  }

  ngOnDestroy(): void {
    if (this.decisionSubscription) {
      this.decisionSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  writeValue(contribution: any): void {
    contribution.commentAmendments.forEach(x => x.amendmentDecisionId = x.amendmentDecisionId || '');

    this.form.setValue({
      amendmentGroupId: contribution.amendmentGroupId,
      commentAmendments: [],
    });

    this.setCommentAmendments(contribution.commentAmendments);

    this.newDecision = !contribution.commentAmendments.some(x => x.amendmentDecisionId > 0);
    this.checkSplitDecision(false);

    this.contribution = this.form.value;

    setTimeout(() => {
      this.setValidators();

      this.validate(this.form);
      this.propagateChange(this.form.value);
    });
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  validate(c: AbstractControl): Promise<{ [key: string]: any; }> | Observable<{ [key: string]: any; }> {
    return of(this.form.valid ? null : { invalid: true });
  }

  private setCommentAmendments(commentAmendments: any) {
    const arr = <FormArray>this.form.controls['commentAmendments'];

    for (let i = 0; i < commentAmendments.length; i++) {
      arr.push(this.fb.group({
        commentAmendmentId: commentAmendments[i].commentAmendmentId,
        contributionId: commentAmendments[i].contributionId,
        amendmentDecisionId: commentAmendments[i].amendmentDecisionId,
        comment: commentAmendments[i].comment,
        supportingInformation: new FormControl(commentAmendments[i].supportingInformation == null ?
          [] : commentAmendments[i].supportingInformation),
        billAmendment: this.fb.group({
          amendmentId: commentAmendments[i].billAmendment ? commentAmendments[i].billAmendment.amendmentId : -1,
          amendmentReference: this.getAmendmentReference(commentAmendments[i].billAmendment)
        })
      }));
    }
  }

  private addEmptyDecision(arr, billAmendment, markastouched = true) {
    arr.push(this.fb.group({
      billAmendment: this.fb.group({
        amendmentId: billAmendment ? billAmendment.amendmentId : -1,
        amendmentReference: this.getAmendmentReference(billAmendment)
      }),
      commentAmendmentId: 0,
      contributionId: 0,
      amendmentDecisionId: '',
      comment: '',
      supportingInformation: []
    }));
    if (markastouched) {
      this.form.markAsTouched();
    }
    setTimeout(() => {
      this.setValidators();
    });
  }

  private checkSplitDecision(markastouched = true) {
    this.mustSplitDecision = this.group
      .contributions
      .filter(x => x.adRoleMemberId !== this.config.getValue('UserRoleMemberId'))
      .some(x => x.commentAmendments.length > 1);

    this.isSplitDecision = this.form.get('commentAmendments').value.length > 1;

    if (!this.isSplitDecision) {
      if (!this.mustSplitDecision) {
        const commentAmendments = (this.form.controls['commentAmendments'] as FormArray).controls[0];
        const amendmentDecision = (commentAmendments as FormGroup).controls['amendmentDecisionId'];

        if (this.decisionSubscription) { this.decisionSubscription.unsubscribe(); }

        this.decisionSubscription = amendmentDecision.valueChanges.subscribe((value) => {
          if (value === AmendmentDecision.Split) {
            this.splitDecision(markastouched);
          }
        });
      } else {
        this.splitDecision(markastouched);
      }
    }
  }

  private splitDecision(markastouched = true) {
    const arr = <FormArray>this.form.controls['commentAmendments'];

    for (let i = 0; i < this.group.amendments.length; i++) {
      this.addEmptyDecision(arr, this.group.amendments[i], markastouched);
    }

    arr.removeAt(0);

    this.isSplitDecision = true;
  }

  private saveFormChanges() {
    this.readonly = true;
    this.contribution = this.form.value;

    setTimeout(() => {
      this.validate(this.form);
      this.propagateChange(this.form.value);
      this.form.markAsPristine();
    });
  }

  private deleteSplitDecisions() {
    const arr = <FormArray>this.form.controls['commentAmendments'];

    this.addEmptyDecision(arr, null);

    while (arr.length > 1) {
      arr.removeAt(0)
    }

    this.newDecision = true;

    this.checkSplitDecision();
  }

  private getAmendmentReference(billAmendment: any) {
    let result = null;

    if (billAmendment) {
      const amendment = this.group.amendments.find(x => x.amendmentId === billAmendment.amendmentId);
      if (amendment) {
        result = amendment.numberListReference || amendment.whiteListReference;
      }
    }

    return result;
  }

  private setValidators() {
    const arr = <FormArray>this.form.controls['commentAmendments'];

    if (this.amendmentView === AmendmentView.FirstReview) {
      arr.controls.forEach(x => {
        const control = x.get('amendmentDecisionId');
        control.setValidators(Validators.required);
        control.updateValueAndValidity();
      });
    } else if (this.amendmentView === AmendmentView.SecondReview) {
      const otherContributions = this.group.contributions.filter(
        x => x.adRoleMemberId !== this.config.getValue('UserRoleMemberId'));

      this.isConflict = this.groupIsConflict(otherContributions);

      if (this.isConflict) {
        arr.controls.forEach(x => {
          const control = x.get('amendmentDecisionId');
          control.setValidators(Validators.required);
          control.updateValueAndValidity();
        });
      }
    }
    setTimeout(() => {
      this.form.updateValueAndValidity();
    });
  }

  public add() {
    const arr = <FormArray>this.form.controls['commentAmendments'];

    if (this.form.valid && arr.controls.every(x => x.get('amendmentDecisionId').value > 0)) {
      this.form.markAsUntouched();
      this.makeValidationChange(true);
      this.saveFormChanges();
      this.onUpdate.emit(this.form.value);
      this.newDecision = false;
      this.supportInfoComponents.forEach((child) => {
        child.makeValidationChange(true);
      });
      setTimeout(() => {
        this.submissionComponent.onSubmit(true, true);
      });
    } else {
      arr.controls.forEach(x => x.get('amendmentDecisionId').markAsTouched());
    }

  }

  public cancel() {
    if (this.isSplitDecision) {
      if (this.contribution.commentAmendments.length === 1 && !this.mustSplitDecision) {
        this.deleteSplitDecisions();
      }
    } else if (this.contribution.commentAmendments.length > 1) {
      this.splitDecision();
    }

    this.form.reset(this.contribution);
    this.readonly = true;
    this.form.markAsUntouched();

    setTimeout(() => {
      this.setValidators();
      this.validate(this.form);
      this.propagateChange(this.form.value);
      this.makeValidationChange(true);
      this.supportInfoComponents.forEach((child) => {
        child.makeValidationChange(true);
      });
    });
  }

  public delete() {
    this.onDelete.emit(this.contribution.commentAmendments[0].contributionId);

    this.deleteSplitDecisions();
    this.saveFormChanges();
    this.form.markAsUntouched();
    this.makeValidationChange(true);
  }

  public onUnsplitChange($event) {
    this.deleteSplitDecisions();

    const arr = <FormArray>this.form.controls['commentAmendments'];
    const commentAmendment = arr.at(0) as FormGroup;

    commentAmendment.controls.amendmentDecisionId.setValue($event);
  }

  public groupIsConflict(groupContributions: any) {
    return groupContributions.some(x => x.commentAmendments.some(y => y.decision.amendmentDecisionId === AmendmentDecision.InOrder)) &&
      groupContributions.some(x => x.commentAmendments.some(y => y.decision.amendmentDecisionId === AmendmentDecision.OutOfOrder));
  }

  public makeValidationChange(clean = false) {
    const mValidation = new ValidModel();
    mValidation.id = this.formID;
    mValidation.clean = clean;
    this.validationService.markForm(mValidation);
  }
}
