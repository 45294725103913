import { FileAttachment } from './../support-info.model';
import { AfterViewInit, Component, OnInit, Input, forwardRef, ViewChild, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import {
  RelatedDocument,
  SupportingInformationOptionsEnum,
  RelatedDocumentEnum
} from '../support-info.model';
import * as moment from 'moment';
import { SelectValue } from 'proceduralsystem-clientcomponents';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'oir-upload-other-related-doc',
  templateUrl: './upload-other-related-doc.component.html',
  styleUrls: ['./upload-other-related-doc.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadOtherRelatedDocComponent),
      multi: true
    }
  ]
})
export class UploadOtherRelatedDocComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() documentLanguages = new Array<SelectValue<any>>();
  @Input() otherTypes = new Array<SelectValue<any>>();
  @Input() queueLimit = 1;
  @Input() allowedMimeTypes;
  @Output() onUploadDocument: EventEmitter<boolean> = new EventEmitter(false);
  @Output() onFormClose: EventEmitter<boolean> = new EventEmitter(false);

  @ViewChild('otherInput') otherInput: any;

  public extension = null;
  public documentUniqueId = moment().format('YYYY-DD-MM h:mm:ss');
  public otherUploader: FileUploader
  public relatedDocumentForm: FormGroup;

  propagateChange = (_: RelatedDocument) => {};
  propagateTouch = (_: any) => {};

  constructor(private formBuilder: FormBuilder) {}

  ngAfterViewInit(): void {
    this.otherUploader = new FileUploader({
      allowedMimeType: this.allowedMimeTypes,
      queueLimit: this.queueLimit
    });
  }

  writeValue(document: RelatedDocument): void {
    if (document) {
      document.documentDate = moment.utc(document.documentDate, ['DD/MM/YYYY']).toISOString();
      this.relatedDocumentForm.patchValue(document, { emitEvent: false });
    }
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  ngOnInit() {
    this.relatedDocumentForm = this.formBuilder.group({
      supportInfoTypeId: SupportingInformationOptionsEnum.NewDocument,
      documentTypeId: RelatedDocumentEnum.Other,

      documentLanguageId: [null, Validators.required],
      documentDate: ['', Validators.required],
      documentUniqueId: this.documentUniqueId,
      documentUserDescription:  ['', Validators.required],
      newFileContent: new FormData(),
      docDesc: '',
      attachedDocuments: []
    });
    this.relatedDocumentForm.valueChanges.subscribe((document) =>
        this.propagateChange(document)
    );
  }

  private uploadFileData() {
    const files = Array<FileAttachment>();

    this.otherUploader.queue.forEach(x => {
      if (x._file.type !== '') {
        const f = new FileAttachment();
        f.name = x._file.name;
        f.content = x._file;
        f.documentUniqueId = this.documentUniqueId;
        files.push(f);
      }
    });

    this.relatedDocumentForm.get('attachedDocuments').setValue(files);
    this.relatedDocumentForm.get('documentUniqueId').setValue(this.documentUniqueId);
  }

  replaceFile() {
    if (this.otherUploader.queue.length > this.queueLimit) {
      this.deleteFile(this.otherUploader.queue[0]);
    }
    this.otherInput.nativeElement.value = '';
  }

  private deleteFile(item: any) {
    item.remove();
  }

  async upload() {
    this.uploadFileData();
    this.onUploadDocument.emit(true);
    setTimeout(() => {
      this.relatedDocumentForm.reset();
    });
  }

  public noFilesUploaded(): any {
    return this.otherUploader.queue.length === 0;
  }
}
