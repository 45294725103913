import { SelectValue } from 'proceduralsystem-clientcomponents';

export class LoadSearchModel {
  topics: SelectValue<any>[];
  forums: SelectValue<any>[];
  sortByOptions: SelectValue<any>[];
}

export class FilterSearchModel {
  freeText: string;
  topicsIds: number[];
  forumId: number;
  sortBy: number = SortByEnum.PrincipleNumber;
  skip;
  take;
  currentPage: number;
}

export class ResultsModel {
  numberOfResults: number;
  principlesResult: ItemResultModel[];
  skip;
  take;
  currentPage: number;
}

export class ItemResultModel {
  principleId: number;
  principleNumber: number;
  principleTitle: string;
  dateEstablished: string;
  citationDate: string;
  forum: string;
  principleTopicList: TopicsListModel[];
  billId: number;
  billShortTitle: string;
  billNumber: string;
  billYear?: number;
  submissionValue: string;
  lastUpdated: string;
  citationCount?: number;
}

export class TopicsListModel {
  topicID: number;
  topicDescription: string;
}

export enum SortByEnum {
  PrincipleNumber,
  MostUsed,
  DateEstablished,
  LastUpdated
}
