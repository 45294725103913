import { CKEditorToolbarItems, OirCkEditorConfig } from "proceduralsystem-ckeditor";

interface localCKEditorConfig extends OirCkEditorConfig {
  toolbar: {
    items: Array<CKEditorToolbarItems | string>
  };
  fontSize: {
    options: Array<number | string>
  } | undefined;
}


export const EditorConfig: localCKEditorConfig = {
  toolbar: {
    items: [
      CKEditorToolbarItems.Bold,
      CKEditorToolbarItems.Italic,
      CKEditorToolbarItems.Underline,
      CKEditorToolbarItems.Strikethrough,
      CKEditorToolbarItems.SEPARATOR,
      CKEditorToolbarItems.NumberedList,
      CKEditorToolbarItems.BulletedList,
      CKEditorToolbarItems.SEPARATOR,
      CKEditorToolbarItems.Outdent,
      CKEditorToolbarItems.Indent,
      CKEditorToolbarItems.SEPARATOR,
      CKEditorToolbarItems.Heading,
      CKEditorToolbarItems.SEPARATOR,
      CKEditorToolbarItems.FontSize,
      CKEditorToolbarItems.SEPARATOR,
      CKEditorToolbarItems.FullScreen,
      CKEditorToolbarItems.Link
    ]
  },
  fontSize: {
    options: ['default', 8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72]
  },
  removePlugins: ['Title', 'Markdown'],
  placeholder: '',
  licenseKey: ''
}
