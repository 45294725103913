import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { Observable, of } from 'rxjs';

import {
  EventType,
  InteractionType,
  PublicClientApplication
} from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalRedirectComponent,
  MsalModule,
  MsalService,
  MsalGuard,
  MsalBroadcastService
} from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common/dist/response/AuthenticationResult';

import { ClientFrameworkModule } from 'proceduralsystem-clientcomponents';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileUploadModule } from 'ng2-file-upload';

import { AppConfigService } from './services/app-config.service';

import { ViewPrincipleComponent } from './principles/view-principle/view-principle.component';
import { EstablishEditComponent } from './principles/establish-edit/establish-edit.component';
import { PrincipleTopicsComponent } from './principle-topics/principle-topics.component';
import { TaskManagerComponent } from './task-manager/task-manager.component';
import { SubmissionComponent } from './submission/submission.component';
import { PrincipleService } from './principles/principle.service';
import { ExpirePrincipleComponent } from './principles/expire-principle/expire-principle.component';
import { LinkSubmissionComponent } from './shared/link-submission.component';
import { SharedService } from './shared/shared.service';
import { ValidationService } from './shared/validation.service';
import { PrincipleSearchService } from './principles/principle-search/principle-search.service';
import { PrincipleTopicsService } from './principle-topics/principle-topics.service';
import { PrincipleSearchComponent } from './principles/principle-search/principle-search.component';
import { CitedSubmissionsComponent } from './principles/cited-submissions/cited-submissions.component';
import { SubmissionService } from './submission/submission.service';
import { SubmissionHeaderComponent } from './submission/header/header.component'
import { SubmissionStatusComponent } from './submission/status/status.component';
import { SubmissionContributionComponent } from './submission/contribution/contribution.component';
import { SubmissionResolve } from './submission/submission.resolve';
import { ContributionViewComponent } from './submission/contribution-view/contribution-view.component';
import { CreateSubmissionComponent } from './submission/create/create-submission.component';
import { CreateSubmissionContainerGuard } from './submission/create/create-submission-container-guard';
import { SafeHtmlPipe } from './safe-html-pipe';
import { RemoveRequiredPipe } from './remove-required-pipe';
import { SupportInfoComponent } from './submission/support-info/support-info.component';
import { ExistingSupportInfoComponent } from './submission/support-info/existing-support-info/existing-support-info.component';
import { UploadOtherRelatedDocComponent } from './submission/support-info/upload-other-related-doc/upload-other-related-doc.component';
import { SupportInfoViewComponent } from './submission/support-info-view/support-info-view.component';
import { TaskManagerService } from './task-manager/task-manager.service';
import { TaskManagerResolve } from './task-manager/task-manager.resolve';
import { SupportInfoService } from './submission/support-info/support-info.service';
import { FinancialImplicationsComponent } from './submission/financial-implications/financial-implications.component';
import { FinancialImplicationTypePanelComponent } from './submission/financial-implications/type-panel/type-panel.component';
import { FinancialImplicationTypeViewComponent } from './submission/financial-implications/type-view/type-view.component';
import { FinancialImplicationTypeCreateComponent } from './submission/financial-implications/type-create/type-create.component';
import { ContributionViewAmdFiComponent } from './submission/contribution-view-amd-fi/contribution-view-amd-fi.component';
import { SubmissionSearchComponent } from './submission/submission-search/submission-search.component';

import { AmendmentComponent } from './submission/amendment/amendment.component';
import { AmendmentService } from './submission/amendment/amendment.service';
import { SubmissionSearchService } from './submission/submission-search/submission-search.service';
import { AmendmentGroupComponent } from './submission/amendment/amendment-group/amendment-group.component';
import { AmendmentReferenceComponent } from './submission/amendment/amendment-reference/amendment-reference.component';
import { AmendmentCreateComponent } from './submission/amendment/amendment-create/amendment-create.component';
import { AmendmentEditComponent } from './submission/amendment/amendment-edit/amendment-edit.component';
import { AmendmentSummaryComponent } from './submission/amendment/amendment-summary/amendment-summary.component';
import { AmendmentFinalDecisionComponent } from './submission/amendment/amendment-final-decision/amendment-final-decision.component';
import { environment } from '../environments/environment';
import { PageComponent } from './page.component';
import { TabViewModule } from 'primeng/tabview';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HttpCacheInterceptor } from './services/app-cache-interceptor.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(
  httpClient: HttpClient,
  config: AppConfigService
): TranslateHttpLoader {
  return new TranslateHttpLoader(
    httpClient,
    './assets/i18n/',
    `.json?v=${config.getValue('VersionNumber')}`
  );
}

export class TranslationHandlerService implements MissingTranslationHandler {
  /**
   * Missing translation handler.
   */
  public handle(params: MissingTranslationHandlerParams): Observable<string> {
    return of(params.key);
  }
}

/**
 * MSAL Angular retrieve tokens for authorizaion
 */
export function MSALInstanceFactory(
  config: AppConfigService
): PublicClientApplication {
  const msalConfig = environment.msalConfig;
  const endpoint = config.getValue('AzureAd');
  if (endpoint) {
    msalConfig.auth.clientId = endpoint.ClientId;
    msalConfig.auth.authority = `${endpoint.Instance}${endpoint.TenantId}`;
  }

  const msalInstance = new PublicClientApplication(msalConfig);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const account = msalInstance.getActiveAccount();
  if (!account) {
    // Set active account on page load
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    } else {
      // handle auth redirect/do all initial setup for msal
      msalInstance.addEventCallback(event => {
        // set active account after redirect
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          msalInstance.setActiveAccount(
            (event.payload as AuthenticationResult).account
          );
          window.location.reload();
        }
      });
    }
  }

  return msalInstance;
}

export function MSALInterceptorConfigFactory(
  config: AppConfigService
): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  let endpoint = config.getValue('ApiEndpoint');
  if (endpoint) {
    protectedResourceMap.set(endpoint.url, endpoint.scopes);
  }
  endpoint = config.getValue('CommsEndpoint');
  if (endpoint) {
    protectedResourceMap.set(endpoint.url, endpoint.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(
  config: AppConfigService
): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: config.getValue('ApiEndpoint').scopes }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    EstablishEditComponent,
    PrincipleTopicsComponent,
    TaskManagerComponent,
    SubmissionComponent,
    SubmissionHeaderComponent,
    SubmissionStatusComponent,
    SubmissionContributionComponent,
    ExpirePrincipleComponent,
    LinkSubmissionComponent,
    PrincipleSearchComponent,
    CitedSubmissionsComponent,
    ViewPrincipleComponent,
    ContributionViewComponent,
    CreateSubmissionComponent,
    SafeHtmlPipe,
    RemoveRequiredPipe,
    SupportInfoComponent,
    ExistingSupportInfoComponent,
    UploadOtherRelatedDocComponent,
    SupportInfoViewComponent,
    FinancialImplicationsComponent,
    FinancialImplicationTypePanelComponent,
    FinancialImplicationTypeViewComponent,
    FinancialImplicationTypeCreateComponent,
    ContributionViewAmdFiComponent,
    SubmissionSearchComponent,
    AmendmentComponent,
    AmendmentGroupComponent,
    AmendmentReferenceComponent,
    AmendmentCreateComponent,
    AmendmentEditComponent,
    AmendmentSummaryComponent,
    AmendmentFinalDecisionComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfigService]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: TranslationHandlerService
      },
      // defaultLanguage: 'en',
      isolate: false
    }),
    ReactiveFormsModule,
    FormsModule,
    ClientFrameworkModule,
    AppRoutes,
    TabViewModule,
    CKEditorModule,
    NgxPaginationModule,
    FileUploadModule,
    MsalModule
  ],
  providers: [
    AppConfigService,
    PrincipleService,
    SharedService,
    ValidationService,
    PrincipleTopicsService,
    PrincipleSearchService,
    TaskManagerService,
    CreateSubmissionContainerGuard,
    PrincipleService,
    SubmissionService,
    SubmissionResolve,
    TaskManagerResolve,
    SupportInfoService,
    SubmissionSearchService,
    AmendmentService,
    { provide: 'debug', useValue: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => () => config.init().toPromise(),
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigService]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCacheInterceptor, multi: true },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }
