/// <reference path="./../../../../assets/Plugins/ckeditor.d.ts" />

import {
  Component,
  Input,
  OnInit,
  forwardRef,
  Output,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  NG_VALUE_ACCESSOR,
  FormControl
} from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ValidationService } from '../../../shared/validation.service';
import { ValidModel } from '../../../models/app.models';
import { AmendmentDecision, AmendmentGroupWithIndex } from './../amendment.model';
import OirCkEditor, { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import {  EditorConfig } from 'src/app/models/editor-config.model';
import { AppConfigService } from 'src/app/services/app-config.service';
@Component({
  selector: 'oir-amendment-create',
  templateUrl: './amendment-create.component.html',
  styleUrls: ['./amendment-create.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmendmentCreateComponent),
      multi: true
    }
  ]
})
export class AmendmentCreateComponent implements OnInit, OnDestroy {
  @Input()
  decisions;
  @Input()
  group: any;
  @Input()
  contribution: any;
  @Input()
  preventCreate: boolean;
  @Input() instanceNr = 0;
  @Output()
  onCreate = new EventEmitter();
  @Output()
  onUpdate = new EventEmitter();
  @Output()
  onCancel = new EventEmitter<void>();

  createMode = true;
  groupObj = [];
  formID: number;

  private subscription: Subscription;
  private validationSubscription: Subscription;

  public noSplitDecisions = [];
  public form: FormGroup;
  public editForm: FormGroup;
  public showValidations$ = new BehaviorSubject(false);
  validService: ValidationService;
  
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  get formData() {
    return <FormArray>this.form.get('billAmendments');
  }

  constructor(
    private fb: FormBuilder,
    private validationService: ValidationService,
    private configurationService: AppConfigService
  ) {
    this.validService = validationService;
    this.formID = Math.floor(Math.random() * 90000) + 10000;
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.configurationService.getValue('CKEditor5LicenseKey')
    }
  }

  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  ngOnInit() {
    this.form = this.fb.group({
      billAmendments: this.fb.array([]),
      comment: ['', Validators.required],
      supportingInformation: [],
      amendmentDecisionId: ['', Validators.required]
    });

    // Check if data exists then open in edit mode.
    if (typeof this.group !== 'undefined') {
      this.createMode = false;
      this.form.patchValue({
        comment: this.group.contributions[0].commentAmendments[0].comment,
        supportingInformation: this.group.contributions[0].commentAmendments[0].supportingInformation,
        amendmentDecisionId: this.group.contributions[0].commentAmendments[0]
          .decision.amendmentDecisionId
      });
      this.contribution.commentAmendments.forEach(
        x => (x.amendmentDecisionId = x.amendmentDecisionId || '')
      );
      this.group.amendments.forEach(element => {
        const obj = {
          amendmentId: element.amendmentId,
          amendmentStatusId: element.amendmentStatusId,
          listType: element.listType,
          numberListReference: element.numberListReference,
          whiteListNumber: element.whiteListNumber,
          whiteListReference: element.whiteListReference,
          amendmentSubmitters: element.amendmentSubmitters
        };
        const cleanObj = this.removeUndef(obj);
        const arrObj = new FormControl();
        arrObj.setValue(cleanObj);
        const items = this.form.get('billAmendments') as FormArray;
        items.push(arrObj);
        this.groupObj.push(cleanObj);
      });

      this.editForm = this.fb.group({
        comment: this.group.contributions[0].commentAmendments[0].comment,
        amendmentGroupId: this.group.amendmentGroupId,
        commentAmendments: this.fb.array([]),
        billAmendments: this.fb.array([]),
        supportingInformation: this.fb.array([]),
        Updated: false
      });

      this.setCommentAmendments(this.contribution.commentAmendments);
      this.form.markAsDirty();

      const mValidation = new ValidModel();
      mValidation.id = this.formID;
      mValidation.clean = false;

      this.validService.markForm(mValidation);
    }

    this.subscription = this.form.valueChanges.subscribe(data => {
      const mValidation = new ValidModel();
      mValidation.id = this.formID;
      mValidation.clean = !this.form.dirty;
      this.validService.markForm(mValidation);
      this.propagateChange(this.form.value);
    });

    this.validationSubscription = this.validationService
      .getValidationCall()
      .subscribe(data => {
        if (data !== 0 && this.form.dirty) {
          this.form.get('amendmentDecisionId').markAsTouched();
          this.form.get('comment').markAsTouched();
          this.showValidations$.next(true);
        }
      });

    const arr = <FormArray>this.form.controls['billAmendments'];

    if (arr.length === 0) {
      this.addAmendmentReference();
    }

    this.noSplitDecisions = this.decisions.slice(0, this.decisions.length - 1);
  }

  // Removes undefined property from object passed into function.
  removeUndef(obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();
    }
  }

  writeValue(_contribution: any): void { }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  public resetForm() {
    // Select editor based on mode.

    this.showValidations$.next(false);
    // If component in edit mode revert back to readonly.
    if (!this.createMode) {
      this.onCancel.emit();
      this.scrollToAmendmentGroup(this.editForm.value.amendmentGroupId);
    } else {
      this.form.reset({
        comment: '',
        amendmentDecisionId: ''
      });
      const arr = <FormArray>this.form.controls['billAmendments'];
      this.addAmendmentReference();
      while (arr.length > 1) {
        arr.removeAt(0);
      }
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();

    const mValidation = new ValidModel();
    mValidation.id = this.formID;
    mValidation.clean = true;
    this.validService.markForm(mValidation);
  }

  public scrollToAmendmentGroup(amendmentGroupId) {
    const scrollVal = this.getOffsetTop(
      document.getElementById(amendmentGroupId)
    );

    if (typeof document.getElementsByClassName('body')[0].scroll !== 'undefined') {
      document.getElementsByClassName('body')[0].scroll({
        top: scrollVal,
        behavior: 'auto'
      });
    } else if (typeof document.getElementsByClassName('body')[0].scrollTop !== 'undefined') {
      document.getElementsByClassName('body')[0].scrollTop = scrollVal;
    }
  }

  public createAmendment() {
    this.showValidations$.next(!this.form.valid);

    if (this.form.valid) {
      this.validService.addAmendment();
      this.propagateChange(this.form.value);
      this.onCreate.emit(this.form.value);
      this.resetForm();
    } else {
      this.form.get('amendmentDecisionId').markAsTouched();
      this.form.get('comment').markAsDirty();
    }
  }

  public updateAmendment() {
    if (this.form.valid) {
      const formArr2 = [];
      this.form.value.billAmendments.forEach((element, index) => {
        // If missing properties add them in.
        if (typeof this.groupObj[index] !== 'undefined') {
          for (const key in this.groupObj[index]) {
            if (!element.hasOwnProperty(key) || (element.hasOwnProperty(key) && typeof element[key] === 'undefined')) {
              element[key] = this.groupObj[index][key];
            }
          }
        }
        if (
          !element.hasOwnProperty('chamberMembers') &&
          typeof this.group.amendments[index]['chamberMembers'] !== 'undefined'
        ) {
          element['chamberMembers'] = this.group.amendments[index][
            'chamberMembers'
          ];
        }
        // Make sure value is number for chamberMembers Id.
        Object.keys(element).forEach(function (key) {
          if (key === 'chamberMembers') {
            element[key].forEach(element2 => {
              Object.keys(element2).forEach(function (objKey) {
                if (objKey === 'value') {
                  element2[objKey] = Number(element2[objKey]);
                }
              });
            });
          }
        });
        formArr2.push(element);
      });

      const decisionObj = {
        amendmentDecisionId: this.form.value.amendmentDecisionId,
        descr:
          this.form.value.amendmentDecisionId === AmendmentDecision.InOrder
            ? 'In Order'
            : 'Out of Order'
      };
      const doUpdate = this.editForm.value.amendmentGroupId !== 0;
      this.editForm.patchValue({
        commentAmendments: [
          {
            amendmentDecisionId: this.form.value.amendmentDecisionId,
            comment: this.form.value.comment,
            decision: decisionObj,
            supportingInformation: this.form.value.supportingInformation,
          }
        ],
        Updated: doUpdate,
        decision: decisionObj
      });
      this.editForm.setControl('billAmendments', this.fb.array(formArr2));
      this.editForm.value.commentAmendments[0].billAmendment = formArr2;
      this.propagateChange(this.editForm.value);

      const aGroupWithIndex = new AmendmentGroupWithIndex();
      aGroupWithIndex.groupIndex = this.group.groupIndex;
      aGroupWithIndex.amendmentGroup = this.editForm.value;
      this.onUpdate.emit(aGroupWithIndex);

      this.resetForm();
      this.scrollToAmendmentGroup(this.editForm.value.amendmentGroupId);
    } else {
      this.showValidations$.next(true);
      this.form.get('amendmentDecisionId').markAsTouched();
      this.form.get('comment').markAsTouched();
    }
  }

  public getOffsetTop(elem) {
    let offsetTop = -50;
    do {
      if (!isNaN(elem.offsetTop)) {
        offsetTop += elem.offsetTop;
      }
    } while ((elem = elem.offsetParent));
    return offsetTop;
  }

  private setCommentAmendments(commentAmendments: any) {
    let amendmentId = -1;
    const arr = <FormArray>this.editForm.controls['commentAmendments'];

    for (let i = 0; i < commentAmendments.length; i++) {
      if (
        commentAmendments[i].hasOwnProperty('billAmendments') &&
        commentAmendments[i].billAmendments.hasOwnProperty('amendmentId')
      ) {
        amendmentId = commentAmendments[i].billAmendments.amendmentId;
      }
      arr.push(
        this.fb.group({
          commentAmendmentId: commentAmendments[i].commentAmendmentId,
          contributionId: commentAmendments[i].contributionId,
          amendmentDecisionId: commentAmendments[i].amendmentDecisionId,
          comment: commentAmendments[i].comment,
          supportingInformation: new FormControl(commentAmendments[i].supportingInformation == null ?
            [] : commentAmendments[i].supportingInformation),
          billAmendment: this.fb.group({
            amendmentId: amendmentId,
            amendmentReference: this.getAmendmentReference(
              commentAmendments[i].billAmendment
            )
          }),
          decision: commentAmendments[i].decision
        })
      );
    }
  }

  private getAmendmentReference(billAmendment: any) {
    let result = null;

    if (billAmendment) {
      const amendment = this.group.amendments.find(
        x => x.amendmentId === billAmendment.amendmentId
      );
      if (amendment) {
        result = amendment.numberListReference || amendment.whiteListReference;
      }
    }

    return result;
  }

  public addAmendmentReference() {
    const arr = <FormArray>this.form.controls['billAmendments'];

    arr.push(
      this.fb.control({
        listType: 1,
        numberListReference: '',
        whiteListNumber: '',
        whiteListReference: '',
        chamberMembers: []
      })
    );
  }

  public removeAmendmentReference(i: number) {
    const arr = <FormArray>this.form.controls['billAmendments'];
    arr.removeAt(i);
  }
}
