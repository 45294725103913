import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalComponent, ToastNotificationsService } from 'proceduralsystem-clientcomponents';
import { PrincipleService } from '../principle.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oir-expire-principle',
  templateUrl: './expire-principle.component.html',
})

export class ExpirePrincipleComponent implements OnInit {
  @ViewChild(ModalComponent) modal: ModalComponent;
  public principleTitle = '';
  public principleText = '';
  public expirePrincipleForm: FormGroup;
  public expiryComplete = new Subject();

  constructor(
    private principleService: PrincipleService,
    private toastService: ToastNotificationsService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.expirePrincipleForm = this.formBuilder.group({
      expiryReason: '',
      expiryReasonValue: '',
      principleId: '',
      updatedBy: '',
      AdRoleMemberIdExpiredBy: ''
    });

    setTimeout(() => {
      this.expirePrincipleForm.markAsPristine();
    }, 200);
  }

  public toggle() {
    const expiryReasonValue = this.expirePrincipleForm.get('expiryReason').value;
    this.expirePrincipleForm.get('expiryReasonValue').patchValue(expiryReasonValue);
    this.expirePrincipleForm.get('expiryReason').patchValue('');
    this.expirePrincipleForm.markAsPristine();
    this.modal.toggle();
  }

  public expirePrinciple() {
    this.toggle();
    const expiryReasonValue = this.expirePrincipleForm.get('expiryReasonValue').value;
    this.expirePrincipleForm.get('expiryReason').patchValue(expiryReasonValue);
    this.principleService.expirePrinciple(this.expirePrincipleForm.value).subscribe(
      response => {
        const description = this.translateService.instant(response.value, [this.expirePrincipleForm.value.principleId]);
        const message = { title: 'PRINCIPLE.EXPIRE.SUCCESS.TITLE', description };
        this.toastService.addNotification(message);

        this.expiryComplete.next(true);
      },
      err => {
        const message = { title: 'COMMON.SAVEERROR', description: err.error.error };
        this.toastService.addNotification(message);
      });
  }

}
