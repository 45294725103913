import { Component, Input } from '@angular/core';
import { FinancialImplicationComment } from '../../submission.model';

@Component({
  selector: 'oir-financial-implications-type-view',
  templateUrl: './type-view.component.html',
  styleUrls: ['./type-view.component.less']
})
export class FinancialImplicationTypeViewComponent {
  @Input() model: FinancialImplicationComment;
  @Input() isCollapsed: boolean;
  @Input() lastDecision: boolean;
  @Input() firstDecision: boolean;

  constructor() { }
}
