import { Injectable } from '@angular/core';
import { BehaviorSubject,  Observable } from 'rxjs';
import { ValidModel } from '../models/app.models';

interface Map<T> {
  [K: string]: T;
}

@Injectable()
export class ValidationService {
  formsArr: Map<boolean> = {};
  ids = [];
  amendments = 0;

  private subject = new BehaviorSubject<boolean>(true);
  private subjectSend = new BehaviorSubject<boolean>(false);
  private callValidation = new BehaviorSubject<number>(0);

  activateValidation() {
    let currentVal = this.callValidation.value;
    currentVal++;
    this.callValidation.next(currentVal);
  }

  markForm(model: ValidModel) {
    let result = true;
    // Add to associative array.
    this.formsArr[String(model.id)] = model.clean;
    // Store Ids.
    if (this.ids.indexOf(model.id) === -1) {
      this.ids.push(model.id);
    }
    // If model is clean make sure other models are clean also.
    if (model.clean) {
      for (let i = 0; i < this.ids.length; i++) {
        if (!this.formsArr[String(this.ids[i])]) {
          result = false;
        }
      }
    } else {
      result = false;
    }
    this.subject.next(result);
    this.checkAmendments();
  }

  addAmendment() {
    this.amendments++;
    this.checkAmendments();
  }

  deleteAmendment() {
    this.amendments--;
    this.checkAmendments();
  }

  checkAmendments () {
    // Check if validation is true and an amendment exists.
    this.subjectSend.next(!!this.subject.value && this.amendments > 0);
  }

  resetNoOfAmendments() {
    this.amendments = 0;
    this.formsArr = {};
    this.ids = [];
    this.checkAmendments();
  }

  getValidationCall(): Observable<number> {
    return this.callValidation.asObservable();
  }

  getData(): Observable<boolean> {
    return this.subject.asObservable();
  }

  getDataWithAnAmendment(): Observable<boolean> {
    return this.subjectSend.asObservable();
  }

  getTopInvalidForm(): any {
    for (let i = 0; i < this.ids.length; i++) {
      if (!this.formsArr[String(this.ids[i])]) {
        return this.ids[i];
      }
    }
  }
}


