import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OirAuthService, OirHttpService, ToastNotificationsService } from 'proceduralsystem-clientcomponents';
import { TaskManagerModel, FilterModel, TabType } from './task-manager.component.model';
import { ErrorModel } from '../models/app.models';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../services/app-config.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TaskManagerService {
  public filter: FilterModel;
  public tabsList: string[];
  public appSettings;
  private hasUpdatedLastTab = false;
  private baseUrl: string;

  constructor(
    private http: OirHttpService,
    private readonly config: AppConfigService,
    private toastService: ToastNotificationsService,
    private translateService: TranslateService,
    private authService: OirAuthService
  ) {
    this.filter = new FilterModel();
    this.baseUrl = `/api/TaskManager`;
  }

  public getBillsBaseUrl(): string {
    return `${this.config.getValue('BillsAdminWebUrl')}/edit/`;
  }

  public getTasksForTab(tabType: TabType): Observable<TaskManagerModel> {
    this.filter.skip = 0;
    this.filter.currentPage = 1;
    this.filter.tabType = tabType;
    return this.getTasks();
  }

  public changePageForTab(pageNumber: number, tabType: TabType) {
    this.filter.skip = pageNumber === 1 ? 0 : this.filter.take * (pageNumber - 1);
    this.filter.currentPage = pageNumber;
    this.filter.tabType = tabType;
    return this.getTasks();
  }

  private getTasks(): Observable<TaskManagerModel> {
    const user = this.authService.getUsername();
    const url = `${this.baseUrl}?skip=${this.filter.skip}&take=${this.filter.take}&tabType=${this.filter.tabType}&UserAD=${user}`;

    return this.http.get<TaskManagerModel>(url)?.pipe(
      map((model: TaskManagerModel) => {
        model.tabType = this.filter.tabType;
        model.currentPage = this.filter.currentPage;

        if (!this.hasUpdatedLastTab) {
          this.updateTabsList(model.shouldDisplayUnassignedTab);
          this.hasUpdatedLastTab = true;
        }
        return model;
      },
      errorData => {
        const model = errorData.error as ErrorModel;
        const errorMsg = this.translateService.instant('TASKMANAGER.GENERIC.ERROR.TITLE');
        this.toastService.addNotification({ title: errorMsg, description: model.error });
      }
    ));
  }

  public updateTabsList(shouldDisplayUnassignedTab) {
    const tabTypes = Object.keys(TabType).filter(
      k => typeof TabType[k as any] === 'number'
    );
    if (!shouldDisplayUnassignedTab) {
      tabTypes.pop();
    }
    this.tabsList = tabTypes;
  }

  public togglePriority(submissionId: number, priorityBool: boolean) {
   const data = {
     submissionId : submissionId,
     setPriority : priorityBool,
     UpdatedBy: this.authService.getUsername()
   }
    return this.http.put({ path: this.baseUrl, body: { ...data }});
  }

  public claimTask(id: number, text: string, validFrom: string) {
    const data = {
      SubmissionId: id,
      ClaimReason: text,
      UpdatedBy: this.authService.getUsername(),
      ValidFrom: validFrom,
      DelegateRoleMemberId: this.config.getValue('DelegateRoleMemberId')
    }
    return this.http.post({ path: this.baseUrl, body: { ...data }});
  }
}
