import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ValidationService } from '../../../shared/validation.service';

@Component({
  selector: 'oir-amendment-group',
  templateUrl: './amendment-group.component.html',
  styleUrls: ['./amendment-group.component.less']
})
export class AmendmentGroupComponent implements OnInit {
  @Input() group: any;
  @Input() isFinalDecision: boolean;
  @Input() isCreator: boolean;
  @Input() decisions: any = [];
  @Input() preventEdit: boolean;
  @Output() onCollapse = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onEditClick = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  updateGroup: any = false;

  constructor(private validationService: ValidationService) {
   }

  ngOnInit() {
    this.validationService.addAmendment();
    for (let i = 0; i < this.group.contributions.length; i++) {
      const contribution = this.group.contributions[i];

      for (let j = 0; j < contribution.commentAmendments.length; j++) {
        const commentAmendment = contribution.commentAmendments[j];
        commentAmendment.amendmentReference = this.getAmendmentReference(commentAmendment.amendmentId);
      }
    }
  }

  collapse() {
    this.group.collapsed = !this.group.collapsed;
    this.onCollapse.emit(this.group.collapsed);
  }

  private getAmendmentReference(amendmentId: any) {
    let result = null;

    if (amendmentId) {
      const amendment = this.group.amendments.find(x => x.amendmentId === amendmentId);
      if (amendment) { result = amendment.numberListReference || amendment.whiteListReference; }
    }

    return result;
  }

  public delete(): void {
    this.onDelete.emit(this.group.contributions[0].contributionId);
    this.validationService.deleteAmendment();
  }

  public edit(): void {
    this.updateGroup = true;
    this.onEditClick.emit();
  }

  public createContribution(value: any) {
    this.onCreate.emit(value);
  }

  public updateContribution(value: any) {
    this.onEdit.emit(value);
  }

  public groupCancel() {
    this.updateGroup = false;
    this.onCancel.emit();
  }
}
