
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  AdvancedFiltersOptions, FilterModel, SearchResultsModel, SortByEnum,
  SubCategoryAliases, SubmissionSubCategory, CategoryAliases
} from './submission-search.model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SubmissionSearchService {
  private model: SearchResultsModel;
  private readonly controllerName = 'SubmissionSearch';
  private filterModel = new FilterModel();
  private url;
  private billUrl;
  private submissionUrl;

  constructor(
    private http: HttpClient,
    private config: AppConfigService
  ) {
    this.url = `${this.config.getValue('ApiEndpoint').url}/api/` + this.controllerName;
    this.billUrl = `${this.config.getValue('BillsAdminWebUrl')}/edit/`;
    this.submissionUrl = window.location.origin + '/submission/';
  }

  public getDropdownData(): Observable<AdvancedFiltersOptions> {
    return this.http.get<AdvancedFiltersOptions>(this.url)
      .pipe(
        catchError((error: any) => observableThrowError(error)),
        map(result => {
          return result;
        })
      );
  }

  public search(filter: FilterModel): Observable<SearchResultsModel> {
    this.filterModel = this.initializeFilterModel(filter);
    return this.performSearch(this.filterModel);
  }

  public changePage(pageNumber: number) {
    this.filterModel.skip = pageNumber === 1 ? 0 : this.filterModel.take * (pageNumber - 1);
    this.filterModel.currentPage = pageNumber;
    return this.performSearch(this.filterModel);
  }

  public sort(selectedOption: SortByEnum) {
    this.filterModel.skip = 0;
    this.filterModel.currentPage = 1;
    this.filterModel.sortBy = selectedOption;
    return this.performSearch(this.filterModel);
  }

  private performSearch(filter: FilterModel): Observable<SearchResultsModel> {
    return this.http.post<SearchResultsModel>(this.url, filter)
      .pipe(
        catchError((error: any) => observableThrowError(error)),
        map((response) => {
          this.model = response;

          this.model.skip = filter.skip;
          this.model.take = filter.take;
          this.model.currentPage = filter.currentPage;
          this.updateSearchResultsModel(this.model);

          return this.model;
        })
      );
  }

  private updateSearchResultsModel(model: SearchResultsModel): void {
    if (model.items) {
      model.items.forEach((x) => {
        if (x.bill != null && x.bill.id != null) {
          x.bill.url = this.billUrl + `${x.bill.id}`;
        }
        x.submissions.forEach((y) => {
          y.url = this.submissionUrl + `${y.submissionNumber}`;
          y.submissionCategory = CategoryAliases.get(y.catId);
          y.subCategory = y.subCatId !== null && y.subCatId !== SubmissionSubCategory.Other
                                    ? '(' + SubCategoryAliases.get(y.subCatId) + ')' : '';
        });
      });
    }
  }

  private initializeFilterModel(filter: FilterModel): FilterModel {
    if (filter.categoryId === null) { filter.categoryId = 0; }
    if (filter.subCategoryIds === null) { delete filter.subCategoryIds; }
    if (filter.statusIds === null) { delete filter.statusIds; }
    if (filter.forumIds === null) { delete filter.forumIds; }
    if (filter.movementIds === null) { delete filter.movementIds; }
    if (filter.userOrRoleId === null) { delete filter.userOrRoleId; }
    if (filter.outcomeIds === null) { delete filter.outcomeIds; }
    if (filter.sortBy === null) { filter.sortBy = SortByEnum.LastUpdatedDate; }

    filter.skip = 0;
    filter.currentPage = 1;
    filter.take = 10;

    return filter;
  }

}
