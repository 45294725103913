export class FilterModel {
  skip: number;
  take = 20;
  tabType: TabType;
  currentPage: number;
}

export class TaskManagerModel {
  actionRequiredCount = 0;
  allActiveCount = 0;
  unassignedCount = 0;
  shouldDisplayUnassignedTab: boolean;
  tabType = TabType.ActionRequired;
  tasksResult: TaskModel[];
  currentPage: number;
}

export class TaskModel {
  submissionId: number;
  submissionType?: string;
  submissionMovement?: number;
  billId?: number;
  billTitle: string;
  billNumber: string;
  billYear?: number;
  categoryId: number;
  category: string;
  subCategory: string;
  forum: string;
  currentUserOrRole: string;
  createdOrAssignedAt?: Date;
  shouldDisplayCreatedBy: boolean;
  isPriority: boolean;
  isClaimable: boolean;
  isInDraftByLoggedUser: boolean;
  validFrom: string;
}

export enum TabType {
  ActionRequired,
  AllActive,
  Unassigned
}

export enum Status {
  InDraft = 1,
  InProgress,
  FinalDecisionMade
}
