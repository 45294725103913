import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TaskManagerService } from './task-manager.service';
import { TabType } from './task-manager.component.model';

@Injectable()
export class TaskManagerResolve implements Resolve<any> {
  constructor(private taskManagerService: TaskManagerService) {}

  resolve(_route: ActivatedRouteSnapshot) {
    return this.taskManagerService.getTasksForTab(TabType.ActionRequired);
  }
}
