import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrincipleSearchService } from './principle-search.service';
import { SelectValue, PaginationOptions } from 'proceduralsystem-clientcomponents';
import { SortByEnum, ResultsModel, TopicsListModel } from './principle-search.model';
import { CreateSubmissionContainer } from '../../submission/create/create-submission-container-guard';
import { CreateSubmissionComponent } from '../../submission/create/create-submission.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'oir-principle-search',
  templateUrl: './principle-search.component.html',
  styleUrls: ['./principle-search.component.less']
})

export class PrincipleSearchComponent implements OnInit, CreateSubmissionContainer {
  @ViewChild(CreateSubmissionComponent) createComponent: CreateSubmissionComponent;

  filterForm: FormGroup;
  topicsList: Array<SelectValue<any>> = [];
  forumList: Array<SelectValue<any>> = [];
  sortOptionsList: Array<SelectValue<any>> = [];
  viewPrincipleBaseUrl: string;
  viewBillBaseUrl: string;
  results$: Observable<ResultsModel>;

  constructor(private fb: FormBuilder, public searchService: PrincipleSearchService) {
    this.filterForm = this.fb.group({
      topicsIds: [[]],
      forumId: 0,
      freeText: '',
      take: 20,
      sortBy: SortByEnum.PrincipleNumber
    });

    this.filterForm.get('sortBy').valueChanges.subscribe((data) => {
        this.onSort(data);
    });
  }

  ngOnInit() {
    this.searchService.getReferenceData().subscribe(model => {
      this.topicsList = model.topics;
      this.forumList = model.forums;
      this.sortOptionsList = model.sortByOptions
    });
    this.viewPrincipleBaseUrl = window.location.origin + '/principle/view/';
    this.viewBillBaseUrl = this.searchService.billsBaseUrl;
  }

  get shouldEnableApplyFiltersButton(): boolean {
    return (this.filterForm.value.forumId && this.filterForm.value.forumId  !== 0)
            || this.filterForm.value.topicsIds.length >= 1 || this.filterForm.value.freeText;
  }

  getPaginationOptions(results: ResultsModel): PaginationOptions {
    return {
      id: 'search',
      itemsPerPage: 20,
      currentPage: results.currentPage,
      totalItems: results.numberOfResults
    }
  }

  getDisplayTo(results: ResultsModel): number {
    const to = results.skip + results.take;
    if (to > results.numberOfResults) {
      return results.numberOfResults;
    }
    return to;
  }

  getTopics(list: TopicsListModel[]): string {
    let topics = '';
    if (list !== null) {
      for (let index = 0; index < list.length; index++) {
        topics += list[index].topicDescription;
        if (index !== list.length - 1) {
          topics += '; '
        }
      }
    }
    return topics;
  }

  onClearFilter() {
    this.filterForm.get('topicsIds').reset([]);
    this.filterForm.get('forumId').reset(0);
    this.filterForm.get('freeText').reset('');
    this.filterForm.markAsPristine();
  }

  onSearch() {
    this.results$ = this.searchService.search(this.filterForm.value);
  }

  onChangePage(pageNumber) {
    this.results$ = this.searchService.changePage(pageNumber);
  }

  onSort(selected) {
    this.results$ = this.searchService.sort(selected);
  }

  toggle(): void {
    this.createComponent.toggle();
  }
}
