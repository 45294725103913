import { SelectValue } from 'proceduralsystem-clientcomponents';
import { CitedSubmissionsModel } from './cited-submissions/cited-submissions.model';

export class LoadEditPrincipleModel {
  public principle: PrincipleModel;
  public topics: SelectValue<any>[];
  public forums: SelectValue<any>[];
}
export class PrincipleModel {
  public principleId = 0;
  public number = 0;
  public houseIdForum = 0;
  public houseForumDescr = '';
  public title = '';
  public text = '';
  public additionalDetails = '';
  public establishedUnderBillId = 0;
  public establishedUnderSubmissionId = 0;
  public establishedUnderSectionId = 0;
  public dateEstablished = '';
  public isExpired = false;
  public principleExpiries: Array<PrincipleExpiry> = [];
  public topicIds: Array<number> = [];
  public principleTopics: Array<Topic> = [];
  public principleLinks1: Array<PrincipleLink> = [];
  public principleLinks2: Array<PrincipleLink> = [];
  public citedSubmissions: CitedSubmissionsModel;
  public updatedBy = '';
  public delegateRoleMemberId;
}

export class PrincipleExpiry {
  public principleExpiryId = 0;
  public principleId = 0;
  public expiryReason: string;
  public expiryDate: string;
  public updatedByName: string;
  public updatedByRole: string;
}

export class Topic {
  public topicId: number;
  public descr: string;
  public isActive: boolean;
  public updatedBy: string;
}

export class PrincipleLink {
  public principleLinkId: number;
  public principleId1: number;
  public principleId2: number;
  public principleId2Title: string;
  public principleLinkTypeId: number;
  public principleLinkTypeDescr: string;
  public updatedBy: string;
}

export class LoadReferenceData {
  topics: SelectValue<any>[];
  forums: SelectValue<any>[];

}

export enum SortByEnum {
  MostNotable,
  MostRecent
}

export enum LinkTypeEnum {
  Relate = 1,
  Supercede = 2
}



