import { Injectable } from "@angular/core";
import { SelectValue } from "proceduralsystem-clientcomponents";
import { TopicModel, TopicResultsModel } from "./principle-topics.model";
import { Observable } from "rxjs";
import "rxjs/Rx";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "../services/app-config.service";
import { map, shareReplay } from "rxjs/operators";

@Injectable()
export class PrincipleTopicsService {
  public pageSize = 20;
  public numberOfResults = 0;
  public currentPage = 1;
  private userName: string;
  private baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly config: AppConfigService
  ) {
    this.userName = this.config.getValue("UserName");
    this.baseUrl = `${this.config.getValue("ApiEndpoint").url}/api/Topic`;
  }

  getTopicsByPage(page: number): Observable<TopicModel[]> {
    const url = `${this.baseUrl}?skip=${this.pageSize * (page - 1)}&take=${
      this.pageSize
    }`;
    return this.http.get<TopicResultsModel>(url)
      .pipe(
        map((result: TopicResultsModel) => {
          this.numberOfResults = result.numberOfResults;
          return result.topicsResult;
        }),
        shareReplay()
      );
  }

  changePage(pageNumber: number): Observable<any> {
    this.currentPage = pageNumber;
    return this.getTopicsByPage(this.currentPage);
  }

  getTopics(): Observable<SelectValue<any>[]> {
    return this.http.get<SelectValue<any>[]>(this.baseUrl);
  }

  deleteTopic(id, text) {
    const data = {
      Id: id,
      Name: text,
      UpdatedBy: this.userName,
      DelegateRoleMemberId: this.config.getValue("DelegateRoleMemberId"),
    };
    const url = `${this.baseUrl}?id=${id}&updatedBy=${data.UpdatedBy}`;
    return this.http.delete(url);
  }

  addOrUpdateTopic(id: number, text: string) {
    const data = {
      Id: id,
      Name: text,
      UpdatedBy: this.userName,
      DelegateRoleMemberId: this.config.getValue("DelegateRoleMemberId"),
    };
    return this.http.post(this.baseUrl, data, {
      headers: { ContentType: "application/json" },
    });
  }
}
