import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../services/app-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SharedService {
  constructor(
    private config: AppConfigService,
    private http: HttpClient
  ) { }

  public getBillSubmissions(id: number): Observable<any>  {
    const url = `${this.config.getValue('ApiEndpoint').url}/api/SubmissionRefData/id?Id=${id}`;
    return this.http.get<any>(url);
  }

  public getSubmissionSections(id: number): Observable<any> {
    const url = `${this.config.getValue('ApiEndpoint').url}/api/SubmissionSectionRef/id?Id=${id}`;
    return this.http.get<any>(url);
  }

  public getBill(id: number): Observable<any> {
    const url = `${this.config.getValue('ApiEndpoint').url}/api/BillTitle/id?Id=${id}`;
    return this.http.get<any>(url);
  }
}
