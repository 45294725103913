import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SelectValue, PaginationOptions } from 'proceduralsystem-clientcomponents';
import { PrincipleService } from '../principle.service';
import { SortByEnum } from '../principle.model';
import { CitedSubmissionsModel, SupportInformation } from './cited-submissions.model';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'oir-cited-submissions',
  templateUrl: './cited-submissions.component.html',
  styleUrls: ['./cited-submissions.component.less']
})
export class CitedSubmissionsComponent implements OnInit {
  @Output() onChange: EventEmitter<void> = new EventEmitter();
  @Input() readonly = false;

  public viewBillLink;
  public init = false;
  public sortOptionsList: Array<SelectValue<[]>> = [];
  public sortBy = 0;
  public model = new CitedSubmissionsModel();

  private principleId: number;

  constructor(
    private config: AppConfigService,
    private principleService: PrincipleService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.sortOptionsList = this.loadSortingOptions();
    this.viewBillLink = `${this.config.getValue('BillsAdminWebUrl')}/edit/`;

    this.principleService.principle.subscribe((data:any) => {
      this.principleId = data.principle.principleId;

      this.model.count = data.citedSubmissions.count;
      this.model.items = data.citedSubmissions.items;
      this.model.establishedForSubmission = data.citedSubmissions.establishedForSubmission;
      this.model.take = data.citedSubmissions.pageSize;

      if (this.principleId > 0) { this.init = true; };
    });
  }

  get paginationOptions(): PaginationOptions {
    return {
      id: 'cited',
      itemsPerPage: this.model.take,
      currentPage: this.model.currentPage,
      totalItems: this.model.count
    }
  }

  public markAsNotable(supportInfo: SupportInformation) {
    if (!this.readonly) {
      this.principleService.markSubmissionAsNotable(supportInfo);
      supportInfo.isNotable = !supportInfo.isNotable;

      this.onChange.emit();
    }
  }

  public hasBill(supportInfo: SupportInformation) {
    return supportInfo.billId !== 0;
  }

  public markEstablishedSubmissionAsNotable() {
    if (!this.readonly) {
      this.principleService.markEstablishedSubmissionAsNotable();

      this.onChange.emit();
    }
  }

  public onChangePage(pageNumber) {
    this.model.skip = pageNumber === 1 ? 0 : this.model.take * (pageNumber - 1);
    this.model.currentPage = pageNumber;

    this.search();
  }

  public onSortChange() {
    this.model.skip = 0;
    this.model.currentPage = 1;
    this.search();
  }

  private search() {
    this.principleService.searchCitedSubmission(this.principleId, this.model.skip, this.model.take, this.sortBy);
  }

  private loadSortingOptions(): Array<SelectValue<any>> {
    return [
      { value: SortByEnum.MostNotable, title: this.translateService.instant('PRINCIPLE.FILTER.NOTABLE') },
      { value: SortByEnum.MostRecent, title: this.translateService.instant('PRINCIPLE.FILTER.RECENT') }
    ];
  }

}
