import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { SubmissionService } from './submission.service';
import {
  Submission,
  SubmissionStatus,
  ActionType,
  SubmissionCategory
} from './submission.model';
import {
  ModalComponent,
  NavigationService,
  ConfigurationService,
  ToastNotificationsService,
  OirAuthService
} from 'proceduralsystem-clientcomponents';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute
} from '@angular/router';
import { CreateSubmissionContainer } from '../submission/create/create-submission-container-guard';
import { AmendmentService } from './amendment/amendment.service';
import { ValidationService } from '../shared/validation.service';
import { Subscription } from '../../../node_modules/rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { CreateSubmissionComponent } from './create/create-submission.component';
import { AppConfig, AppConfigService } from '../services/app-config.service';

@Component({
  selector: 'oir-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.less']
})
export class SubmissionComponent implements OnInit, CreateSubmissionContainer, OnDestroy {
  @ViewChild('unsavedChangesModal')
  unsavedChangesModal: ModalComponent;
  @ViewChild('amendmentRequiredModel')
  amendmentRequiredModel: ModalComponent;
  @ViewChild('amendmentIncompleteModel')
  amendmentIncompleteModel: ModalComponent;
  @ViewChild(CreateSubmissionComponent)
  createComponent: CreateSubmissionComponent;

  public submission: Submission;
  public form: FormGroup;
  public userDiscardResponse$ = new Subject<boolean>();
  public selectedNode;
  public completedContributions;
  public readOnly = false;
  public submissionCategory = SubmissionCategory;
  public amendment: any;
  public submissionStatus = SubmissionStatus;
  public formClean = true;
  public formCleanWithAmendment = false;
  public validationService1: Subscription;
  public validationService2: Subscription;
  private closeTab = false;
  private subscription;
  private cameFromCloseButton = false;
  private routerSubscription;

  constructor(
    private submissionService: SubmissionService,
    private navigationService: NavigationService,
    private validationService: ValidationService,
    private formBuilder: FormBuilder,
    public configurationService: ConfigurationService<AppConfig>,
    private readonly config: AppConfigService,
    private toastService: ToastNotificationsService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private amendmentService: AmendmentService,
    private translateService: TranslateService,
    private authService: OirAuthService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      coreContribution: '',
      financialImplications: this.formBuilder.array([]),
      amendmentContribution: '',
      supportingInformation: []
    });
    this.routerSubscription = this.route.url.subscribe(url => {
      this.closeTab = Boolean(
        this.route.snapshot.queryParamMap.get('closeTab')
      );
    });
    this.selectedNode = this.navigationService.currentNode;
    this.subscription = this.submissionService.submission$.subscribe(model => {
      // Reset number of amendments.
      this.validationService.resetNoOfAmendments();
      this.submission = model.submission;
      this.completedContributions = this.submission.contributions.filter(
        x => x.contributionStatusId === 2
      ).length;
      if (
        this.submission.assignedToUser == null ||
        this.submission.assignedToUser.adRoleMemberId === null ||
        this.submission.assignedToUser.adRoleMemberId === 0
      ) {
        this.readOnly = true;
      } else {
        this.readOnly = this.submission.assignedToUser.adRoleMemberId !== +this.config.getValue('UserRoleMemberId');
      }
      const draftContribution = model.submission.contributions.filter(
        x => x.contributionStatusId === 1
      );
      if (draftContribution.length > 0) {
        if (model.submission.leaveToIntroduce) {
          draftContribution[0].leaveToIntroduce = moment(
            model.submission.leaveToIntroduce
          ).format('DD/MM/YYYY');
        }
        this.form.controls['coreContribution'].patchValue(draftContribution[0]);
      } else {
        this.form.controls['coreContribution'].reset();
      }
      if (model.submission.supportingInformation) {
        this.form.controls['supportingInformation'].patchValue(
          model.submission.supportingInformation
        );
      }
      if (model.amendment) {
        this.amendment = model.amendment;
        // Clone amendment groups array and sort it by amendment groupid.
        const sortedAmendmentGroups = this.amendment.amendmentGroups.slice(0).sort(this.compare);
        const sortedAGArray = [];
        sortedAmendmentGroups.forEach((element) => {
          sortedAGArray.push(element['amendmentGroupId']);
        });
        // Set groupIndex by order of amendment group id asc.
        this.amendment.amendmentGroups.forEach((element, index) => {
          element['groupIndex'] = sortedAGArray.indexOf(element['amendmentGroupId']);
        });
        this.form
          .get('amendmentContribution')
          .setValue(model.amendment.amendmentContribution);
        this.amendmentService.amendmentSummary$.next(this.amendment);
      }

      setTimeout(() => {
        this.initNavigation();
        this.form.markAsPristine();
      }, 500);
    });
    this.validationService1 = this.validationService
      .getData()
      .subscribe(data => {
        // True if data is valid or not an amendment category.
        this.formClean =
          data ||
          this.submission.category.categoryId !==
            this.submissionCategory.Amendments;
      });
    this.validationService2 = this.validationService
      .getDataWithAnAmendment()
      .subscribe(data => {
        // True if data is valid and contains at least one amendment.
        this.formCleanWithAmendment = data;
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.clearTaskManagerNavigation();
    this.validationService1.unsubscribe();
    this.validationService2.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  public compare(a, b) {
    if (a.amendmentGroupId < b.amendmentGroupId) {
      return -1;
    } else if (a.amendmentGroupId > b.amendmentGroupId) {
      return 1;
    }
    return 0;
  }

  public scrollToError() {
    // Get first invalid form on page, if create is invalid it will always be scrolled to first as it is first on array.
    const formiId = this.validationService.getTopInvalidForm();
    // Get element offset to top of page.
    if (
      typeof document.getElementById(formiId) !== 'undefined' &&
      document.getElementById(formiId) != null
    ) {
      const scrollVal = this.getOffsetTop(document.getElementById(formiId));
      // Main scrollbar belongs to element with class name body.
      if (
        typeof document.getElementsByClassName('body')[0].scroll !== 'undefined'
      ) {
        document.getElementsByClassName('body')[0].scroll({
          top: scrollVal,
          behavior: 'smooth'
        });
      } else if (
        typeof document.getElementsByClassName('body')[0].scrollTop !==
        'undefined'
      ) {
        document.getElementsByClassName('body')[0].scrollTop = scrollVal;
      }
    }
  }

  public getOffsetTop(elem) {
    let offsetTop = 0;
    do {
      if (!isNaN(elem.offsetTop)) {
        offsetTop += elem.offsetTop;
      }
    } while ((elem = elem.offsetParent));
    return offsetTop;
  }

  public onSubmit(isDraft = false, isAmendmentUpdate = false): void {
    const category = this.submission.category.categoryId as SubmissionCategory;
    if (
      category === SubmissionCategory.Amendments &&
      ((!this.formCleanWithAmendment && !isDraft) ||
        (!this.formClean && isDraft))
    ) {
      // save amendement changes
      if (isAmendmentUpdate) {
        this.submit(isDraft, category);
      }
      // Show amendments validation modal.
      else if (!this.formCleanWithAmendment && !isDraft) {
        if (!this.formClean) {
          this.amendmentIncompleteModel.toggle();
          this.validationService.activateValidation();
          this.scrollToError();
        } else {
          this.amendmentRequiredModel.toggle();
        }
      } else if (!this.formClean && isDraft) {
        this.amendmentIncompleteModel.toggle();
        this.validationService.activateValidation();
        this.scrollToError();
      }
    } else {
      if (this.form.valid || isDraft) {
        this.submit(isDraft, category);
      }
    }
  }

  private submit(isDraft = false, category) {
    const formData = new FormData();
    const data = this.form.value;

    data.isDraft = isDraft;

    if (data.coreContribution) {
      if (data.coreContribution.leaveToIntroduce) {
        data.coreContribution.leaveToIntroduce = moment
          .utc(data.coreContribution.leaveToIntroduce, ['DD/MM/YYYY'])
          .toISOString();
      }

      if (data.coreContribution.supportingInformation) {
        this.attachDocuments(
          data.coreContribution.supportingInformation,
          formData
        );
      }
    }

    data.submissionId = this.submission.submissionId;
    data.createdBy = this.config.getValue('UserId');
    data.createdByUserName = this.authService.getUsername();
    data.delegateRoleMemberId = this.config.getValue('DelegateRoleMemberId');
    data.validFrom = this.submission.validFrom;

    if (data.financialImplications) {
      data.financialImplications.forEach(fi => {
        this.attachDocuments(fi.supportingInformation, formData);
      });
    }

    if (data.supportingInformation) {
      this.attachDocuments(data.supportingInformation, formData);
    }

    if (data.amendmentContribution) {
      data.amendmentContribution.amendmentGroups.forEach(amd => {
        if (amd.commentAmendments) {
          amd.commentAmendments.forEach(commentAmd => {
            this.attachDocuments(commentAmd.supportingInformation, formData);
          });
        }
        this.attachDocuments(amd.supportingInformation, formData);
      });
    }
    formData.append('SubmissionData', JSON.stringify(data));

    this.submissionService.put(formData, category).subscribe(
      response => {
        if (response) {
          const finalDecision =
            data.coreContribution &&
            data.coreContribution.actionType === ActionType.Decision;
          const msg = this.translateService.instant(
            response.responseMessage,
            [response.submissionId]
          );

          this.toastService.addNotification(
            {
              title: isDraft
                ? 'SUBMISSION.SAVED'
                : finalDecision
                  ? 'SUBMISSION.FINALDECISION'
                  : 'SUBMISSION.SENTFORREVIEW',
              description: msg
            }
          );
          if (isDraft) {
            this.submissionService.refresh(this.submission.submissionId);
          } else {
            this.form.markAsPristine();
            this.navigationService.clearSelect(
              this.navigationService.currentParent
            );
            this.router.navigate(['/task-manager']);
          }
        }
      },
      err => {
        if (err.error.error === 'Concurrency Violation') {
          this.toastService.addNotification(
            { title: 'COMMON.SAVEERROR', description: 'COMMON.SAVEERROR-STALESUBMISSION' }
          );
        } else {
          this.toastService.addNotification(
            { title: 'COMMON.SAVEERROR', description: 'COMMON.SAVEERRORDESCRIPTION' }
          );
        }

        // this.router.navigate(['/task-manager']);
      }
    );
  }

  public canDeactivate(
    _component: SubmissionComponent,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    if (
      !this.form.pristine &&
      (!this.readOnly ||
        this.submission.status.statusId ===
          SubmissionStatus.FinalDecisionMade) &&
      nextState.url !== '/create-submission'
    ) {
      this.unsavedChangesModal.toggle();
      return this.userDiscardResponse$
      .pipe(
        map(value => {
          if (value) {
            this.clearTaskManagerNavigation();
            return true;
          } else {
            this.initNavigation();
            return false;
          }
        })
      );
    }
    return true;
  }

  public onClose() {
    if (this.closeTab) {
      this.cameFromCloseButton = true;
      if (
        !this.form.pristine &&
        (!this.readOnly ||
          this.submission.status.statusId ===
            SubmissionStatus.FinalDecisionMade)
      ) {
        this.unsavedChangesModal.toggle();
        return this.userDiscardResponse$
        .pipe(
          map(value => {
            if (value) {
              return true;
            } else {
              this.initNavigation();
              return false;
            }
          })
        );
      } else {
        this.tryCloseTab();
      }
    } else {
      this.router.navigate(['task-manager']);
    }
  }

  public cancelChanges(toggle = true) {
    this.cameFromCloseButton = false;
    this.userDiscardResponse$.next(false);
    if (toggle) {
      this.unsavedChangesModal.toggle();
    }
  }

  public discardChanges() {
    this.userDiscardResponse$.next(true);
    this.unsavedChangesModal.toggle();
    if (this.closeTab && this.cameFromCloseButton) {
      this.tryCloseTab();
    }
  }

  private tryCloseTab() {
    const objWin = window.self;
    objWin.open('', '_self', '');
    objWin.close();
    if (!objWin.closed) {
      this.router.navigate(['task-manager']);
    }
  }

  public toggle(): void {
    this.createComponent.toggle();
  }

  public acknowledgeIncompleNotice() {
    this.amendmentIncompleteModel.toggle();
  }

  public acknowledgeReqNotice() {
    this.amendmentRequiredModel.toggle();
  }

  private clearTaskManagerNavigation() {
    this.navigationService.select(this.navigationService.currentNode);
    const taskManagerNode = this.navigationService.model.tree.find(
      x => x.title === 'Task manager' || 'TASKMANAGER.TITLE'
    );
    taskManagerNode.expanded = false;
  }

  private initNavigation() {
    const title = this.translateService.instant(
      'SUBMISSION.TITLE',
      { categoryDesc: this.submission.category.descr, submissionId: this.submission.submissionId }
    );

    this.titleService.setTitle(title);
    const taskManagerNode = this.navigationService.model.tree.find(
      x => x.title === 'Task manager' || 'TASKMANAGER.TITLE'
    );
    this.navigationService.select(taskManagerNode);
    setTimeout(() => {
      const node = {
        title,
        path: `/submission/${this.submission.submissionId}`
      };
      this.navigationService.addNode(node);
    }, 420);
  }

  private attachDocuments(
    supportingInformation: Array<any>,
    formData: FormData
  ) {
    if (supportingInformation === undefined || supportingInformation === null) {
      return;
    }

    supportingInformation.forEach(s => {
      if (s.attachedDocuments !== undefined && s.attachedDocuments !== null) {
        s.attachedDocuments.forEach(a => {
          formData.append(a.documentUniqueId, a.content, a.name);
        });
      }
    });
  }
}
