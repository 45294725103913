export enum  Roles {
  BillsOffice = '0a2eb18d-8d79-462f-8e4b-5ea435fdbbbf',
  BillsOfficeReviewers = '7cd512c2-85cb-44e9-9678-7f1a5541bfcf',
  POHouseServices = 'fe4742e9-2518-4cfb-a051-1f2b83dd5cd0',
  ClerkAssistantOfTheDail = 'af729a63-405b-4ae5-a12a-1bda20d93044',
  ClerkOfTheDail= '52f562c5-9c96-4654-b20a-c4ecafee0845',
  ClerkAssistantOfTheSeanad = '908db054-2c4f-412e-9527-a64145d70f9d',
  ClerkOfTheSeanad = '96048d91-6bd8-4c1e-8854-eb8d86caae16'
}

export enum UserRoleRank {
  BillsOffice = 10,
  BillReviewers = 20,
  POHouseServices = 30,
  AssistantClerkOfDail = 40,
  AssistantClerkOfSeanad = 40,
  ClerkOfSeanad = 50,
  ClerkOfDail = 50
}
