import { Component, OnInit, AfterViewInit, forwardRef, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, NG_VALUE_ACCESSOR, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { AmendmentView, AmendmentDecision, ReferenceNumberType, AmendmentSectionTitles, AmendmentGroupWithIndex } from './amendment.model';
import { AmendmentService } from './amendment.service';
import { OirAuthService } from 'proceduralsystem-clientcomponents';
import { Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SubmissionService } from '../submission.service';
import { SubmissionComponent } from '../submission.component';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'oir-amendment',
  templateUrl: './amendment.component.html',
  styleUrls: ['./amendment.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmendmentComponent),
      multi: true
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => AmendmentComponent),
      multi: true
    }
  ]
})
export class AmendmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() amendment: any;
  @Input() readOnly: boolean;

  private init = false;
  private valueSubscription: Subscription;
  private statusSubscription: Subscription;
  private amendmentServiceSubscription: Subscription;
  private section: string;

  public form: FormGroup;
  public isReviewer = false;
  public isCreator = false;
  public isFinalDecision = false;
  public preventEdit = false;


  constructor(
    private fb: FormBuilder,
    private config: AppConfigService,
    private amendmentService: AmendmentService,
    private submissionService: SubmissionService,
    readonly submissionComponent: SubmissionComponent,
    private route: ActivatedRoute,
    private authService: OirAuthService) { }

  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  ngOnInit() {
    this.form = this.fb.group({
      amendmentGroups: this.fb.array([]),
      amendmentView: AmendmentView,
      updatedBy: this.authService.getUsername(),
      adRoleMemberId: this.config.getValue('UserRoleMemberId')
    });

    this.valueSubscription = this.form.valueChanges.subscribe(() => {
      this.propagateChange(this.form.value)
    });

    this.statusSubscription = this.form.statusChanges.subscribe(() => {
      this.validate();
    });

    this.route.queryParams.subscribe(params => {
      if (typeof(params['section']) !== 'undefined') {
        this.section = params['section'];
      }
    });
  }

  ngAfterViewInit() {
    if (this.section) {
      const countEditors = Object.keys(CKEDITOR.instances).length;
      const readyEditors = [];
      if (countEditors === 0) {
        setTimeout(() => {
          this.submissionService.scrollToSection(this.section);
        });
      } else {
        for (let i in CKEDITOR.instances) {
          CKEDITOR.instances[i].on('instanceReady', () => {
            readyEditors.push(i);
            if(readyEditors.length === countEditors) {
              this.submissionService.scrollToSection(this.section);
            }
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }

    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }

    if (this.amendmentServiceSubscription) {
      this.amendmentServiceSubscription.unsubscribe();
    }
  }

  writeValue(obj: any) {
    const arr = <FormArray>this.form.controls['amendmentGroups'];
    const sortedArray = this.sortArray(obj.amendmentGroups);

    for (let i = 0; i < sortedArray.length; i++) {
      const group = sortedArray[i];

      arr.push(this.fb.control({
        amendmentGroupId: group.amendmentGroupId,
        commentAmendments: group.commentAmendments,
        billAmendments: group.billAmendments,
        supportingInformation: group.supportingInformation
      }));
    }

    if (arr.length > sortedArray.length) {
      while (arr.length > sortedArray.length) { arr.removeAt(0) }
    }

    this.getWorkflowStatus(obj.amendmentView);

    setTimeout(() => this.form.updateValueAndValidity());
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  validate(): Promise<{ [key: string]: any; }> | Observable<{ [key: string]: any; }> {
    return of(this.form.valid ? null : { invalid: true });
  }

  get sortedArray() {
    return this.sortArray(this.amendment.amendmentGroups);
  }

  public sortArray(amendmentGroup) {
    return amendmentGroup.sort(
      (n1, n2) => {
      if (n1.decisionId > n2.decisionId) {
        return 1;
      }

      if (n1.decisionId < n2.decisionId) {
        return -1;
      }

    });
  }

  public updateCreateContribution(value: any) {
    this.createGroup(value);
    const arr = <FormArray>this.form.controls['amendmentGroups'];
    arr.push(this.fb.control({
      amendmentGroupId: 0,
      commentAmendments: [{
        commentAmendmentId: 0,
        contributionId: 0,
        amendmentDecisionId: value.amendmentDecisionId,
        supportingInformation: value.supportingInformation,
        comment: value.comment
      }],
      billAmendments: this.getBillAmendments(value.billAmendments)
    }));
    this.submissionComponent.onSubmit(true, true);
  }

  public isSameDecision(previousGroup: any, currentGroup: any) {
    let result = false;

    if (previousGroup) {
      result = previousGroup.decisionId === currentGroup.decisionId;
    }

    return result;
  }

  public collapseGroups(decisionId: number) {
    const groups = this.amendment.amendmentGroups.filter(x => x.decisionId === decisionId);

    for (let i = 0; i < groups.length; i++) {
      groups[i].sectionCollapsed = !groups[i].sectionCollapsed;
    }
  }

  private getWorkflowStatus(status: AmendmentView) {
    switch (status) {
      case AmendmentView.Create:
        this.isCreator = true;
        this.isReviewer = false;
        this.isFinalDecision = false;
        break;

      case AmendmentView.FirstReview:
      case AmendmentView.SecondReview:
      case AmendmentView.AdditionalReview:
        this.isReviewer = true;
        this.isCreator = false;
        this.isFinalDecision = false;
        break;

      case AmendmentView.FinalDecision:
        this.isFinalDecision = true;
        this.isReviewer = false;
        this.isCreator = false;
        break;
    }

    this.form.get('amendmentView').setValue(status);
  }

  private getBillAmendments(value: any) {
    const billAmendments = value?.map(v => {
      return {
        listType: v.listType,
        numberListReference: v.numberListReference,
        whiteListNumber: v.whiteListNumber,
        whiteListReference: v.whiteListReference,
        chamberMembers: v.chamberMembers
      }
    });

    return billAmendments;
  }

  private createGroup(value: any) {
    const index = this.amendment.amendmentGroups.push({
      amendmentGroupId: 0,
      groupTitle: '',
      contributions: [],
      groupIndex: this.amendment.amendmentGroups.length,
      amendments: value.billAmendments,
      supportingInformation: value.supportingInformation
    });

    const commentAmendments = {
      commentAmendments: [{
        billAmendment: {
          amendmentId: -1,
        },
        amendmentGroupId: 0,
        comment: value.comment,
        commentAmendmentId: 0,
        contributionId: 0,
        supportingInformation: value.supportingInformation,
        amendmentDecisionId: value.amendmentDecisionId
      }]
    };

    this.updateContribution(commentAmendments, index - 1);

    const group = this.amendment.amendmentGroups[this.amendment.amendmentGroups.length - 1];
    this.updateAmendmentGroupTitle(group);
  }



  // Only called for Creator for editing groups.
  public editContribution(value: AmendmentGroupWithIndex, orderIndex: number) {
    this.form.markAsDirty();
    const tempVal = value.amendmentGroup;
    let groupIndex = value.groupIndex;

    if ( tempVal.amendmentGroupId !== 0) {
      groupIndex = this.form.value.amendmentGroups.findIndex(x => x.amendmentGroupId === tempVal.amendmentGroupId)
    }
    // Amendment return from server contains billAmendment.
    // API is exprcting billAmendments when creator.
    // Rename property.
    if (tempVal.hasOwnProperty('commentAmendments')) {
      for (let b = 0; b < tempVal.commentAmendments.length ; b++) {
        Object.defineProperty(tempVal.commentAmendments[b], 'billAmendments',
         Object.getOwnPropertyDescriptor(tempVal.commentAmendments[b], 'billAmendment'));
         delete tempVal.commentAmendments[b].billAmendment;
      }
    }

    const submitters = [];
    // Remove amendment submitters, it is not needed for creator.
    for (let i = 0; i < tempVal.billAmendments.length; i++) {
      if (tempVal.billAmendments[i].hasOwnProperty('amendmentSubmitters') && !tempVal.billAmendments[i].hasOwnProperty('chamberMembers')) {
        submitters.push({
          billIndex: i,
          submitters: tempVal.billAmendments[i].amendmentSubmitters
        });
        delete tempVal.billAmendments[i].amendmentSubmitters;
      }
    }
    // Set value of form that is being return to server
    Object.assign(this.form.controls['amendmentGroups'].value[groupIndex], tempVal);
    this.updateContribution(tempVal, orderIndex, true , submitters);
    this.propagateChange(this.form.value);
    this.submissionComponent.onSubmit(true, true);
    this.preventEdit = false;
}

  public updateContribution(value: any, groupIndex: number, edit = false , submitters = []) {
    if (typeof value.amendmentGroupId !== 'undefined' && value.amendmentGroupId > 0) {
      groupIndex = this.amendment.amendmentGroups.findIndex(x => x.amendmentGroupId === value.amendmentGroupId)
    }

    const amendmentGroup = this.amendment.amendmentGroups[groupIndex];

    if (amendmentGroup) {
      const contributionId = value.commentAmendments[0].contributionId;
      const contribution = amendmentGroup.contributions.find(x => x.contributionId === contributionId) ||
        {
          adGroupMemberIdSentTo: null,
          adRoleMemberId: this.config.getValue('UserRoleMemberId'),
          contributionId: 0,
          createdByUser: `${this.config.getValue('FullName')} (${this.config.getValue('UserGrade')}` + ')',
          sendToDate: null
        };
       if ( edit ) {
        contribution.commentAmendments = value.commentAmendments;
        amendmentGroup.amendments = value.billAmendments;
        amendmentGroup.supportingInformation = value.supportingInformation;
        this.updateAmendmentGroupTitle(amendmentGroup, edit, submitters);
      } else {
         contribution.commentAmendments = this.updateCommentAmendments(amendmentGroup.amendmentGroupId, value.commentAmendments);
      }
      const contributionIndex = amendmentGroup.contributions.indexOf(contribution) >= 0 ?
        amendmentGroup.contributions.indexOf(contribution) :
        amendmentGroup.contributions.length;

      amendmentGroup.contributions.splice(contributionIndex, 1, contribution);
      this.updateGroupDecision(amendmentGroup);

      const data = this.getAmendmentNumbers(this.amendment.amendmentGroups, true);
      this.amendmentService.refreshAmendmentSummary(data);
    }
  }

  private updateCommentAmendments(amendmentGroupId: number, commentAmendments: Array<any>) {
    let listReference = '';
    const result = [];
    const amendments = this.amendment.amendmentGroups.find(x => x.amendmentGroupId === amendmentGroupId).amendments || [];

    for (let i = 0; i < commentAmendments.length; i++) {
      if ( typeof amendments[i] !== 'undefined' ) {
        if ( typeof amendments[i].numberListReference !== 'undefined' && amendments[i].numberListReference != null) {
          listReference = amendments[i].numberListReference;
        } else if ( typeof amendments[i].whiteListReference  !== 'undefined' && amendments[i].whiteListReference != null) {
          listReference = amendments[i].whiteListReference;
        }
      }
      const billAmendID = typeof commentAmendments[i].commentAmendmentId !== 'undefined' &&
      commentAmendments[i].commentAmendmentId !== null ? commentAmendments[i].commentAmendmentId : 0;
      const commentAmendment = {
        amendmentGroupId: amendmentGroupId,
        amendmentId: typeof commentAmendments[i].billAmendment !== 'undefined' ?
        commentAmendments[i].billAmendment.amendmentId : commentAmendments[i].billAmendments.amendmentId,
        amendmentReference: listReference,
        comment: commentAmendments[i].comment,
        commentAmendmentId: billAmendID,
        supportingInformation: commentAmendments[i].supportingInformation,
        contributionId: commentAmendments[i].contributionId,
        decision: {
          amendmentDecisionId: commentAmendments[i].amendmentDecisionId,
          descr: this.getDecisionTitle(commentAmendments[i].amendmentDecisionId)
        }
      };
      let amendment;
      amendment = amendments.find(x => x.amendmentId === billAmendID);

      if (amendment) {
        amendment.amendmentReference = amendment.numberListReference || amendment.whiteListReference;
        commentAmendment.amendmentReference = amendment.amendmentReference;
      }
      result.push(commentAmendment);
    }

    return result;
  }

  public deleteEditContribution(_contributionId: number, groupIndex?: number) {
    // Get removed item.
    const removedItem = this.amendment.amendmentGroups.splice(groupIndex, 1);
    this.amendment.amendmentContribution.amendmentGroups.splice(groupIndex, 1);

    const data = this.getAmendmentNumbers(this.amendment.amendmentGroups);
    this.amendmentService.refreshAmendmentSummary(data);

    const thisFormArray = this.form.get('amendmentGroups') as FormArray;
    // Find removed Item and remove it from main form.
    const indexOfItemInForm = thisFormArray.value.findIndex(x => x.amendmentGroupId === removedItem[0].amendmentGroupId)
    thisFormArray.removeAt(indexOfItemInForm);
  }

  public deleteContribution(contributionId: number, groupIndex: number) {
    const amendmentGroup = this.amendment.amendmentGroups[groupIndex];

    if (amendmentGroup) {
      const index = amendmentGroup.contributions?.map(x => x.contributionId)
        .indexOf(contributionId);

      if (index >= 0) {
        amendmentGroup.contributions.splice(index, 1);

        this.updateGroupDecision(amendmentGroup);

        const data = this.getAmendmentNumbers(this.amendment.amendmentGroups);
        this.amendmentService.refreshAmendmentSummary(data);
      }
    }
  }

  private getDecisionTitle(amendmentDecisionId, asConflicts: boolean = false) {
    let result = '';
    const decision = AmendmentSectionTitles.find(x => x.value === amendmentDecisionId);

    if (decision) {
      result = decision.value === AmendmentDecision.Conflict && asConflicts ? AmendmentSectionTitles[0].title : decision.title;
    }

    return result;
  }

  private updateGroupDecision(amendmentGroup: any) {
    const contributionDecision = this.getContributionDecision(amendmentGroup);

    if (amendmentGroup.decisionId !== contributionDecision) {
      amendmentGroup.decisionId = contributionDecision;
      amendmentGroup.decisionTitle = this.getDecisionTitle(contributionDecision, true);

      this.expandGroupSection(contributionDecision);
    }
  }

  private getContributionDecision(amendmentGroup: any) {
    let result = AmendmentDecision.Conflict;

    const contributionDecisions = [];

    for (let i = 0; i < amendmentGroup.contributions.length; i++) {
      contributionDecisions.push(this.getCommentAmendmentDecision(amendmentGroup.contributions[i].commentAmendments));
    }

    if (contributionDecisions.every(x => x === AmendmentDecision.InOrder)) {
      result = AmendmentDecision.InOrder;
    } else if (contributionDecisions.every(x => x === AmendmentDecision.OutOfOrder)) {
      result = AmendmentDecision.OutOfOrder;
    }

    return result;
  }

  private getCommentAmendmentDecision(commentAmendments: Array<any>) {
    let result = AmendmentDecision.Conflict;

    if (commentAmendments.every(x => x.decision.amendmentDecisionId === AmendmentDecision.InOrder)) {
      result = AmendmentDecision.InOrder;
    } else if (commentAmendments.every(x => x.decision.amendmentDecisionId === AmendmentDecision.OutOfOrder)) {
      result = AmendmentDecision.OutOfOrder;
    }

    return result;
  }

  private expandGroupSection(decisionId: number) {
    const groups = this.amendment.amendmentGroups.filter(x => x.decisionId === decisionId);

    for (let i = 0; i < groups.length; i++) {
      groups[i].sectionCollapsed = false;
    }
  }

  private updateAmendmentGroupTitle(group: any, edit = false, submitters = [] ) {
    let data = this.getAmendmentNumbers([group]);
    const dataGroup = [];
    if (submitters.length > 0 && edit) {
      for (let y = 0; y < group.amendments.length; y++) {
          dataGroup.push({
          decisionId: group.decisionId,
          decisionTitle: group.decisionTitle,
          value: group.amendments[y].listType === ReferenceNumberType.NumberedList
                ? group.amendments[y].numberListReference : group.amendments[y].whiteListReference,
          referenceNumberType: group.amendments[y].listType,
          chamberMembers: submitters,
          whiteListNumber: group.amendments[y].listType === ReferenceNumberType.WhiteList
                ? group.amendments[y].whiteListNumber : null
        });
      }
      for  (let y = 0; y < dataGroup.length; y++) {
        const cMemsArr = [];
        const s = submitters.find(x => x.billIndex === y);
        if ( typeof s !== 'undefined') {
          for (let z = 0; z < s.submitters.length; z++) {
            cMemsArr.push({
              title: s.submitters[z].chamberMember.firstName + ' ' +
              s.submitters[z].chamberMember.lastName,
              value: s.submitters[z].chamberMember.chamberMemberId
            });
          }
          group.amendments[y].chamberMembers = cMemsArr;
          if (group.amendments[y].hasOwnProperty('amendmentSubmitters')) {
            delete group.amendments[y].amendmentSubmitters;
          }
          dataGroup[y].chamberMembers = cMemsArr;
        } else {
          dataGroup[y].chamberMembers = group.amendments[y].chamberMembers;
        }
      }
      data = dataGroup;
    } else {
      for  (let y = 0; y < group.amendments.length; y++) {
        delete group.amendments[y].amendmentSubmitters;
      }
    }
    this.amendmentServiceSubscription = this.amendmentService.getAmendmentGroupTitle(data).subscribe(response => {
      if (response) {
        group.groupTitle = response.groupTitle;
      }
    });
  }

  // Convert the Amendment objects into AmendmentNumber objects for easier parsing server side.
  private getAmendmentNumbers(amendmentGroups: any, create = false) {
    const data = [];

    for (let i = 0; i < amendmentGroups.length; i++) {
      const group = amendmentGroups[i];

      for (let j = 0; j < group.amendments.length; j++) {
        const amendment = group.amendments[j];

        const decision = this.getContributionDecision(group);
        const isNumberList = amendment.numberListReference != null && amendment.numberListReference !== '';

        data.push({
          decisionId: decision,
          decisionTitle: this.getDecisionTitle(decision),
          value: isNumberList ? amendment.numberListReference : amendment.whiteListReference,
          referenceNumberType: isNumberList ? ReferenceNumberType.NumberedList : ReferenceNumberType.WhiteList,
          chamberMembers: amendment.chamberMembers,
          whiteListNumber: !isNumberList ? amendment.whiteListNumber : null
        });
      }
    }

    return data;
  }

  onEditClick(_event, _i) {
    this.preventEdit = true;
  }

  onGroupCancel(_event, _i) {
    this.preventEdit = false;
  }
}
