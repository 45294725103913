import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CreateSubmissionService {
  public appSettings;
  private readonly controllerName = 'Submission';

  constructor(
    private http: HttpClient,
    private config: AppConfigService
  ) { }

  public get(userAccount: string): Observable<any> {
    return this.http.get(`${this.config.getValue('ApiEndpoint').url}/api/${this.controllerName}?userAccount=${userAccount}`);
  }

  public post(model: any): Observable<any> {
    return this.http
               .post(`${this.config.getValue('ApiEndpoint').url}/api/${this.controllerName}`, model)
               .pipe(catchError((error: any) => observableThrowError(error)));
  }
}
