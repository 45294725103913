import { Component, OnInit, forwardRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, NG_VALUE_ACCESSOR, Validators, AbstractControl, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { ListType } from '../amendment.model';
import { Subscription ,  BehaviorSubject ,  Observable, of } from 'rxjs';
import { BillAmendment, Member } from '../amendment.model';

@Component({
  selector: 'oir-amendment-reference',
  templateUrl: './amendment-reference.component.html',
  styleUrls: ['./amendment-reference.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmendmentReferenceComponent),
      multi: true
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => AmendmentReferenceComponent),
      multi: true
    }
  ]
})

export class AmendmentReferenceComponent implements OnInit, OnDestroy {
  @Input() siblingsCount: number;
  @Input() billAmendment: BillAmendment;
  @Input() showValidations$ = new BehaviorSubject(false);
  @Input() index: number;

  @Output() onRemove = new EventEmitter();

  private formSubscription: Subscription;
  private validationSubscription: Subscription;

  public form: FormGroup;
  public apiUrlMember: string;

  constructor(
    private fb: FormBuilder
  ) { }

  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  ngOnInit() {
    this.form = this.fb.group({
      listType: [{ value: 1, disabled: false }, Validators.required],
      numberListReference: [{ value: '', disabled: false }],
      whiteListNumber: [{ value: '', disabled: true }],
      whiteListReference: [{ value: '', disabled: true }],
      chamberMembers: [{ value: Array<Member>(), disabled: false }, Validators.required],
    });
    if (this.billAmendment != null && typeof this.billAmendment[this.index] !== 'undefined') {

        this.form.patchValue(
          {
            listType: this.billAmendment[this.index].listType,
            numberListReference: this.billAmendment[this.index].numberListReference
        });

       this.form.patchValue(
          {
            listType: this.billAmendment[this.index].listType,
            whiteListNumber: this.billAmendment[this.index].whiteListNumber,
            whiteListReference: this.billAmendment[this.index].whiteListReference
        });

      const chamberobject = Array<Member>();
      if (this.billAmendment[this.index].hasOwnProperty('chamberMembers')) {
        this.billAmendment[this.index].chamberMembers.forEach(element => {
          chamberobject.push(
            {
              value: Number(element.value),
              title: String(element.title),
              typeId: Number(element.typeId),
              amendmentSubmitterId: Number(element.amendmentSubmitterId)});
        })
      }
      this.form.patchValue({chamberMembers: chamberobject});
    }

    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.propagateChange(this.form.value);
    });

    this.validationSubscription = this.showValidations$.subscribe((value) => {
      this.markAllAsTouched(value);
    });

    this.apiUrlMember = `/api/Member`;
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.validationSubscription) {
      this.validationSubscription.unsubscribe();
    }
  }

  writeValue(_obj: any) {
    this.updateListType();
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  validate(c: AbstractControl): Promise<{ [key: string]: any; }> | Observable<{ [key: string]: any; }> {
    return of(this.form.valid ? null : { invalid: true });
  }

  private markAllAsTouched(value: boolean) {
    if (value) {
      this.form.get('numberListReference').markAsTouched();
      this.form.get('whiteListNumber').markAsTouched();
      this.form.get('whiteListReference').markAsTouched();
      this.form.get('chamberMembers').markAsTouched();
    } else {
      this.form.get('numberListReference').markAsUntouched();
      this.form.get('whiteListNumber').markAsUntouched();
      this.form.get('whiteListReference').markAsUntouched();
      this.form.get('chamberMembers').markAsUntouched();
    }
  }

  public updateListType() {
    const numberListReference = this.form.get('numberListReference');
    const whiteListNumber = this.form.get('whiteListNumber');
    const whiteListReference = this.form.get('whiteListReference');

    if (this.form.value.listType === ListType.Numbered) {
      numberListReference.enable();
      numberListReference.setValidators([Validators.required, Validators.pattern('([A-Za-z]?)+[0-9]+([A-Za-z]?)+')]);

      whiteListNumber.disable();
      whiteListNumber.clearValidators();

      whiteListReference.disable();
      whiteListReference.clearValidators();
    } else {
      numberListReference.disable();
      numberListReference.clearValidators();

      whiteListNumber.enable();
      whiteListNumber.setValidators([Validators.required, Validators.pattern('[0-9]+')]);

      whiteListReference.enable();
      whiteListReference.setValidators([Validators.required, Validators.pattern('[0-9]+')]);
    }

    numberListReference.updateValueAndValidity();
    whiteListNumber.updateValueAndValidity();
    whiteListReference.updateValueAndValidity();
  }

  public remove() {
    this.onRemove.emit();
  }
}
