import { Component, OnInit, ViewChild } from "@angular/core";
import {
  ModalComponent,
  ToastNotificationsService,
} from "proceduralsystem-clientcomponents";
import { CreateSubmissionContainer } from "../submission/create/create-submission-container-guard";
import { CreateSubmissionComponent } from "../submission/create/create-submission.component";
import {
  TaskManagerModel,
  TabType,
  Status,
} from "./task-manager.component.model";
import { TaskManagerService } from "./task-manager.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ErrorModel } from "../models/app.models";
import { PaginationInstance } from "ngx-pagination";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "oir-task-manager",
  templateUrl: "./task-manager.component.html",
  styleUrls: ["./task-manager.component.less"],
})
export class TaskManagerComponent implements OnInit, CreateSubmissionContainer {
  @ViewChild(CreateSubmissionComponent)
  createComponent: CreateSubmissionComponent;
  @ViewChild("claimModal") claimModal: ModalComponent;

  Status = Status;
  TabType = TabType;
  viewSubmissionBaseUrl: string;
  viewBillBaseUrl: string;
  claimForm: FormGroup;
  tabIndex = 0;
  taskManagerModel: TaskManagerModel;
  page: { [id: string]: number } = {};

  constructor(
    public taskManagerService: TaskManagerService,
    private toastService: ToastNotificationsService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {
    this.claimForm = this.fb.group({
      reason: [""],
      value: 0,
    });
  }

  ngOnInit() {
    this.taskManagerModel = this.route.snapshot.data["tasks"];
    this.viewSubmissionBaseUrl = window.location.origin + "/submission/";
    this.viewBillBaseUrl = this.taskManagerService.getBillsBaseUrl();
  }

  public clearForm() {
    this.claimForm.reset({ reason: "", value: 0 });
    this.claimForm.markAsPristine();
  }

  public getTabHeader(tabTypeName: string): string {
    switch (tabTypeName) {
      case TabType[TabType.ActionRequired]:
        return `${this.translateService.instant("TASKMANAGER.TAB.ACTION")} (${
          this.taskManagerModel.actionRequiredCount
        })`;
      case TabType[TabType.AllActive]:
        return `${this.translateService.instant("TASKMANAGER.TAB.ALL")} (${
          this.taskManagerModel.allActiveCount
        })`;
      case TabType[TabType.Unassigned]:
        return `${this.translateService.instant(
          "TASKMANAGER.TAB.UNASSIGNED"
        )} (${this.taskManagerModel.unassignedCount})`;
    }
  }

  public getNoResultMessage(tabTypeIndex: number): string {
    switch (tabTypeIndex) {
      case TabType.ActionRequired:
        return this.translateService.instant(
          "TASKMANAGER.NORESULTS.TSUPPORT.INFO.AB.ACTION"
        );
      case TabType.AllActive:
        return this.translateService.instant("TASKMANAGER.NORESULTS.TAB.ALL");
      case TabType.Unassigned:
        return this.translateService.instant(
          "TASKMANAGER.NORESULTS.TAB.UNASSIGNED"
        );
    }
  }

  public getPaginationOptions(tabIndex): PaginationInstance {
    const paginationId = "task" + tabIndex;
    return {
      id: paginationId,
      itemsPerPage: 20,
      currentPage: this.page[paginationId],
      totalItems: this.getNumberOfResults(tabIndex),
    };
  }

  private getNumberOfResults(tabIndex): number {
    switch (tabIndex) {
      case TabType.ActionRequired:
        return this.taskManagerModel.actionRequiredCount;
      case TabType.AllActive:
        return this.taskManagerModel.allActiveCount;
      case TabType.Unassigned:
        return this.taskManagerModel.unassignedCount;
    }
  }

  public onTabChange(event) {
    this.taskManagerService.getTasksForTab(event.index).subscribe((m) => {
      this.tabIndex = event.index;
      this.taskManagerModel = m;
    });
  }

  public onPageChange(pageNumber) {
    this.taskManagerService
      .changePageForTab(pageNumber, this.taskManagerModel.tabType)
      .subscribe((m) => {
        this.page["task" + this.taskManagerModel.tabType] = pageNumber;
        this.taskManagerModel = m;
      });
  }

  public onPriorityChange(submissionId: number) {
    const priority = this.taskManagerModel.tasksResult.find(
      (x) => x.submissionId === submissionId
    );
    this.taskManagerService
      .togglePriority(priority.submissionId, !priority.isPriority)
      .subscribe(() =>
        this.onPageChange(this.taskManagerModel.currentPage)
      );
  }

  public onTaskClaimClick(taskId: number) {
    this.claimForm.get("value").setValue(taskId);
    this.claimModal.toggle();
  }

  public claimTask() {
    const id = this.claimForm.get("value").value;
    const reason = this.claimForm.get("reason").value;
    const validFrom = this.taskManagerModel.tasksResult.find(
      (m) => m.submissionId === id
    ).validFrom;

    this.clearForm();
    this.claimModal.close();

    this.taskManagerService.claimTask(id, reason, validFrom).subscribe(
      () => {
        this.toastService.addNotification({
          title: "TOAST.TASK.CLAIM.TITLE",
          description: this.translateService.instant(
            "TOAST.TASK.CLAIM.SUBTITLE",
            { principleId: id }
          ),
        });
        this.onTabChange({ index: TabType.ActionRequired });
      },
      (errorData) => {
        const model = errorData.error as ErrorModel;
        const errorMsg = this.translateService.instant(
          "TASKMANAGER.GENERIC.ERROR.TITLE"
        );
        this.toastService.addNotification({
          title: errorMsg,
          description: model.error,
        });
      }
    );
  }

  public cancelModal() {
    this.clearForm();
    this.claimModal.close();
  }

  // toggle Create Submission modal
  public toggle(): void {
    this.createComponent.toggle();
  }
}
