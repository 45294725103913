import { Component, ViewChild, Input } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrincipleTopicsService } from './principle-topics.service';
import { TopicModel } from './principle-topics.model';
import { ModalComponent, SelectValue, AccordionComponent, ToastNotificationsService, PaginationOptions } from 'proceduralsystem-clientcomponents';
import { Observable } from 'rxjs';
import { CreateSubmissionContainer } from '../submission/create/create-submission-container-guard';
import { CreateSubmissionComponent } from '../submission/create/create-submission.component';

@Component({
  selector: 'oir-principle-topics',
  templateUrl: './principle-topics.component.html',
  styleUrls: ['./principle-topics.component.less']
})
export class PrincipleTopicsComponent implements CreateSubmissionContainer {
  @ViewChild(CreateSubmissionComponent) createComponent: CreateSubmissionComponent;
  @ViewChild(ModalComponent) modal: ModalComponent;
  @ViewChild(Input) editTopicDescription: Input;
  @ViewChild(AccordionComponent) topicAccordion: AccordionComponent;

  public editModel: SelectValue<any>;
  public results$: Observable<TopicModel[]>;
  public topicForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private principleTopicsService: PrincipleTopicsService,
    private toastService: ToastNotificationsService,
  ) {
    this.topicForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(256)]],
      value: 0
    });
    this.init();
  }

  clearForm() {
    this.topicForm.reset({title: '', value: 0});
    this.topicForm.markAsPristine();
  }

  private async init() {
    this.changePage(1);
  }

  changePage(pageNumber) {
    this.results$ = this.principleTopicsService.changePage(pageNumber);
  }

  get paginationOptions(): PaginationOptions {
    return {
      id: 'topics',
      itemsPerPage: this.principleTopicsService.pageSize,
      currentPage: this.principleTopicsService.currentPage,
      totalItems: this.principleTopicsService.numberOfResults
    }
  }

  public setTopic(id: number, desc: string) {
    this.topicForm.get('value').setValue(id);
    this.topicForm.get('title').setValue(desc);
    this.topicForm.get('title').markAsPristine({onlySelf: true});
  }

  public openModal(id?: number , text?: string) {
     this.modal.toggle();
  }

  public cancelModal() {
    this.clearForm();
    this.modal.close();
  }

  public saveTopic() {
    const id = this.topicForm.get('value').value;
    const title = this.topicForm.get('title').value;

    this.clearForm();
    this.modal.close();

    this.principleTopicsService.addOrUpdateTopic(id, title)
    .subscribe(() => {
        if (id === 0) {
          this.toastService.addNotification({ title: 'TOAST.TOPIC.CREATED.TITLE', description: 'TOAST.TOPIC.CREATED.SUBTITLE' });
        } else {
          this.toastService.addNotification({ title: 'TOAST.TOPIC.SAVED.TITLE', description: 'TOAST.TOPIC.SAVED.SUBTITLE' });
        }
        this.changePage(this.principleTopicsService.currentPage);
       });
  }

  public deleteTopic() {
    const id = this.topicForm.get('value').value;
    const title = this.topicForm.get('title').value;

    this.clearForm();
    this.modal.close();

    this.principleTopicsService.deleteTopic(id, title)
      .subscribe(() => {
        this.toastService.addNotification({ title: 'TOAST.TOPIC.DELETED.TITLE', description: 'TOAST.TOPIC.DELETED.SUBTITLE' });
        this.changePage(this.principleTopicsService.currentPage);
      });
  }

  toggle(): void {
    this.createComponent.toggle();
  }
}
