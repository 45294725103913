import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { SelectValue } from 'proceduralsystem-clientcomponents';
import { LoadSearchModel, SortByEnum, ResultsModel, FilterSearchModel } from './principle-search.model';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../../services/app-config.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PrincipleSearchService {
  public filterModel = new FilterSearchModel();
  public billsBaseUrl;
  private baseUrl;

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private translateService: TranslateService,
  ) {
    this.baseUrl = `${this.config.getValue('ApiEndpoint').url}/api/PrincipleSearch`;
    this.billsBaseUrl = `${this.config.getValue('BillsAdminWebUrl')}/edit/`;
  }

  getReferenceData(): Observable<LoadSearchModel> {
    return this.http.get<LoadSearchModel>(this.baseUrl)
      .pipe(
        map(result => {
          result.sortByOptions = this.loadSortingOptions();
          return result;
        })
      );
  }

  search(filter: FilterSearchModel): Observable<ResultsModel> {
    filter.skip = 0;
    filter.currentPage = 1;

    this.filterModel = filter;
    return this.performSearch(filter);
  }

  changePage(pageNumber: number): Observable<ResultsModel> {
    this.filterModel.skip = pageNumber === 1 ? 0 : this.filterModel.take * (pageNumber - 1);
    this.filterModel.currentPage = pageNumber;
    return this.performSearch(this.filterModel);
  }

  sort(selectedOption: SortByEnum): Observable<ResultsModel> {
    this.filterModel.skip = 0;
    this.filterModel.currentPage = 1;
    this.filterModel.sortBy = selectedOption;
    return this.performSearch(this.filterModel);
  }

  private performSearch(filter: FilterSearchModel): Observable<ResultsModel> {
    if (!filter.forumId) {
      filter.forumId = 0;
    }
    return this.http.post<ResultsModel>(this.baseUrl, filter)
      .pipe(
        map((model) => {
          model.skip = filter.skip;
          model.take = filter.take;
          model.currentPage = filter.currentPage;
          return model;
        })
      );
  }

  private loadSortingOptions(): Array<SelectValue<any>> {
    return [
      { value: SortByEnum.PrincipleNumber, title: this.translateService.instant('SEARCHPRINCIPLE.PRINCIPLENUMBER') },
      { value: SortByEnum.MostUsed, title: this.translateService.instant('SEARCHPRINCIPLE.MOSTUSED') },
      { value: SortByEnum.DateEstablished, title: this.translateService.instant('SEARCHPRINCIPLE.DATEESTABLISHED') },
      { value: SortByEnum.LastUpdated, title: this.translateService.instant('SEARCHPRINCIPLE.LASTUPDATED') }
    ];
  }
}
