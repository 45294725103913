import { Component, OnInit, OnDestroy } from '@angular/core';
import { Submission, Contribution, UserRoleRank, ContributionStatus } from '../submission.model';
import { SubmissionService } from '../submission.service';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'oir-contribution-view-amd-fi',
  templateUrl: './contribution-view-amd-fi.component.html',
  styleUrls: ['./contribution-view-amd-fi.component.less']
})

export class ContributionViewAmdFiComponent implements OnInit, OnDestroy {

  public Rank = UserRoleRank;
  public completedContributions: Contribution[];
  public submission: Submission;
  public collapsed = false;

  private readOnly;
  private subscription;
  public scrollTop = false;

  constructor(
    private submissionService: SubmissionService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.subscription = this.submissionService.submission$.subscribe(model => {
      this.submission = model.submission;

      // Check if submission is unassigned.
      if (this.submission.assignedToUser === null) {
        this.readOnly = true;
      } else {
        // Else check if current user matches user submission assigned to.
        this.readOnly = this.submission.assignedToUser.adRoleMemberId !== +this.config.getValue('UserRoleMemberId');
      }

      this.completedContributions = model.submission.contributions.filter(x => x.contributionStatusId === ContributionStatus.Completed);

      // If readonly, push draft contribution on to the end off the contributions array to display.
      if (this.readOnly && this.submission.contributions.filter(x => x.contributionStatusId === 1).length > 0) {
        this.completedContributions.push(this.submission.contributions.filter(x => x.contributionStatusId === ContributionStatus.Draft)[0])
      }

    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
  }

  get firstContrib(): Contribution {
    return this.completedContributions && this.completedContributions.length > 0 ?
           this.completedContributions[this.completedContributions.length - 1] :
           new Contribution();
  }
}
