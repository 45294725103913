import { Component, OnInit, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { FinancialImplication, Submission } from '../submission.model';
import { SubmissionService } from '../submission.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'oir-financial-implications',
  templateUrl: './financial-implications.component.html',
  styleUrls: ['./financial-implications.component.less']
})

export class FinancialImplicationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() parentFormGroup: FormGroup;
  @Input() financialImplications: FinancialImplication[];
  @Input() readOnly: boolean;

  public submission: Submission;
  public form: FormGroup;

  private subscription;
  private section: string;

  constructor(private formBuilder: FormBuilder,
    private submissionService: SubmissionService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      financialImplicationTypes: [this.formBuilder.array([])]
    });

    this.subscription = this.submissionService.submission$.subscribe(model =>  {
      this.submission = model.submission;
      this.form.setControl('financialImplicationTypes', this.formBuilder.array(this.submission.financialImplications || []));
    });

    this.route.queryParams.subscribe(params => {
      if (typeof(params['section']) !== 'undefined') {
        this.section = 'FinanceSection' + params['section'];
      }
    });
  }

  ngAfterViewInit() {
    if (this.section) {
      const countEditors = Object.keys(CKEDITOR.instances).length;
      const readyEditors = [];
      if (countEditors === 0) {
        setTimeout(() => {
          this.submissionService.scrollToSection(this.section);
        });
      } else {
        for (let i in CKEDITOR.instances) {
          CKEDITOR.instances[i].on('instanceReady', () => {
            readyEditors.push(i);
            if(readyEditors.length === countEditors){
              this.submissionService.scrollToSection(this.section);
            }
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getType(index: number): FinancialImplication {
    return this.submission.financialImplications[index];
  }

  get financialImplicationTypes(): FormArray {
    return <FormArray>this.form.get('financialImplicationTypes');
  }
}
