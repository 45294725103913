import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AccessDeniedComponent,
  RoleGuardService,
  CanDeactivateGuard,
} from 'proceduralsystem-clientcomponents';

import { PrincipleSearchComponent } from './principles/principle-search/principle-search.component';
import { PrincipleTopicsComponent } from './principle-topics/principle-topics.component';
import { TaskManagerComponent } from './task-manager/task-manager.component';
import { SubmissionComponent } from './submission/submission.component';
import { ViewPrincipleComponent } from './principles/view-principle/view-principle.component';
import { EstablishEditComponent } from './principles/establish-edit/establish-edit.component';
import { CreateSubmissionComponent } from './submission/create/create-submission.component';
import { CreateSubmissionContainerGuard } from './submission/create/create-submission-container-guard';
import { Roles } from './models/roles.enum.model';
import { SubmissionResolve } from './submission/submission.resolve';
import { TaskManagerResolve } from './task-manager/task-manager.resolve';
import { SubmissionSearchComponent } from './submission/submission-search/submission-search.component';
import { MsalGuard } from '@azure/msal-angular';
import { PageComponent } from './page.component';

const routes: Routes = [
  { path: '', redirectTo: '/task-manager', pathMatch: 'full' },
  {
    path: '',
    component: PageComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'principle-search',
        canActivate: [RoleGuardService],
        data: {
          title: 'Search principles',
          roles: [
            Roles.BillsOffice,
            Roles.BillsOfficeReviewers,
            Roles.POHouseServices,
            Roles.ClerkAssistantOfTheDail,
            Roles.ClerkOfTheDail,
            Roles.ClerkAssistantOfTheSeanad,
            Roles.ClerkOfTheSeanad,
          ],
        },
        children: [
          {
            path: '',
            component: PrincipleSearchComponent,
            pathMatch: 'full',
            canDeactivate: [CreateSubmissionContainerGuard],
          },
        ],
      },
      {
        path: 'principle/establish-edit',
        data: { title: 'Establish Principle' },
        children: [
          {
            path: '',
            component: EstablishEditComponent,
            pathMatch: 'full',
            canDeactivate: [CanDeactivateGuard, CreateSubmissionContainerGuard],
            canActivate: [RoleGuardService],
            data: {
              title: 'Establish Principle',
              roles: [
                Roles.POHouseServices,
                Roles.ClerkAssistantOfTheDail,
                Roles.ClerkOfTheDail,
                Roles.ClerkAssistantOfTheSeanad,
                Roles.ClerkOfTheSeanad,
              ],
            },
          },
        ],
      },
      {
        path: 'principle/establish-edit/:id',
        data: { title: 'Establish Principle' },
        children: [
          {
            path: '',
            component: EstablishEditComponent,
            pathMatch: 'full',
            canDeactivate: [CanDeactivateGuard, CreateSubmissionContainerGuard],
            canActivate: [RoleGuardService],
            data: {
              title: 'Establish Principle',
              roles: [
                Roles.BillsOffice,
                Roles.BillsOfficeReviewers,
                Roles.POHouseServices,
                Roles.ClerkAssistantOfTheDail,
                Roles.ClerkOfTheDail,
                Roles.ClerkAssistantOfTheSeanad,
                Roles.ClerkOfTheSeanad,
              ],
            },
          },
        ],
      },
      {
        path: 'principle-topics',
        data: { title: 'Principle Topics' },
        children: [
          {
            path: '',
            component: PrincipleTopicsComponent,
            pathMatch: 'full',
            canDeactivate: [CreateSubmissionContainerGuard],
            canActivate: [RoleGuardService],
            data: {
              roles: [
                Roles.BillsOffice,
                Roles.BillsOfficeReviewers,
                Roles.POHouseServices,
                Roles.ClerkAssistantOfTheDail,
                Roles.ClerkOfTheDail,
                Roles.ClerkAssistantOfTheSeanad,
                Roles.ClerkOfTheSeanad,
              ],
            },
          },
        ],
      },
      {
        path: 'task-manager',
        canActivate: [RoleGuardService],
        resolve: {
          tasks: TaskManagerResolve,
        },
        data: {
          title: 'Task Manager',
          roles: [
            Roles.BillsOffice,
            Roles.BillsOfficeReviewers,
            Roles.POHouseServices,
            Roles.ClerkAssistantOfTheDail,
            Roles.ClerkOfTheDail,
            Roles.ClerkAssistantOfTheSeanad,
            Roles.ClerkOfTheSeanad,
          ],
        },
        children: [
          {
            path: '',
            component: TaskManagerComponent,
            pathMatch: 'full',
            canDeactivate: [CreateSubmissionContainerGuard],
          },
        ],
      },
      {
        path: 'submission/:id',
        component: SubmissionComponent,
        canActivate: [RoleGuardService],
        canDeactivate: [CanDeactivateGuard, CreateSubmissionContainerGuard],
        data: {
          roles: [
            Roles.BillsOffice,
            Roles.BillsOfficeReviewers,
            Roles.POHouseServices,
            Roles.ClerkAssistantOfTheDail,
            Roles.ClerkOfTheDail,
            Roles.ClerkAssistantOfTheSeanad,
            Roles.ClerkOfTheSeanad,
          ],
        },
        runGuardsAndResolvers: 'always',
        resolve: {
          submission: SubmissionResolve,
        },
      },
      {
        path: 'submission-search',
        canActivate: [RoleGuardService],
        data: {
          title: 'Search submissions',
          roles: [
            Roles.BillsOffice,
            Roles.BillsOfficeReviewers,
            Roles.POHouseServices,
            Roles.ClerkAssistantOfTheDail,
            Roles.ClerkOfTheDail,
            Roles.ClerkAssistantOfTheSeanad,
            Roles.ClerkOfTheSeanad,
          ],
        },
        children: [
          {
            path: '',
            component: SubmissionSearchComponent,
            pathMatch: 'full',
            canDeactivate: [CreateSubmissionContainerGuard],
          },
        ],
      },
      {
        path: 'principle/view/:id',
        canActivate: [RoleGuardService],
        data: {
          roles: [
            Roles.BillsOffice,
            Roles.BillsOfficeReviewers,
            Roles.POHouseServices,
            Roles.ClerkAssistantOfTheDail,
            Roles.ClerkOfTheDail,
            Roles.ClerkAssistantOfTheSeanad,
            Roles.ClerkOfTheSeanad,
          ],
        },
        children: [
          {
            path: '',
            component: ViewPrincipleComponent,
            pathMatch: 'full',
            canDeactivate: [CreateSubmissionContainerGuard],
          },
        ],
      },
      {
        path: 'principle/expired-principle/:id',
        redirectTo: 'principle/view/:id',
      },
      { path: 'create-submission', component: CreateSubmissionComponent },
      { path: 'access-denied', component: AccessDeniedComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutes {}
