import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Submission, SubmissionCategory } from './submission.model';
import { AppConfigService } from '../services/app-config.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SubmissionService implements OnDestroy {
  private readonly controllerName = '/api/Submission';
  public submission$ = new BehaviorSubject<any>(new Submission());
  public url = `${this.config.getValue('ApiEndpoint').url}` + this.controllerName;

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService,
  ) {}

  public get(id: number) {
    const adRoleMemberId = this.config.getValue('UserRoleMemberId');
    const url = `${this.url}/${id}?adRoleMemberId=${adRoleMemberId}`;

    return this.httpClient.get<any>(url);
  }

  public refresh(id: number) {
    const adRoleMemberId = this.config.getValue('UserRoleMemberId');
    const url = `${this.url}/${id}?adRoleMemberId=${adRoleMemberId}`;

    return this.httpClient.get<any>(url).subscribe(model => {
      this.submission$.next(model);
    });
  }

  public put(model: any, submissionType: SubmissionCategory): Observable<any> {
    const url = this.getControllerUrl(submissionType);

    return this.httpClient.put(url, model)
      .pipe(catchError((error: any) => observableThrowError(error)));
  }

  private getControllerUrl(submissionType) {
    switch (submissionType) {
      case SubmissionCategory.Amendments:
        return `${this.config.getValue('ApiEndpoint').url}/api/amendment`;
      case SubmissionCategory.FinancialImplications:
        return `${this.config.getValue('ApiEndpoint').url}/api/financialImplications`;
      default:
        return this.url;
    }
  }

  ngOnDestroy(): void {
    this.submission$.complete();
  }

  public scrollToSection(sectionId: string): void {
    const docElement = document.getElementById(sectionId);

    if (docElement == null) {
      console.log('Scroll Error: Cannot scroll to section; target element not found');
    } else {
      const scrollVal = this.getOffsetTop(docElement);
      if ( typeof document.getElementsByClassName('body')[0].scroll !== 'undefined' ) { // Chrome
      document.getElementsByClassName('body')[0].scroll({
        top: scrollVal,
        behavior: 'auto'
      });
      } else if (typeof document.getElementsByClassName('body')[0].scrollTop !== 'undefined') { // IE
        document.getElementsByClassName('body')[0].scrollTop = scrollVal;
      }
    }
  }

  private getOffsetTop(elem) {
    let offsetTop = -50;
    do {
      if (!isNaN(elem.offsetTop)) {
        offsetTop += elem.offsetTop;
      }
    } while ((elem = elem.offsetParent));
    return offsetTop;
  }
}
