import { Component, OnInit, OnDestroy } from '@angular/core';
import { AmendmentService } from '../amendment.service';

@Component({
  selector: 'oir-amendment-summary',
  templateUrl: './amendment-summary.component.html',
  styleUrls: ['./amendment-summary.component.less']
})
export class AmendmentSummaryComponent implements OnInit, OnDestroy {
  public amendmentSummary: any;
  public render = false;

  private subscription;

  constructor(private amendmentService: AmendmentService) { }

  ngOnInit() {
    this.subscription = this.amendmentService.amendmentSummary$.subscribe(response => {
      if (response) {
        this.amendmentSummary = response.amendmentSummary;

        this.render = this.amendmentSummary && (
          this.amendmentSummary.conflicts ||
          this.amendmentSummary.inOrder ||
          this.amendmentSummary.outOfOrder
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
