import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  SingleselectOptions,
  ModalComponent,
  NavigationService,
  ToastNotificationsService,
  OirAuthService
} from 'proceduralsystem-clientcomponents';
import { AppConfigService } from '../../services/app-config.service';
import { CreateSubmissionModel } from './create-submission.model';
import { CreateSubmissionService } from './create-submission.service';

@Component({
  selector: 'oir-create-submission',
  templateUrl: './create-submission.component.html',
  styleUrls: ['./create-submission.component.less'],
  providers: [CreateSubmissionService]
})
export class CreateSubmissionComponent implements OnInit {
  @ViewChild(ModalComponent) modal: ModalComponent;

  private dataModel: CreateSubmissionModel = new CreateSubmissionModel();

  public form: FormGroup;
  public model: CreateSubmissionModel = new CreateSubmissionModel();
  public forumSelectOptions: SingleselectOptions = { search: false, headings: false };
  public subCategorySelectOptions: SingleselectOptions = { search: false, headings: false, modelPropertyName: 'subCategoryId' };
  public assignedToSelectOptions: SingleselectOptions = { search: false, headings: false, modelPropertyName: 'roleMemberId' };
  public showSubCategory = true;
  public billIsRequired = true;
  public apiUrl: string;
  public saving = true;

  private assignedToSelf = false;

  constructor(
    private router: Router,
    private readonly config: AppConfigService,
    private navigationService: NavigationService,
    private formBuilder: FormBuilder,
    private createService: CreateSubmissionService,
    private toastService: ToastNotificationsService,
    private translateService: TranslateService,
    private authService: OirAuthService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      categoryId: [{ value: '', disabled: true }, Validators.required],
      houseIdForum: [{ value: '', disabled: true }, Validators.required],
      subCategoryId: { value: '', disabled: true },
      billId: { value: '', disabled: true },
      assignedTo: [{ value: '', disabled: true }, Validators.required],
      updatedBy: this.authService.getUsername(),
      userRoleMemberId: +this.config.getValue('UserRoleMemberId'),
      assignedToRole: '',
      delegateRoleMemberId: this.config.getValue('DelegateRoleMemberId')
    });

    this.setupDropdownCascade();
    this.apiUrl = `/api/Bill`;
  }

  private setupDropdownCascade(): void {

    this.form.get('categoryId').valueChanges.subscribe((categoryId) => {
      const bill = this.form.get('billId');
      const forum = this.form.get('houseIdForum');
      const assignedTo = this.form.get('assignedTo');
      const subCategory = this.form.get('subCategoryId');

      this.resetControls(bill, forum, assignedTo, subCategory);
      if (categoryId) {
        this.enableControls(bill, forum, assignedTo, subCategory);
        this.model.forums = this.dataModel.forums.filter(x => x.value.categoryId === categoryId);
        this.model.subCategories = this.dataModel.subCategories.filter(x => x.value.categoryId === categoryId);

        this.showSubCategory = categoryId === 1 || categoryId === 4 || categoryId === 5;
        this.billIsRequired = categoryId === 1 || categoryId === 2 || categoryId === 3 || categoryId === 4;

        this.setConditionalValidators(categoryId);
        this.updateAssignedTo();
      } else {
        this.disableControls(bill, forum, assignedTo, subCategory);
      }
    });

    this.form.get('assignedTo').valueChanges.subscribe((roleMemberId) => {
      if (roleMemberId) {
        const userId = this.dataModel.billUsers.find(x => x.value.roleMemberId === roleMemberId).value.userId;

        this.assignedToSelf = userId === this.config.getValue('UserId');

        const roleId = this.dataModel.billUsers.find(x => x.value.roleMemberId === roleMemberId).value.roleId;
        this.form.get('assignedToRole').setValue(roleId);
      } else {
        const user = this.dataModel.billUsers.find(x => x.value.roleMemberId == this.config.getValue('UserRoleMemberId'));
        if (user) { this.form.get('assignedTo').setValue(user.value.roleMemberId); }
      }
    });
  }

  private resetControls(...controls: Array<any>) {
    controls.forEach(x => x.reset());
  }

  private enableControls(...controls: Array<any>) {
    controls.forEach(x => x.enable());
  }

  private disableControls(...controls: Array<any>) {
    controls.forEach(x => x.disable());
  }

  private setConditionalValidators(categoryId): void {
    const bill = this.form.get('billId');
    const subCategory = this.form.get('subCategoryId');

    bill.setValidators(Validators.required);
    // subCategory.setValidators(Validators.required);

    if (categoryId === 5) {
      bill.clearValidators();
    } else if (categoryId === 2 || categoryId === 3) {
      subCategory.clearValidators();
    }

    subCategory.updateValueAndValidity();
    bill.updateValueAndValidity();
  }

  private updateAssignedTo() {
    const user = this.dataModel.billUsers.find(x => {
      return x.value.roleMemberId == this.config.getValue('UserRoleMemberId');
    });

    if (user) {
      this.form.get('assignedTo').setValue(user.value.roleMemberId);
      this.form.get('assignedToRole').setValue(user.value.roleId);

      this.assignedToSelf = true;
    }
  }

  private redirect(submissionId): void {
    this.modal.close();
    const url = this.assignedToSelf ? `/submission/${submissionId}` : '/task-manager';
    this.router.navigateByUrl(url);
  }

  public toggle(): void {
    this.form.reset({
      houseIdForum: { value: '', disabled: true },
      subCategoryId: { value: '', disabled: true },
      billId: { value: '', disabled: true },
      assignedTo: { value: '', disabled: true },
      updatedBy: this.authService.getUsername(),
      userRoleMemberId: this.config.getValue('UserRoleMemberId'),
      assignedToRole: '',
      delegateRoleMemberId: this.config.getValue('DelegateRoleMemberId')
    });

    this.showSubCategory = false;

    const userAccount = this.authService.getUsername();
    this.createService.get(userAccount).subscribe((response) => {
      this.dataModel = response;
      this.model.categories = response.categories;
      this.model.billUsers = response.billUsers;

      this.form.get('categoryId').enable();
      this.saving = false;
    });

    this.modal.toggle();
  }

  public updateNavigation(): void {
    this.navigationService.select(this.navigationService.previousNode);
  }

  public onSubmit($event): void {
    /// TODO: This is required because the typeahead model is an object instead
    ///       of an int. Will refactor after the typeahead is updated.
    this.saving = true;

    const data = this.form.value;
    if (data.billId) {
      data.billId = parseInt(data.billId.value);
    }

    this.createService.post(data).subscribe(
      response => {
        if (response) {

          setTimeout(() => this.redirect(response.submissionId));

          const message = this.translateService.instant(response.responseMessage, [response.submissionId]);
          this.toastService.addNotification({ title: 'SUBMISSION.CREATESUCCESS', description: message });
          this.saving = false;
        }
      },
      () => {
        this.toastService.addNotification({ title: 'COMMON.SAVEERROR', description: 'COMMON.SAVEERRORDESCRIPTION' });
        this.saving = false;
      });
  }
}
