import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'removeRequired'})
export class RemoveRequiredPipe implements PipeTransform {
  transform(value: string): string {
    let newStr: string = "";
    newStr = value.replace("required", "");
    newStr = newStr.replace("Required", "");
    return newStr;
  }
}