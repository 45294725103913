import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectValue, NavigationService, OirAuthService } from 'proceduralsystem-clientcomponents';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { PrincipleModel } from '../principle.model';
import { PrincipleService } from '../principle.service';
import { ExpirePrincipleComponent } from '../expire-principle/expire-principle.component';
import { CreateSubmissionComponent } from '../../submission/create/create-submission.component';
import { CreateSubmissionContainer } from '../../submission/create/create-submission-container-guard';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'oir-view-principle',
  templateUrl: './view-principle.component.html',
  styleUrls: ['./view-principle.component.less']
})
export class ViewPrincipleComponent implements OnInit, CreateSubmissionContainer, OnDestroy {
  @ViewChild(CreateSubmissionComponent) createComponent: CreateSubmissionComponent;

  @ViewChild('expirePrinciple') expireModal: ExpirePrincipleComponent;
  sortOptionsList: Array<SelectValue<any>> = [];
  public relatedLinks = [];
  public supercededLinks = [];
  public supercedingLinks = [];
  public id: number;
  public model: PrincipleModel = new PrincipleModel();
  public showExpireButton = false;
  private routeSubscription: Subscription;
  private principleSubscription: Subscription;
  private keepTab = true;

  constructor(
    private config: AppConfigService,
    private principleService: PrincipleService,
    private navigationService: NavigationService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private authService: OirAuthService
  ) { }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.principleService.getPrinciple(this.id);
      this.principleSubscription = this.principleService.principle.subscribe((data: any) => {
        if (this.id > 0) {
          this.model = data.principle;
          this.model.dateEstablished = moment(this.model.dateEstablished, ['DD/MM/YYYY']).format('DD/MM/YYYY');
          this.titleService.setTitle(this.translateService.instant('PRINCIPLE.TABTITLE', { principleId: this.model.principleId } ));
          if (this.model.principleId && !this.model.isExpired && data.isAuthorizedToExpire) {
            this.onUpdateExpireModal();
            this.showExpireButton = true;
          }
        }

        this.relatedLinks = this.hasRelatedPrinciples();
        this.supercededLinks = this.hasSupercededPrinciples();
        this.supercedingLinks = this.hasSupercedingPrinciples();
      });

      this.principleService.initNavigationViewChild(this.id);
      this.keepTab = Boolean(
        this.route.snapshot.queryParamMap.get('keepTab')
      );
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.principleSubscription.unsubscribe();

    const searchNode = this.navigationService.model.tree.find(x => x.title === 'Search principles');
    searchNode.expanded = false;
  }

  toggle(): void {
    this.createComponent.toggle();
  }

  public onShowExpireModal() {
    this.expireModal.toggle();
  }

  public hasRelatedPrinciples() {
    if (this.model && this.model.principleLinks1 && this.model.principleLinks1.length > 0) {
      const relatedLinkIds = this.model.principleLinks1.filter(
        p => p.principleLinkTypeId === 1
      );
      return relatedLinkIds;
    }
    return [];
  }

  public hasSupercededPrinciples() {
    if (this.model && this.model.principleLinks1 && this.model.principleLinks1.length > 0) {
      const supercededLinkIds = this.model.principleLinks1.filter(
        p => p.principleLinkTypeId === 2
      );
      return supercededLinkIds;
    }
    return [];
  }

  public hasSupercedingPrinciples() {
    if (this.model && this.model.principleLinks1 && this.model.principleLinks1.length > 0) {
      const supercedingLinkIds = this.model.principleLinks1.filter(
        p => p.principleLinkTypeId === 3
      );
      return supercedingLinkIds;
    }
    return [];
  }

  public onGoToEditScreen() {
    this.navigationService.navigateToPath(`/principle/establish-edit/${this.id}`);
  }

  public onCloseViewPrinciple() {
    if (this.keepTab) {
      this.navigationService.clearSelect(this.navigationService.currentNode);
      const searchNode = this.navigationService.model.tree.find(x => x.title === 'Search principles');
      this.navigationService.select(searchNode, true);
      this.router.navigate([`/principle-search`]);
    } else {
    this.tryCloseTab();
    }
  }

  private onUpdateExpireModal() {
    if (this.expireModal) {
      this.expireModal.principleTitle = this.model.title;
      this.expireModal.principleText = this.model.text;

      this.expireModal.expirePrincipleForm.setValue({
        expiryReason: '',
        expiryReasonValue: '',
        principleId: this.model.principleId,
        updatedBy: this.authService.getUsername(),
        AdRoleMemberIdExpiredBy: this.config.getValue('UserRoleMemberId')
      });

      this.expireModal.expiryComplete.subscribe(result => {
        this.principleService.getPrinciple(this.model.principleId);
      });
    }
  }

  private tryCloseTab() {
    const objWin = window.self;
    objWin.open('', '_self', '');
    objWin.close();

    if (!objWin.closed) {
      this.router.navigate(['task-manager']);
    }
  }
}
