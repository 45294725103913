export class CitedSubmissionsModel {
  count = 0;
  items: Array<Array<SupportInformation>> = [];
  establishedForSubmission: SupportInformation;
  skip = 0;
  take = 0;
  currentPage = 1;
}

export class SupportInformation {
  billId: number;
  supportInfoId: number;
  submissionIdLink: number;
  shortTitleEngLink: string;
  submissionCategory: string;
  citationDate: string;
  isNotable: boolean;
  submissionSubCategory: string;
}
