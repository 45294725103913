import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppConfigService } from "../../services/app-config.service";

@Injectable()
export class AmendmentService implements OnDestroy {
  public amendmentSummary$ = new BehaviorSubject<any>({});

  constructor(
    private config: AppConfigService,
    private http: HttpClient
  ) {}

  public refreshAmendmentSummary(data: any) {
    const url = `${this.config.getValue(
      "ApiEndpoint"
    ).url}/api/amendmentSummary`;

    return this.http
      .post<any>(url, { amendmentNumbers: data })
      .subscribe((model) => {
        this.amendmentSummary$.next(model);
      });
  }

  public getAmendmentGroupTitle(data: any) {
    const url = `${this.config.getValue(
      "ApiEndpoint"
    ).url}/api/amendmentGroupTitle`;
    return this.http.post<any>(url, { amendmentNumbers: data });
  }

  ngOnDestroy(): void {
    this.amendmentSummary$.complete();
  }
}
