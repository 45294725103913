import { SelectValue } from 'proceduralsystem-clientcomponents';
import { SupportingInformation } from './support-info/support-info.model';

export class Submission {
  submissionId: number;
  bill: Bill;
  createdAt: Date;
  assignedAt: Date;
  completedAt: Date;
  forum: House;
  subCategory: SubCategory;
  category: Category;
  status: Status;
  createdByUser: User;
  assignedToUser: User;
  completedByUser: User;
  contributions: Contribution[];
  financialImplications: FinancialImplication[];
  supportingInformation: SupportingInformation[];
  leaveToIntroduce: Date;
  finalDecision: string;
  validFrom: string;
}

export class Contribution {
  contributionId: number;
  reviewerId: number;
  actionType: number;
  contributionStatusId: number;
  billDecisionId: number;
  billDecisionDescr: string;
  comment: string;
  isActive: boolean;
  leaveToIntroduce: Date;
  createdByUser: User;
  sendToDate: Date;
  supportingInformation: SupportingInformation[];
}

export class FinancialImplication {
  type: FinancialImplicationType;
  decisions: SelectValue<any>[];
  comments: FinancialImplicationComment[];
}

export class FinancialImplicationType {
  id?: number;
  title?: string;
}

export class FinancialImplicationComment {
  id?: number;
  comment?: string;
  typeId?: number;
  decisionId: number;
  decision?: string;
  user?: string;
  sendToDate?: string;
  supportingInformation: SupportingInformation[];
}

export class Member {
  public chamberMemberId: number;
  public memberId: number;
  public memberName: string;
  public partyName: string;
  public organisation: string;
}

export class ShortTitleEng {
  public billId: number;
  public shortTitleEng = '';
}

export class BlgBill {
  public billId: number;
  public billNumber = '';
  public billYear: number;
}

export class House {
  public houseId: number;
  public houseTitle: string;
}

export class SubCategory {
  public subCategoryId: number;
  public categoryId: number;
  public descr: string;
}

export class Bill {
  public shortTitleEng: ShortTitleEng;
  public sponsor: Member;
  public billInfo: BlgBill;
  public billUrl: string;
}

export class Category {
  public categoryId: number;
  public descr: string;
}

export class Status {
  public statusId: number;
  public descr: string;
}

export enum SubmissionStatus {
  InDraft = 1,
  InProgress = 2,
  FinalDecisionMade = 3
}

export enum ContributionStatus {
  Draft = 1,
  Completed = 2
}

export enum SubmissionCategory {
  PMB = 1,
  PrivateBill = 2,
  FinancialImplications = 3,
  Amendments = 4,
  Other = 5
}

export enum SubmissionView {
  Default,
  Amendment,
  FinancialImplication
}

export enum ActionType {
  Review = 1,
  Decision = 2
}

export enum UserRoleRank {
  BillsOffice = 10,
  BillReviewers = 20,
  POHouseServices = 30,
  AssistantClerkOfDail = 40,
  AssistantClerkOfSeanad = 40,
  ClerkOfSeanad = 50,
  ClerkOfDail = 50
}

export class User {
  public userId: number;
  public adRoleMemberId: number;
  public userName: string;
  public firstName: string;
  public lastName: string;
  public title: string;
  public role: string;
  public roleRank: number;
  public userObjectId: string;
}

export enum FinancialImplicationEnum {
  MoneyMessageRequired = 1,
  FinancialResolutionRequired = 2,
  MoneyBill = 3,
  ECBConsultationRequired = 4
}
