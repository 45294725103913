import { Component, OnInit, OnDestroy } from '@angular/core';
import { Submission, Contribution, UserRoleRank } from '../submission.model';
import { SubmissionService } from '../submission.service';
import { AppConfigService } from '../../services/app-config.service';
import { marked } from 'marked';

@Component({
  selector: 'oir-submission-contribution-view',
  templateUrl: 'contribution-view.component.html',
  styleUrls: ['./contribution-view.component.less'],
})

export class ContributionViewComponent implements OnInit, OnDestroy {
  public submission: Submission;
  public Rank = UserRoleRank;
  public completedContributions: Contribution[];
  private subscription;
  public readOnly;

  constructor(
    private submissionService: SubmissionService,
    private config: AppConfigService
  ) { }

  ngOnInit() {
    this.subscription = this.submissionService.submission$.subscribe(model => {
      this.submission = model.submission;
      if (this.submission.assignedToUser === null) {
        this.readOnly = true;
      } else {
        this.readOnly = this.submission.assignedToUser.adRoleMemberId !== +this.config.getValue('UserRoleMemberId');
      }
        this.completedContributions = this.submission.contributions
          .filter(x => x.contributionStatusId === 2)
          .map(contrib => ({
            ...contrib,
            comment: marked(contrib.comment).toString()
          }));
        if (this.readOnly && this.submission.contributions.filter(x => x.contributionStatusId === 1).length > 0) {
          this.completedContributions.unshift(this.submission.contributions.filter(x => x.contributionStatusId === 1)[0])
        }
        if (this.submission.leaveToIntroduce && this.completedContributions.length > 0) {
          this.completedContributions[this.completedContributions.length - 1].leaveToIntroduce
          = this.submission.leaveToIntroduce;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

