import { Component, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { SelectValue } from 'proceduralsystem-clientcomponents';
import { Submission, SubmissionCategory, UserRoleRank, ActionType, Contribution } from '../submission.model';
import { SubmissionService } from '../submission.service';
import { FormBuilder, FormGroup, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_ASYNC_VALIDATORS, AsyncValidator, AbstractControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AppConfigService } from '../../services/app-config.service';
import OirCkEditor, { OirCkEditorConfig } from 'proceduralsystem-ckeditor';
import { EditorConfig } from 'src/app/models/editor-config.model';

@Component({
  selector: 'oir-submission-contribution',
  templateUrl: 'contribution.component.html',
  styleUrls: ['./contribution.component.less'],
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => SubmissionContributionComponent),
      multi: true,
  },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubmissionContributionComponent),
      multi: true
    }
  ]
})

export class SubmissionContributionComponent implements OnInit, OnDestroy, ControlValueAccessor, AsyncValidator  {

  public billDecisions: Array<SelectValue<[]>> = [];
  public category = SubmissionCategory;
  public editorValue;
  public form: FormGroup;
  public submission: Submission;
  public reviewers: Array<any> = [];
  public Rank = UserRoleRank;
  public ActionType = ActionType;
  public submitted = false;
  public firstContribution = true;
  public showBillDecision = true;
  private subscription;
  Editor = OirCkEditor;
  ckEditorConfig: OirCkEditorConfig;

  propagateChange = (_: Contribution) => {};
  propagateTouch = (_: any) => {};

  constructor(
    private submissionService: SubmissionService,
    private formBuilder: FormBuilder,
    public config: AppConfigService,
  ) { 
    this.ckEditorConfig = {
      ...EditorConfig,
      licenseKey: this.config.getValue('CKEditor5LicenseKey')
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      billDecisionId: [null],
      comment: [''],
      leaveToIntroduce: [''],
      contributionId: 0,
      reviewerId: [null, Validators.required],
      actionType: ActionType.Review,
      supportingInformation: []
    });
    this.form.valueChanges.subscribe(contribution => this.propagateChange(contribution));

    this.subscription = this.submissionService.submission$.subscribe(model => {
      this.submission = model.submission;
      this.billDecisions = model.decisions;
      this.reviewers = model.submission.reviewers;
      if (model.submission.contributions.filter(x => x.contributionStatusId === 2).length > 0) {
        this.firstContribution = false;
      } else {
        this.firstContribution = true;
      }

      setTimeout(() => {this.setValidators()});

      setTimeout(() => {
        this.propagateChange(this.form.value);
        this.form.markAsPristine();
      });
    });

    this.form.get('actionType').valueChanges.subscribe(action => {
      const reviewers = this.form.get('reviewerId');
      if (action === ActionType.Decision) {
        reviewers.reset();
        reviewers.disable();
      } else if (action === ActionType.Review) {
        reviewers.reset();
        reviewers.enable();
      }
    })
  }

  validate(c: AbstractControl): Promise<{ [key: string]: any; }> | Observable<{ [key: string]: any; }> {
    return of(this.form.valid ? null : {invalid: true});
  }

  setValidators() {
    if (this.submission.category.categoryId === SubmissionCategory.FinancialImplications ||
        this.submission.category.categoryId === SubmissionCategory.Amendments) {
      this.showBillDecision = false;
    } else {
      this.showBillDecision = true;
      this.form.controls['billDecisionId'].setValidators([Validators.required]);
      this.form.controls['billDecisionId'].updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(contribution: Contribution): void {
    if (contribution != null) {
      if (contribution.billDecisionId === 0) {
        contribution.billDecisionId = null;
      }
      this.form.patchValue(contribution);
    } else {
      const newContribution = new Contribution();
      newContribution.billDecisionId = null;
      newContribution.leaveToIntroduce = null;
      newContribution.contributionId = 0;
      newContribution.supportingInformation = [];
      newContribution.comment = '';
      newContribution.reviewerId = null;
      this.form.patchValue(newContribution);
    }
   }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public allowFinalDecision(): boolean {
    return !!this.submission
      && (+this.config.getValue('RoleRank') >= this.Rank.POHouseServices)
      && this.submission.contributions.filter(x => x.isActive === true && x.contributionStatusId !== 1).length > 0;
  }
}
