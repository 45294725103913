import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubmissionService } from '../submission.service';
import { Submission, SubmissionStatus, SubmissionCategory } from '../submission.model';

@Component({
  selector: 'oir-submission-status',
  templateUrl: 'status.component.html',
  styleUrls: ['./status.component.less']
})

export class SubmissionStatusComponent implements OnInit, OnDestroy {
  public submission: Submission;
  public status = SubmissionStatus;
  public category = SubmissionCategory;
  private subscription;

  constructor(private submissionService: SubmissionService) { }

  ngOnInit() {
    this.subscription = this.submissionService.submission$.subscribe(model =>  {
      this.submission = model.submission;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
 }
}

