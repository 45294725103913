import {
  Component,
  ElementRef,
  AfterViewInit
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationService, IAppSettings } from "proceduralsystem-clientcomponents";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";
import { AppConfig, AppConfigService } from "./services/app-config.service";

@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements AfterViewInit {
  public navigateTo;
  public appSettings: IAppSettings;

  constructor(
    public configurationService: ConfigurationService<AppConfig>,
    private elementRef: ElementRef,
    private readonly config: AppConfigService,
    private readonly translateService: TranslateService
  ) {
    this.navigateTo = this.elementRef.nativeElement.getAttribute("navigateTo");
  }

  ngAfterViewInit() {
    this.showMessage(`${this.config.getValue('ModuleName')} application started`);

    forkJoin([this.config.get('VersionNumber'), this.config.get('defaultLang')])
      .pipe(take(1))
      .subscribe(([version, defaultLang]) => {
        this.showMessage(`v. ${version}`);

        this.translateService.addLangs(['en', 'ga']);
        this.translateService.use(defaultLang);
      });
  }

  private showMessage(message: string): void {
    console.log(
      `%c${message}`,
      'color: #b39841; font-family: Arial; font-size: 1.5em; font-weight: bold;'
    );
  }
}
