import { SharedService } from './../../shared/shared.service';
import { Component, OnInit, ViewChild, ViewChildren, QueryList, OnDestroy, AfterViewInit, ElementRef,
  AfterViewChecked} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PrincipleService } from '../principle.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PrincipleModel, PrincipleExpiry, Topic, PrincipleLink, LinkTypeEnum } from '../principle.model';
import { SelectValue, ModalComponent, AccordionComponent, NavigationService, DatePickerComponent, OirAuthService } from 'proceduralsystem-clientcomponents';
import { LinkSubmissionComponent } from '../../shared/link-submission.component';
import { ExpirePrincipleComponent } from '../expire-principle/expire-principle.component';
import { CitedSubmissionsModel } from '../cited-submissions/cited-submissions.model';
import { Subject, Subscription } from 'rxjs';
import { CreateSubmissionComponent } from '../../submission/create/create-submission.component';
import { CreateSubmissionContainer } from '../../submission/create/create-submission-container-guard';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../../services/app-config.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'oir-principles-establish-edit',
  templateUrl: './establish-edit.component.html',
  styleUrls: ['./establish-edit.component.less'],
  preserveWhitespaces: true
})
export class EstablishEditComponent implements OnInit, CreateSubmissionContainer, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild(CreateSubmissionComponent) createComponent: CreateSubmissionComponent;
  @ViewChild('titleBox', {read: ElementRef}) titleBox: ElementRef;
  @ViewChild('textBox', {read: ElementRef}) textBox: ElementRef;
  @ViewChild('additionalDetailsBox', {read: ElementRef}) additionalDetailsBox: ElementRef;
  @ViewChildren(ModalComponent) modalChildren: QueryList<ModalComponent>;
  @ViewChild(AccordionComponent) accordion: AccordionComponent;
  @ViewChild('expirePrinciple') expireModal: ExpirePrincipleComponent;
  @ViewChild('unsavedChangesModal') unsavedChangesModal: ModalComponent;
  @ViewChild(LinkSubmissionComponent) linkSubmission: LinkSubmissionComponent;
  @ViewChild(DatePickerComponent) datepicker: DatePickerComponent;

  public principleForm: FormGroup;
  public topicsList: Array<SelectValue<any>> = [];
  public principlesList: Array<SelectValue<any>> = [];
  public forumList: Array<SelectValue<any>> = [];
  public principleLinks: Array<PrincipleLink> = [];
  public isSaveDisabled = true;
  public isLinkingDisabled = true;
  public userDiscardResponse$ = new Subject<boolean>();
  public modals: any = {};
  public submissionLinking = {
    establishedUnderBill: [],
    establishedUnderSubmissionId: 0,
    establishedUnderSectionId: 0
  }
  public billOption = [];
  public data;
  public pristine = false;
  public showExpireButton = false;
  public principle: PrincipleModel;

  private model: PrincipleModel = new PrincipleModel();
  private citedSubmissions: CitedSubmissionsModel;
  private routeSubscription: Subscription;
  private principleSubscription: Subscription;
  private heightNeedsCheck = true;

  constructor(
    private config: AppConfigService,
    public principleService: PrincipleService,
    public sharedService: SharedService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private titleService: Title,
    private authService: OirAuthService) { }

  ngAfterViewInit() {
    const modalChildren = this.modalChildren.toArray();
    this.modals.showSupercedeConfirmationModal = modalChildren[0];
    this.principleForm.updateValueAndValidity();
  }

  ngAfterViewChecked() {
    if (this.heightNeedsCheck) {
      if (this.principleForm.get('title').value ||
      this.principleForm.get('text').value ||
      this.principleForm.get('additionalDetails').value) {
        this.heightUpdater('titleBox');
        this.heightUpdater('textBox');
        this.heightUpdater('additionalDetailsBox');
        delete this.heightNeedsCheck;
      }
    }
  }

  heightUpdater(directive: string): void {
    const box = this[directive].nativeElement;
    box.style.height = 'auto';

    if (box.offsetHeight - 2 !== box.scrollHeight) {
      box.style.height = (box.scrollHeight + 2) + 'px';
    }
  }

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(params => {
      const id = params['id'];

      this.principleService.getPrinciple(id > 0 ? id : 0);

      this.principleForm = this.fb.group({
        principleId: 0,
        topicIds: [[]],
        houseIdForum: [null, [Validators.required]],
        title: ['', [Validators.required]],
        text: [''],
        additionalDetails: '',
        dateEstablished: [null, [Validators.required]],
        principleIdLinking: [[]],
        principleLinks1: [[]],
        principleLinks2: [[]],
        submissionLinking: [this.submissionLinking, [this.validateSubmission.bind(this), this.validateSubmissionLinking.bind(this)]],
        establishedUnderSubmissionId: 0,
        establishedUnderSectionId: 0,
        establishedUnderBill: [[]],
        isExpired: false,
        submissions: [[]],
        sections: [[]],
        linkType: 0,
        isAllowedSave: true
      });

      this.principleSubscription = this.principleService.principle.subscribe((data: any) => {
        if (data) {
          this.topicsList = data.topics;
          this.forumList = data.forums;

          if (id > 0) {
            this.model = data.principle;
            this.citedSubmissions = data.citedSubmissions;
            this.titleService.setTitle(this.translateService.instant('PRINCIPLE.TABTITLE', { principleId: this.model.principleId } ));
            this.getTopicsIds(this.model.principleTopics);
            this.getBillTitle(this.model.establishedUnderBillId).subscribe(billTitle => {
              this.billOption = billTitle;
              this.data = {
                establishedUnderBill: this.billOption,
                establishedUnderSubmissionId: this.model.establishedUnderSubmissionId,
                establishedUnderSectionId: this.model.establishedUnderSectionId
              }

              this.principleForm.setValue({
                principleId: this.model.principleId,
                topicIds: this.model.topicIds,
                houseIdForum: this.model.houseIdForum,
                title: this.model.title,
                text: this.model.text,
                additionalDetails: this.model.additionalDetails,
                dateEstablished: moment(this.model.dateEstablished, ['DD/MM/YYYY']).format('DD/MM/YYYY'),
                principleIdLinking: [],
                principleLinks1: this.model.principleLinks1,
                principleLinks2: this.model.principleLinks2,
                submissionLinking: this.data,
                establishedUnderSubmissionId: this.model.establishedUnderSubmissionId,
                establishedUnderSectionId: this.model.establishedUnderSectionId,
                establishedUnderBill: this.model.establishedUnderBillId,
                isExpired: this.model.isExpired,
                submissions: [],
                sections: [],
                linkType: 0,
                isAllowedSave: true
              });

              this.principleService.initNavigationEditChild(this.model.principleId);
                this.principleForm.markAsPristine();
            });

            if (this.model.principleId && !this.model.isExpired && data.isAuthorizedToExpire) {
              this.onUpdateExpireModal();
              this.showExpireButton = true;
            }
          }
        }
        this.principleForm.markAsPristine();
      });
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.principleSubscription.unsubscribe();

    const searchNode = this.navigationService.model.tree.find(x => x.title === 'Search principles');
    searchNode.expanded = false;
  }

  toggle(): void {
    this.createComponent.toggle();
  }

  public getPrinciples() {
    this.principleService.getPrinciples().subscribe((response: any) => {
      const principlesArray = response.principles.filter(e => e.value !== this.model.principleId);
      this.principlesList = principlesArray;

      if (this.model.principleLinks1.length > 0) {
        if (this.principleForm.value.linkType === LinkTypeEnum.Relate) {

          this.principlesList = principlesArray.filter(p => !this.model.principleLinks1.includes(p));

        } else if (this.principleForm.value.linkType === LinkTypeEnum.Supercede) {
          this.principlesList = principlesArray;
        }
      }
    });
  }

  public onEstablishEditPrinciple() {
    this.markAsPristine();
    let result = new PrincipleModel();
    result = this.principleForm.value;
    result.establishedUnderBillId = Number(this.principleForm.value.submissionLinking.establishedUnderBill.value);
    result.establishedUnderSubmissionId = this.principleForm.value.submissionLinking.establishedUnderSubmissionId;
    
    if (this.principleForm.value.submissionLinking.establishedUnderSectionId == null) {
      result.establishedUnderSectionId = 0;
    } else {
      result.establishedUnderSectionId = this.principleForm.value.submissionLinking.establishedUnderSectionId;
    }

    result.principleExpiries = new Array<PrincipleExpiry>();
    result.principleLinks2 = new Array<PrincipleLink>();
    result.citedSubmissions = this.citedSubmissions;
    result.dateEstablished = moment.utc(result.dateEstablished, ['DD/MM/YYYY']).toISOString();
    this.principleService.establishEditPrinciple(result);
  }

  public getTopicsIds(principleTopics: Array<Topic>) {
    this.model.topicIds = [];
    for (const topic of principleTopics) {
      this.model.topicIds.push(topic.topicId);
    }
  }

  public onLinkRelatedPrinciples() {
    let linkingData = this.createLinkingData();
    const linksValue = this.model.principleLinks1.concat(this.principleLinks);
    const mySet = new Set(linksValue);
    linkingData = Array.from(mySet);

    if (this.principleForm.value.principleId !== 0) {
      this.onClearPrinciplesLinking();
      this.principleForm.get('principleLinks1').patchValue(linkingData);
    } else {
      this.model.principleLinks1 = linkingData;
      this.onClearPrinciplesLinking();
      this.principleForm.get('principleLinks1').patchValue(this.model.principleLinks1);
    }
  }

  public onSupercedePrinciples() {
    let linkingData = this.createLinkingData();
    this.modals.showSupercedeConfirmationModal.toggle();
    const linksValue = this.model.principleLinks1.concat(this.principleLinks);
    const mySet = new Set(linksValue);
    linkingData = Array.from(mySet);

    if (this.principleForm.value.principleId !== 0) {
      this.onClearPrinciplesLinking();
      this.principleForm.get('principleLinks1').patchValue(linkingData);
    } else {
      this.model.principleLinks1 = linkingData;
      this.onClearPrinciplesLinking();
      this.principleForm.get('principleLinks1').patchValue(this.model.principleLinks1);
    }
  }

  public createLinkingData() {
    const linkType = this.principleForm.value.linkType;

    for (const id of this.principleForm.value.principleIdLinking) {
      const linkedPrincipleTitle = this.principlesList.filter(p => p.value === id)[0].title;
      const principleLink = new PrincipleLink;
      {
        principleLink.principleId1 = this.principleForm.value.principleId;
        principleLink.principleId2 = id;
        principleLink.principleLinkTypeId = linkType;
        principleLink.principleId2Title = linkedPrincipleTitle;
      }
      if (linkType === LinkTypeEnum.Supercede) {
        principleLink.principleLinkTypeId = 2;
      } else {
        principleLink.principleLinkTypeId = 1;
      }
      this.principleLinks.push(principleLink);
    };

    return this.principleLinks;
  }

  public onClearPrinciplesLinking() {
    this.principleForm.get('linkType').reset();
    this.principleForm.get('principleIdLinking').patchValue([]);
    this.principlesList = [];
    this.accordion.close();
  }

  public onDeletePrincipleLink(link: PrincipleLink, index: number) {
    this.principleLinks = this.principleForm.get('principleLinks1').value;
    this.principleLinks.splice(index, 1);
    this.principleForm.get('principleLinks1').patchValue(this.principleLinks);
    this.principleForm.markAsDirty();
  }


  public canLinkPrinciples() {
    const link = this.principleForm.get('linkType');
    const linkedPrinciple = this.principleForm.get('principleIdLinking');
    if (link.value !== 0 && linkedPrinciple.value.length !== 0) {
      this.isLinkingDisabled = false;
    } else {
      this.isLinkingDisabled = true;
    }
  }

  public getBillTitle(id: number) {
    return this.sharedService.getBill(id);
  }

  public onShowExpireModal() {
    this.expireModal.toggle();
  }

  public canDeactivate(_component: EstablishEditComponent,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) {

    if (!this.principleForm.pristine) {
      if (nextState.url !== '/create-submission') {
        this.unsavedChangesModal.toggle();
      }
      return this.userDiscardResponse$.pipe(
        map(value => {
          if (value) {
            return true;
          } else {
            this.principleService.initNavigationEditChild(this.model.principleId);
            return false;
          }
        })
      );
    }
    return true;
  }

  public markAsPristine(): void {
    this.pristine = true;

    this.principleForm.markAsPristine();
    this.principleForm.markAsUntouched();

  }

  public onCancelChanges(toggle = true) {
    this.userDiscardResponse$.next(false);

    if (toggle) {
      this.unsavedChangesModal.toggle();
    }
  }

  public onDiscardChanges() {
    this.userDiscardResponse$.next(true);
    this.unsavedChangesModal.toggle();
  }

  public onClosePrincipleScreen() {
    this.navigationService.clearSelect(this.navigationService.currentNode);

    if (this.model.principleId > 0) {
      this.navigationService.navigateToPath(`/principle/view/${this.model.principleId}`);
    } else {
      this.navigationService.navigateToPath(`/principle-search`);
    }
  }

  public onCitedSubmissionsChange() {
    this.principleForm.markAsDirty();
  }

  private validateSubmission(input, context) {
    if (!context) {
      return;
    }

    let result = null;
    const submissionId = input.value.establishedUnderSubmissionId;

    if (submissionId && this.citedSubmissions) {
      if (this.citedSubmissions.items.find(group => group.some(item => item.submissionIdLink === submissionId) )) {
        result = {
          validateSubmission: {
            valid: false
          }
        }
      }
    }

    return result;
  }

  private validateSubmissionLinking(input, _context) {
    let result = null;
    const submissionLinking = input.value;
    const establishedUnderBill = submissionLinking.establishedUnderBill.value;
    const submissionId = submissionLinking.establishedUnderSubmissionId;
    const sectionId = submissionLinking.establishedUnderSectionId;

    if ((submissionLinking.establishedUnderBill.length === 0 ) ||
      (submissionLinking.establishedUnderBill.length === 0 && Number(establishedUnderBill) > 0
      && (submissionId === null) || submissionId === 0)
      || (Number(establishedUnderBill) > 0 && submissionId === null && this.linkSubmission?.hideSection === true)
      || (this.linkSubmission?.hideSection === false && (sectionId === 0 ||  sectionId === null) )) {
      result = {
        validateSubmissionLinking: {
          valid: false
        }
      }
    }
    return result;
  }

  private onUpdateExpireModal() {
    if (!this.expireModal) {
      return;
    }

    this.expireModal.principleTitle = this.model.title;
    this.expireModal.principleText = this.model.text;

    this.expireModal.expirePrincipleForm.setValue({
      expiryReason: '',
      expiryReasonValue: '',
      principleId: this.model.principleId,
      updatedBy: this.authService.getUsername(),
      AdRoleMemberIdExpiredBy: this.config.getValue('UserRoleMemberId')
    });

    this.expireModal.expiryComplete.subscribe(result => {
      if (result) {
        this.principleService.initNavigationViewChild(this.model.principleId);
        this.navigationService.navigateToPath(`/principle/view/${this.model.principleId}`);
      }
    });
  }
}
