import {
  Component,
  OnInit,
  AfterViewInit,
  forwardRef,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  FormBuilder,
  Validators,
  NG_VALUE_ACCESSOR,
  FormArray
} from '@angular/forms';
import {
  AddSupportInfoModel,
  SupportingInformation,
  SupportingInformationOptionsEnum,
  AddSupportingInformation
} from './support-info.model';
import { Subject } from 'rxjs';
import { SubmissionService } from '../submission.service';
import { LinkSubmissionComponent } from '../../shared/link-submission.component';
import * as moment from 'moment';
import { ValidModel } from '../../models/app.models';
import { ValidationService } from '../../shared/validation.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'oir-support-info',
  templateUrl: './support-info.component.html',
  styleUrls: ['./support-info.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SupportInfoComponent),
      multi: true
    }
  ]
})
export class SupportInfoComponent
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() contributionId;
  @Input() submissionId;
  @Input() formTouchedValidation = false;
  @ViewChild(LinkSubmissionComponent) linkSubmission: LinkSubmissionComponent;

  public formID: number;
  public form: FormGroup;
  public supportInfoForm: FormGroup;
  public refDataModel: AddSupportInfoModel = new AddSupportInfoModel();
  public supportingInformationOptions = SupportingInformationOptionsEnum;
  public addSupportingInformation = AddSupportingInformation;
  public selectedTypeDesc = '';
  public obs$ = new Subject();
  private subscription;

  public allowedMimeType = [
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/richtext',
    'application/pdf',
    'text/enriched',
    'application/rtf',
    'application/x-rtf',
    'text/rtf',
    'text/richtext',
    'application/msword',
    'application/doc',
    'application/x-soffice'
  ];
  propagateChange = (_: SupportingInformation[]) => {};
  propagateTouch = (_: any) => {};

  constructor(
    private formBuilder: FormBuilder,
    private submissionService: SubmissionService,
    private validationService: ValidationService
  ) {
    this.formID = Math.floor(Math.random() * 90000) + 10000;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      supportingInformation: this.formBuilder.array([])
    });

    this.supportInfoForm = this.newFormGroup();


    this.subscription = this.submissionService.submission$.subscribe(model => {
      this.obs$.next(this.addSupportingInformation.SelectFromOptions);
      this.refDataModel = model.supportInformationRefData;
    });

    this.obs$.subscribe(() => {
      if (+this.supportInfoForm.get('supportInfoTypeId').value > 0) {
        this.selectedTypeDesc = this.refDataModel.supportInfoTypes.find(
          x => x.value === this.supportInfoForm.get('supportInfoTypeId').value
        ).title;
      }
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.touched) {
        this.makeValidationChange(!this.form.touched);
      }
      this.propagateChange(this.form.get('supportingInformation').value)
    });

     this.supportInfoForm.get('supportInfoTypeId').valueChanges.subscribe(
      (infoType: number) => {
        const principleIds = this.supportInfoForm.get('principleIds');
        const existingDocument = this.supportInfoForm.get('existingDocument');
        const url = this.supportInfoForm.get('url');
        const urlDesc = this.supportInfoForm.get('urlDesc');
        const standingOrderIds = this.supportInfoForm.get('standingOrderIds');
        const salientRulingIds = this.supportInfoForm.get('salientRulingIds');
        const articlesIds = this.supportInfoForm.get('articlesIds');
        const docDesc = this.supportInfoForm.get('documentUserDescription');

        if (this.linkSubmission !== undefined) {
          this.linkSubmission.resetTheForm();
        }

        this.clearValidators(principleIds, existingDocument, url, urlDesc, standingOrderIds,
          salientRulingIds, articlesIds, docDesc);
        this.resetControls(principleIds, existingDocument, url, urlDesc, standingOrderIds,
          salientRulingIds, articlesIds, docDesc);

        switch (+infoType) {
          case SupportingInformationOptionsEnum.EstablishPrinciple: {
            this.supportInfoForm
            .get('principleIds')
            .setValidators([
              Validators.required
            ]);
            this.supportInfoForm.get('principleIds').updateValueAndValidity();
            break;
          }
          case SupportingInformationOptionsEnum.ExistingDocument: {
            this.supportInfoForm
            .get('existingDocument')
            .setValidators([
              Validators.required
            ]);
            this.supportInfoForm
            .get('documentUserDescription')
            .setValidators([
              Validators.required
            ]);
            this.supportInfoForm.get('existingDocument').updateValueAndValidity();
            break;
          }
          case SupportingInformationOptionsEnum.ExternalLink: {
            this.supportInfoForm
            .get('url')
            .setValidators([
              Validators.required,
              Validators.maxLength(2048)
            ]);
            this.supportInfoForm
            .get('urlDesc')
            .setValidators([
              Validators.required,
              Validators.maxLength(200)
            ]);
            this.supportInfoForm.get('url').updateValueAndValidity();
            this.supportInfoForm.get('urlDesc').updateValueAndValidity();
            break;
          }
          case SupportingInformationOptionsEnum.StandingOrder: {
            this.supportInfoForm
            .get('standingOrderIds')
            .setValidators([
              Validators.required
            ]);
            this.supportInfoForm.get('standingOrderIds').updateValueAndValidity();
            break;
          }
          case SupportingInformationOptionsEnum.SalientRuling: {
            this.supportInfoForm
            .get('salientRulingIds')
            .setValidators([
              Validators.required
            ]);
            this.supportInfoForm.get('salientRulingIds').updateValueAndValidity();
            break;
          }
          case SupportingInformationOptionsEnum.ArticlesOfTheConstitution: {
            this.supportInfoForm
            .get('articlesIds')
            .setValidators([
              Validators.required
            ]);
            this.supportInfoForm.get('articlesIds').updateValueAndValidity();
            break;
          }
        }
        this.supportInfoForm.markAsPristine();
        this.supportInfoForm.markAsUntouched();
      }
    );
  }

  get supportingInfoFormData() { return <FormArray>this.form.get('supportingInformation'); }

  ngAfterViewInit() {
    this.obs$
      .pipe(filter(r => r === this.addSupportingInformation.SelectFromOptions))
      .subscribe(r => {
          this.supportInfoForm.reset();
      });
      setTimeout(() => { this.obs$.next(this.addSupportingInformation.SelectFromOptions);
      });
  }

  onSaveSupportingInfo() {
    this.form.markAsTouched();
    this.makeValidationChange();
    switch (+this.supportInfoForm.get('supportInfoTypeId').value) {
      case SupportingInformationOptionsEnum.EstablishPrinciple: {
        this.addPrinciples();
        break;
      }
      case SupportingInformationOptionsEnum.ExistingDocument: {
        this.addLinkToRelatedDocument();
        break;
      }
      case SupportingInformationOptionsEnum.NewDocument: {
        this.addOtherDocument();
        break;
      }
      case SupportingInformationOptionsEnum.LinkToASubmission: {
        this.addLinkToSubimssion();
        break;
      }
      case SupportingInformationOptionsEnum.ExternalLink: {
        this.addRelatedLink();
        break;
      }
      case SupportingInformationOptionsEnum.StandingOrder: {
        this.addStandingOrder();
        break;
      }
      case SupportingInformationOptionsEnum.SalientRuling: {
        this.addSaliantRuling();
        break;
      }
      case SupportingInformationOptionsEnum.ArticlesOfTheConstitution: {
        this.addarticlesofTheConstitution();
        break;
      }
    }
    setTimeout(() => {
      this.onCloseForm();
      this.supportInfoForm.markAsPristine();
    });
  }

  public onCloseForm(): void {
    this.obs$.next(this.addSupportingInformation.SelectFromOptions);
  }

  public addPrinciples(): void {
    for (const p of this.supportInfoForm.get('principleIds').value) {
      const newInfo = new SupportingInformation();
      newInfo.supportInfoTypeId = this.supportInfoForm.get(
        'supportInfoTypeId'
      ).value;
      newInfo.principleId = p.id;
      newInfo.principleDesc = this.refDataModel.principles.filter(
        l => l.value.id === p.id
      )[0].value.title;
      this.addSupportingInfo(newInfo);
    }
  }

  public addLinkToRelatedDocument(): void {
    const newInfo = new SupportingInformation();
    newInfo.supportInfoTypeId = this.supportInfoForm.get(
      'supportInfoTypeId'
    ).value;
    newInfo.documentId = this.supportInfoForm.get('existingDocument').value.documentId;
    newInfo.documentUserDescription = this.supportInfoForm.get('documentUserDescription').value;
    newInfo.documentDescr = this.supportInfoForm.get('existingDocument').value.name;
    this.addSupportingInfo(newInfo);
  }

  public addOtherDocument(): void {
    const newInfo = new SupportingInformation();
    newInfo.supportInfoTypeId = this.supportInfoForm.get(
      'supportInfoTypeId'
    ).value;
    newInfo.documentUserDescription = newInfo.documentLanguageId = this.supportInfoForm.get(
      'newDocument'
    ).value.documentUserDescription;
    newInfo.documentLanguageId = this.supportInfoForm.get(
      'newDocument'
    ).value.documentLanguageId;
    newInfo.documentDate = moment.utc(this.supportInfoForm.get(
      'newDocument'
    ).value.documentDate, ['DD/MM/YYYY']).toISOString();
    newInfo.attachedDocuments = this.supportInfoForm.get(
      'newDocument'
    ).value.attachedDocuments;
    newInfo.documentUniqueId = this.supportInfoForm.get(
      'newDocument'
    ).value.documentUniqueId;
    newInfo.documentDescr = newInfo.attachedDocuments[0].name;
    this.addSupportingInfo(newInfo);
  }

  public addLinkToSubimssion(): void {
    const newInfo = new SupportingInformation();
    newInfo.billIdLink = this.supportInfoForm.get(
      'submissionLinking'
    ).value.establishedUnderBill.value;
    newInfo.supportInfoTypeId = this.supportInfoForm.get(
      'supportInfoTypeId'
    ).value;
    newInfo.submissionIdLink = this.supportInfoForm.get(
      'submissionLinking'
    ).value.establishedUnderSubmissionId;
    newInfo.shortTitleEngLink = this.supportInfoForm.get(
      'submissionLinking'
    ).value.establishedUnderBill.title;

    newInfo.establishedUnderSectionId = this.supportInfoForm.get(
      'submissionLinking'
    ).value.establishedUnderSectionId;
    this.addSupportingInfo(newInfo);
  }

  public addRelatedLink(): void {
    const newInfo = new SupportingInformation();
    newInfo.supportInfoTypeId = this.supportInfoForm.get(
      'supportInfoTypeId'
    ).value;
    newInfo.url = this.supportInfoForm.get('url').value;
    if (newInfo.url.indexOf('http') === -1) {
      newInfo.url = 'http://' + newInfo.url;
    }
    newInfo.urlDesc = this.supportInfoForm.get('urlDesc').value;
    this.addSupportingInfo(newInfo);
  }

  public addStandingOrder(): void {
    for (const so of this.supportInfoForm.get('standingOrderIds').value) {
      const newInfo = new SupportingInformation();
      newInfo.supportInfoTypeId = this.supportInfoForm.get(
        'supportInfoTypeId'
      ).value;
      newInfo.standingOrderId = so.id;
      newInfo.standingOrderReference = so.soReference;
      newInfo.standingOrderDescr = this.refDataModel.standingOrders.filter(
        l => l.value.id === so.id
      )[0].value.title;
      this.addSupportingInfo(newInfo);
    }
  }

  public addSaliantRuling(): void {
    for (const sr of this.supportInfoForm.get('salientRulingIds').value) {
      const newInfo = new SupportingInformation();
      newInfo.supportInfoTypeId = this.supportInfoForm.get(
        'supportInfoTypeId'
      ).value;
      newInfo.salientRulingId = sr.id;
      newInfo.salientRulingReference = sr.srReference;
      newInfo.salientRulingDescr = this.refDataModel.salientRulings.filter(
        l => l.value.id === sr.id
      )[0].value.title;
      this.addSupportingInfo(newInfo);
    }
  }

  public addarticlesofTheConstitution(): void {
    for (const ac of this.supportInfoForm.get('articlesIds').value) {
      const newInfo = new SupportingInformation();
      newInfo.supportInfoTypeId = this.supportInfoForm.get(
        'supportInfoTypeId'
      ).value;
      newInfo.constitutionArticleId = ac.id;
      newInfo.constitutionArticleReference = ac.coReference;
      newInfo.constitutionArticleDescr = this.refDataModel.articlesofTheConstitution.filter(
        l => l.value.id === ac.id
      )[0].value.title;
      this.addSupportingInfo(newInfo);
    }
  }

  public addSupportingInfo(model: SupportingInformation) {
    const control = <FormArray>this.form.get('supportingInformation');
    const formControl = this.formBuilder.group({
      supportInfoId: 0,
      contributionId: '',
      submissionId: '',
      billId: 0,
      supportInfoTypeId: '',
      commentFinancialId: '',
      commentAmendmentId: '',
      supportinfoTypeDescr: '',
      standingOrderId: '',
      standingOrderReference: '',
      standingOrderDescr: '',
      salientRulingId: '',
      salientRulingReference: '',
      salientRulingDescr: '',
      constitutionArticleId: '',
      constitutionArticleReference: '',
      constitutionArticleDescr: '',
      principleId: '',
      principleDesc: '',
      documentId: '',
      documentTypeId: 0,
      documentDescr: '',
      documentDate: '',
      documentTypeDescr: '',
      documentLanguageId: 0,
      documentLanguageDescr: '',
      documentUniqueId: '',
      url: '',
      urlDesc: '',
      establishedUnderSectionId: '',
      financialImplicationTypeIdLink: '',
      financialImplicationTypeDescr: '',
      amendmentGroupIdLink: '',
      amendmentGroupTitle: '',
      submissionIdLink: '',
      shortTitleEngLink: '',
      submissionDesc: '',
      attachedDocuments: [],
      documentUserDescription: '',
      billIdLink: 0,
    });
    formControl.patchValue(model);

    if (this.isUnique(control, formControl)) {
      control.push(formControl);
    }
  }

  public isUnique(existingSupportInfo: FormArray, model: any): boolean {
    switch (+model.get('supportInfoTypeId').value.toString()) {
      case SupportingInformationOptionsEnum.EstablishPrinciple: {
        return (
          existingSupportInfo.controls.find(
            i => i.value.principleId === model.get('principleId').value
          ) === undefined
        );
      }
      case SupportingInformationOptionsEnum.ExistingDocument: {
        return (
          existingSupportInfo.controls.find(
            i => i.value.documentId === model.get('documentId').value
          ) === undefined
        );
      }
      case SupportingInformationOptionsEnum.NewDocument: {
        // Cannot check
        return true;
      }
      case SupportingInformationOptionsEnum.LinkToASubmission: {
        return (
          existingSupportInfo.controls.find(
            i =>
              i.value.submissionIdLink === model.get('submissionIdLink').value &&
              i.value.establishedUnderSectionId === model.get('establishedUnderSectionId').value) === undefined
        );
      }
      case SupportingInformationOptionsEnum.ExternalLink: {
        return (
          existingSupportInfo.controls.find(
            i => i.value.url === model.get('url').value
          ) === undefined
        );
      }
      case SupportingInformationOptionsEnum.StandingOrder: {
        return (
          existingSupportInfo.controls.find(
            i => i.value.standingOrderId === model.get('standingOrderId').value
          ) === undefined
        );
      }
      case SupportingInformationOptionsEnum.SalientRuling: {
        return (
          existingSupportInfo.controls.find(
            i => i.value.salientRulingId === model.get('salientRulingId').value
          ) === undefined
        );
      }
      case SupportingInformationOptionsEnum.ArticlesOfTheConstitution: {
        return (
          existingSupportInfo.controls.find(
            i =>
              i.value.constitutionArticleId ===
              model.get('constitutionArticleId').value
          ) === undefined
        );
      }
    }
    return true;
  }

  public deleteSupportingInformation(index: number) {
    const control = <FormArray>this.form.controls['supportingInformation'];
    control.removeAt(index);
  }

  public newFormGroup(): FormGroup {
   return this.formBuilder.group({
      supportInfoTypeId: 0,
      supportInfoTypeDescr: '',
      principleIds: [[]],
      documentId: [null],
      salientRulingIds: [[]],
      standingOrderIds: [[]],
      articlesIds: [[]],
      shortTitleEngLink: '',
      newFileContent: '',
      attachedDocuments: [],
      url: [null],
      urlDesc: [null],
      existingDocument: [null],
      documentUserDescription: '',
      submissionLinking : [{
        establishedUnderBill: [],
        establishedUnderSubmissionId: null,
        establishedUnderSectionId: null
      }],
      newDocument: this.formBuilder.control({
        documentTypeId: 0,
        submissionId: 0,
        otherDescription: '',
        documentLanguageId: 0,
        documentUserDescription: '',
        documentDate: '',
        documentUniqueId: '',
        newFileContent: '',
        attachedDocuments: []
      })
    });
  }

  private clearValidators(...controls: Array<any>) {
    controls.forEach(x => x.clearValidators());
  }
  private resetControls(...controls: Array<any>) {
    controls.forEach(x => x.reset());
  }

  public writeValue(supportingInfo: SupportingInformation[]): void {
    if (supportingInfo) {
      const infoFGs = supportingInfo?.map(info => this.formBuilder.group(info));
      const infoFormArray = this.formBuilder.array(infoFGs);
      this.form.setControl('supportingInformation', infoFormArray);
     } else {
      this.form.setControl('supportingInformation', this.formBuilder.array([]));
      this.onCloseForm();
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public makeValidationChange(clean = false) {
    if (this.formTouchedValidation) {
      const mValidation = new ValidModel();
      mValidation.id = this.formID;
      mValidation.clean = clean;
      this.validationService.markForm(mValidation);
    }
  }
}
