import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { SubmissionService } from './submission.service';

@Injectable()
export class SubmissionResolve implements Resolve<any> {

  constructor(private submissionService: SubmissionService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.submissionService.get(route.params['id'])
    .pipe(
      map(data => {
        this.submissionService.submission$.next(data)
      })
    );
  }
}
