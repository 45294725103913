import { Component, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators,
  ControlValueAccessor, NG_VALUE_ACCESSOR, NG_ASYNC_VALIDATORS, AsyncValidator, AbstractControl } from '@angular/forms';
import { SelectValue } from 'proceduralsystem-clientcomponents';
import { SharedService } from './shared.service';
import { BillSubmission } from './link-submission.model';
import { Subscription ,  Observable, of } from 'rxjs';

@Component({
  selector: 'oir-link-submission',
  templateUrl: './link-submission.component.html',
  styleUrls: ['./link-submission.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LinkSubmissionComponent),
      multi: true
    }
  , {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => LinkSubmissionComponent),
      multi: true,
  }]
})

export class LinkSubmissionComponent implements ControlValueAccessor, OnInit, OnDestroy, AsyncValidator {
  public submissionList: Array<SelectValue<any>> = [];
  public sectionList: Array<SelectValue<any>> = [];
  public apiUrlBill: string;
  public hideSection = true;
  public submissionLinkForm: FormGroup;
  public billSubmissions: Array<BillSubmission> = [];
  public billSection: Array<any> = [];
  public currentItemSubId = 0;
  public currentItemSecId = 0;
  public isUpdate = false;
  private subscription: Subscription;

  propagateChange = (_: any) => { };
  propagateTouch = (_: any) => { };

  constructor(private fb: FormBuilder, private sharedService: SharedService) {
    this.submissionList = new Array<SelectValue<any>>();
  }

  writeValue(submissionLinking: any) {
    if (submissionLinking && submissionLinking.establishedUnderSubmissionId) {
      // Store set form data from model.
      this.currentItemSubId = submissionLinking.establishedUnderSubmissionId;
      this.currentItemSecId = submissionLinking.establishedUnderSectionId;

      this.submissionLinkForm.setValue({
        establishedUnderBill: {
          value: submissionLinking.establishedUnderBill[0].value,
          title: submissionLinking.establishedUnderBill[0].title
        },
        establishedUnderSubmissionId:
          submissionLinking.establishedUnderSubmissionId,
        establishedUnderSectionId:
          submissionLinking.establishedUnderSectionId
      });
    }
  }

  updateSection(val: number) {
    if (this.currentItemSecId !== this.currentItemSubId
      || (val != null &&  this.currentItemSubId !== val)
      || this.isUpdate) {
        // Only update section if submission dropdown change and is different from initial submission id.
      this.submissionLinkForm.patchValue({establishedUnderSectionId: null });
      this.isUpdate = true;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propagateTouch = fn;
  }

  ngOnInit() {
    this.submissionLinkForm = this.fb.group({
      establishedUnderBill: [[''], [Validators.required]],
      establishedUnderSubmissionId: [0, [Validators.required]],
      establishedUnderSectionId: 0
    });

    this.onBillChanges();
    this.getTypeaheadApi();

    this.subscription = this.submissionLinkForm.valueChanges.subscribe(x => this.propagateChange(x));
  }

  resetTheForm() {
    this.submissionLinkForm = this.fb.group({
      establishedUnderBill: 0,
      establishedUnderSubmissionId: 0,
      establishedUnderSectionId: 0
    });
  }

  validate(c: AbstractControl): Promise<{ [key: string]: any; }> | Observable<{ [key: string]: any; }> {
    return of(this.submissionLinkForm.valid ? null : {invalid: true});
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onBillChanges(): void {
    const bill = this.submissionLinkForm.get('establishedUnderBill');
    bill.patchValue([]);

    this.submissionLinkForm.get('establishedUnderBill').valueChanges.subscribe(val => {
      this.submissionLinkForm.controls['establishedUnderSubmissionId'].reset();
      this.submissionLinkForm.markAsPristine();
      this.submissionList = new Array<SelectValue<any>>();
      this.hideSection = true;

      if (val.value !== undefined && this.submissionLinkForm.value.establishedUnderSubmissionId == null) {
        this.populateSubmissionDropdown(val.value);
        this.onSubmissionChanges();
      }
    });
  }

  onSubmissionChanges(): void {
    this.submissionLinkForm.get('establishedUnderSubmissionId').valueChanges.subscribe(val => {
      this.updateSection(val);
      this.submissionLinkForm.markAsPristine();
      this.sectionList = new Array<SelectValue<any>>();
      this.hideSection = true;

      if (val !== undefined && this.submissionLinkForm.value.establishedUnderSectionId == null) {
        this.populateSectionsDropdown(val);
        this.submissionLinkForm.markAsPristine();
      }
    });
  }

  public async getTypeaheadApi() {
    this.apiUrlBill = `/api/Bill`;
  }

  public populateSubmissionDropdown(billId: number) {
    this.sharedService.getBillSubmissions(billId).subscribe(billSubmissions => {
      this.submissionList = new Array<SelectValue<any>>();
      this.submissionList = billSubmissions;
      this.submissionList.forEach(submission => submission.title = '#' + submission.title);
    });

  }

  public populateSectionsDropdown(submissionId: number) {
    if (submissionId != null) {
      this.sharedService.getSubmissionSections(submissionId).subscribe(sections => {
        this.sectionList = new Array<SelectValue<any>>();
        this.sectionList = sections;
        this.hideSection = this.hideSections();
      });
    }
  }

  public hideSections() {
    return !this.sectionList || !this.sectionList.length;
  }
}
